import {IntlShape} from "react-intl";
import {Checkbox, InputNumber, Select, Space} from "antd";
import {InternalPersonLabel, PersonLogoBadge} from "~shared/ui";
import moment from "moment";
import { debounce, isBoolean, isFunction } from "lodash";

type FormatMessage = IntlShape["formatMessage"];
export type OnColumnChangedCallback = ({ column, value } : {column: string; value: number | string | boolean | null; record?: any | null }, e?: any | null) => void;
type CanEditFlag = boolean | ((column: string, record: any) => boolean);
type EditableColumn = {
  canEdit?: CanEditFlag;
  isEditMode: boolean;
  onColumnChanged?: OnColumnChangedCallback
};

// TODO: move closer to api types when we have them
type Status = "ACTIVE" | "UNREGISTERED" | "PENDING" | "DECEASED" | "INACTIVE";
type Person = {
  dcPersonId: string;
  dob: string | null;
  gender: "MALE" | "FEMALE";
  imageHeadUrl: string | null;
  imagePoseUrl: string | null;
  imageWaistUrl: string | null;
  nameFullLatin: string | null;
  nameFullLocal: string;
  nationality: string;
  personId: string | null;
  // TODO: add role type
  role: string;
  status: Status;
}

type NumberObject = { number: string };

export const checkIsEditEnabled = (flag: CanEditFlag | undefined, column: string, record: any) => {
  let canEdit = false;
  if (flag === undefined) {
    canEdit = true;
  } else if (isFunction(flag)) {
    canEdit = flag(column, record);
  } else if (isBoolean(flag)) {
    canEdit = flag;
  } else {
    throw new Error("Current flag type is not accepted");
  }
  return canEdit;
}

export const getNumberColumn = (formatMessage: FormatMessage) => ({
  title: formatMessage({
    id: "number",
    defaultMessage: "Number"
  }),
  dataIndex: "number",
  align: "center",
  width: 75,
  sorter: (a: NumberObject, b: NumberObject) => parseInt(a?.number || "0") - parseInt(b?.number || "0"),
});

export const getDobColumn = (formatMessage: FormatMessage) => ({
  title: formatMessage({
    id: "dob",
    defaultMessage: "DOB"
  }),
  width: 100,
  dataIndex: "dob",
  render: (personDob?: string) => personDob ? moment(personDob).format("DD-MM-YYYY") : "",
  align: "center"
});

export const getRoleColumn = (formatMessage: FormatMessage, opts?: EditableColumn & { rolesList: any[] }) => ({
  title: formatMessage({
    id: "role",
    defaultMessage: "Role"
  }),
  width: 250,
  dataIndex: "role",
  // TODO: add role type
  render: opts?.isEditMode ? 
  (role: string, record: any) => {
    return (
      <Select
      disabled={!checkIsEditEnabled(opts?.canEdit, "role", record)}
      style={{width: "150px"}}
      showSearch
      defaultValue={role}
      placeholder={"Select role..."}
      filterOption={(input, option) => (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())}
      onChange={(value) => {
        record.role = value;
        if (opts?.onColumnChanged) {
          opts?.onColumnChanged({ column: "role", value: record.role, record });
        }
      }}
      options={[...(opts?.rolesList || [])?.map(role => {
        return {
          value: role,
          label: formatMessage({
            id: `roles.${role}`,
            defaultMessage: `${role || ""}`,
          }),
          dcPersonId: record?.dcPersonId,
          personId: record?.personId,
        };
      }) ?? []]}
      />
    );
  } : 
  (role: string) => role ? formatMessage({
    id: `roles.${role}`,
    defaultMessage: role
  }) : "",
});

export const getNameFullLocalColumns = (formatMessage: FormatMessage, isPlayerTitle = false) => ({
  title: formatMessage({
    id: isPlayerTitle ? "name.player" : "name.full",
    defaultMessage: isPlayerTitle ? "Player Name" : "Full Name"
  }),
  dataIndex: "nameFullLocal",
  render: (nameFullLocal: string | undefined, person: Person) => (
    person?.personId ? (
      <InternalPersonLabel person={person}/>
    ) : (
      <Space>
      <PersonLogoBadge img={person?.imageHeadUrl}/>
      {nameFullLocal}
      </Space>
    )
  )
});

export const getGetPositionColumn = (formatMessage: FormatMessage, opts?: EditableColumn & { rosterPositions: any[] }) => ({
  title: formatMessage({
    id: "position",
    defaultMessage: "Position"
  }),
  width: 120,
  dataIndex: "position",
  // TODO: add position type
  render: 
  opts?.isEditMode ?
  (
    (position: string, record: any) => {
      // !(opts?.canEdit === undefined ? true : opts?.canEdit)
      return (
        <Select
        disabled={!checkIsEditEnabled(opts?.canEdit, "position", record)}
        style={{width: "150px"}}
        showSearch
        defaultValue={position}
        placeholder={"Select position..."}
        filterOption={(input, option) => (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())}
        onChange={(value) => {
          record.position = value;
          if (opts?.onColumnChanged) {
            opts?.onColumnChanged({ column: "position", value: record.position, record });
          }
        }}
        options={[...(opts?.rosterPositions || [])?.map(position => {
          return {
            value: position,
            label: formatMessage({
              id: `roster.${position}`,
              defaultMessage: `${position || ""}`,
            }),
            dcPersonId: record?.dcPersonId,
            personId: record?.personId,
          };
        }) ?? []]}
        />
      );
    }
  )
  :
  ((position: string) => position ? formatMessage({
    id: `roster.${position}`,
    defaultMessage: position,
  }) : ""),
  align: "center"
});

export const getNationalityColumn = (formatMessage: FormatMessage) => ({
  title: formatMessage({id: "nationality", defaultMessage: "Nationality"}),
  dataIndex: "nationality",
  align: "center",
  width: 75
});

export const getPersonStatusColumn = (formatMessage: FormatMessage, isPersonTitle = false) => ({
  title: formatMessage({
    id: isPersonTitle ? "person.status" : "status",
    defaultMessage: isPersonTitle ? "Person Status" : "Status"
  }),
  dataIndex: "personStatus",
  width: 100,
  render: (status?: Status | null) => !status ? "" : formatMessage({
    id: `status.${status}`,
    defaultMessage: status
  }).toUpperCase(),
  align: "center"
});

export const getRosterStatusColumn = (formatMessage: FormatMessage) => ({
  title: formatMessage({
    id: "seasonRoster.status"
  }),
  dataIndex: "seasonRosterPlayerStatus",
  render: (status?: Status | null) => !status ? "" : formatMessage({
    id: `status.${status}`,
    defaultMessage: status
  }).toUpperCase(),
  align: "center",
  width: 150,
});

const createDebouncedHandler = (lambda: (value: number) => void) => debounce((value) => {
  lambda(value);
}, 500);

export const getRosterNumberColumn = (formatMessage: FormatMessage, opts?: EditableColumn) => ({
  title: formatMessage({
    id: "roster.number",
    defaultMessage: "Number"
  }),
  width: 120,
  dataIndex: "number",
  render: opts?.isEditMode ?
  (
    (value: number, record: any) => (
      <InputNumber
      min={1}
      disabled={!checkIsEditEnabled(opts?.canEdit, "number", record)}
      defaultValue={value}
      onInput={createDebouncedHandler((e) => {const value = e;
        record.number = value?.toString() ?? "";
        if (opts?.onColumnChanged) {
          const decodedValue = Number.isNaN(Number(record.number)) ? record.number : Number(record.number);
          opts?.onColumnChanged({ column: "number", value: decodedValue, record });
        }
      })}
      onStep={(e) => {
        const value = e;
        record.number = value?.toString() ?? "";
        if (opts?.onColumnChanged) {
          const decodedValue = Number.isNaN(Number(record.number)) ? record.number : Number(record.number);
          opts?.onColumnChanged({ column: "number", value: decodedValue, record });
        }
      }}
      />
    )
  ) : 
  (number: string) => number,
  align: "center"
});


export const getIsCaptainColumn = (formatMessage: FormatMessage, opts?: EditableColumn) => ({
  title: formatMessage({
    id: "captain",
    defaultMessage: "Captain"
  }),
  dataIndex: "isCaptain",
  width: 75,
  render: opts?.isEditMode ? 
  (isCaptain: boolean, record: any) => (
    <Checkbox
    disabled={!checkIsEditEnabled(opts?.canEdit, "isCaptain", record)}
    defaultChecked={isCaptain}
    onChange={(e) => {
      record.isCaptain = e.target.checked;
      if (opts?.onColumnChanged) {
        const decodedValue = e.target.checked;
        opts?.onColumnChanged({ column: "isCaptain", value: decodedValue, record });
      }
    }}
    />
  ) : 
  (isCaptain: boolean) => isCaptain ? <i className="fa-solid fa-check"/> : "",
  align: "center"
});

export const getIsStarterColumn = (formatMessage: FormatMessage, opts?: EditableColumn) => ({
  title: formatMessage({
    id: "starting",
    defaultMessage: "Starting"
  }),
  dataIndex: "isStarter",
  width: 75,
  render: opts?.isEditMode ? 
  (isStarter: boolean, record: any) => (
    <Checkbox
    disabled={!checkIsEditEnabled(opts?.canEdit, "isStarter", record)}
    defaultChecked={isStarter}
    onChange={(e) => {
      record.isStarter = e.target.checked;
      if (opts?.onColumnChanged) {
        const decodedValue = e.target.checked;
        opts?.onColumnChanged({ column: "isStarter", value: decodedValue, record });
      }
    }}
    />
  ) : 
  (isStarter: boolean) => isStarter ? <i className="fa-solid fa-check"/> : "",
  align: "center"
});