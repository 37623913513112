import {useParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {useApi} from "~shared/api";
import {IUniformItemModel} from "../models/IUniformItemModel";

export const useDeleteUniformItem = () => {
  const {sport, organizationId, teamId} = useParams();
  const {mutateData} = useApi();

  return useMutation({
    mutationFn: (uniformItem: IUniformItemModel) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/uniforms/items/${uniformItem.uniformItemId}`,
        method: "DELETE",
        data: undefined
      });
    }
  });
}
