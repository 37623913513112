import { Space, Tag } from "antd";
import { PersonLogoBadge } from "./PersonLogoBadge";

export const InternalPersonLabel = ({ person }) => {
  return <Space>
    <PersonLogoBadge img={person?.imageHeadUrl}/>
    {person?.nameFullLocal}
    <Tag className={"ml-5 text-upper"} color="geekblue">
      Newly Created
    </Tag>
  </Space>
}