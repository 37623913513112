import { Button } from "antd"
import { useState } from "react"
import { ApproveModal } from "./ApproveModal"

export const ButtonApprove = ({ type }) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return <>
    <Button
      icon={<i className="fa-solid fa-check"></i>}
      onClick={openModal}
      type={"primary"}
      className={"text-upper"}
    >Approve</Button>
    <ApproveModal open={open} type={type} closeModal={closeModal}/>
  </>
}