/* eslint-disable @typescript-eslint/no-explicit-any */
import {useMemo, useState} from "react";
import {Flex, Spin, Tabs} from "antd";
import {IUniformModel} from "./models/IUniformModel";
import {useFetchUniforms} from "./hooks/useFetchUniforms";
import {Uniform} from "./Uniform";
import styled from "styled-components";
import {useSportConfiguration} from "~shared/hooks/useSportConfiguration";
import {isEmpty} from "lodash";

const Wrapper = styled.div`
  
  .UniformsContainer {
    margin-top: 10px;
    min-height: 200px;
  }

  .UniformsContainer[data-loading="yes"] {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-tab {
    border-radius: 0 !important;
  }

  .ant-tabs-tab-active {
    border-top: 1px solid #FF0000 !important;
  }

  .ant-tabs-tabpane {
    overflow: auto;
    width: 100%;
    min-height: 200px;
  }
`;

const NewTabKey = "new-uniform-tab-key";

export const Uniforms = () => {
  const [currentTabKey, setCurrentTabKey] = useState<string>();
  const {data: uniforms, isLoading} = useFetchUniforms();
  const {uniformItemTypes} = useSportConfiguration();
  const [showNewTab, setShowNewTab] = useState<boolean>();


  const onAddedUniformHandler = async (uniformId: string) => {
    setCurrentTabKey(uniformId);
    setShowNewTab(false);
  };

  const onTabsEditHandler = async (key: any, action: 'add' | 'remove') => {
    if (action === 'add') {
      setShowNewTab(true);
      setCurrentTabKey(NewTabKey);
    } else if (key === NewTabKey) {
      setShowNewTab(false);
      setCurrentTabKey("");
    }
  };

  const tabs = useMemo(() => {

    const array = (uniforms || []).map((x: IUniformModel) => ({
      label: x.nameLocal,
      key: x.uniformId,
      closable: false,
      children: (
        <Uniform key={x.uniformId} uniform={x}
                 onDeleted={() => setCurrentTabKey("")}/>
      ),
    }));

    if (showNewTab) {
      array.push({
        label: "*",
        key: NewTabKey,
        closable: true,
        children: (
          <Uniform onAdded={onAddedUniformHandler}/>
        ),
      });
    }

    return array;
  }, [uniforms, showNewTab]);

  // Current sport does not support uniforms
  if (!uniformItemTypes || Object.values(uniformItemTypes).length === 0) {
    return;
  }
  return (
    <Wrapper>
      <Flex className="UniformsContainer" vertical data-loading={isLoading ? "yes" : "no"}>
        {isLoading ? 
            <Spin /> : (
            <Tabs
              activeKey={isEmpty(currentTabKey) ? tabs?.[0]?.key : currentTabKey}
              type="editable-card"
              items={tabs}
              onEdit={onTabsEditHandler}
              onChange={(key) => setCurrentTabKey(key)}
              hideAdd={showNewTab}
            />
        )}
      </Flex>
    </Wrapper>
  );
}
