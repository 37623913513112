import { Select } from "antd";
import { useIntl } from "react-intl";
import styled from "styled-components";

// Project
import { useCallback, useMemo } from "react";
import { useFetchUniforms, useUpdateFixtureEntityUniform } from "../../features/Uniforms/hooks";

const StyledContainer = styled.div`
  min-width: 150px;
  .FixtureTeamUniformSelect {
    width: 100%;
  }
`;
StyledContainer.displayName = 'StyledContainer';

export function FixtureTeamUniformSelect({ fixture, teamId, disabled }) {
  const { formatMessage } = useIntl();
  const {data: uniforms, isLoading } = useFetchUniforms();
  const {mutate: updateFixtureTeamUniform, isLoading: isUpdateInProgress} = useUpdateFixtureEntityUniform();
  const fixtureTeam = useMemo(() => {
    const fixtureTeams = [fixture?.awayEntity, fixture?.homeEntity].filter(it => !!it);
    const fixturePageTeam = fixtureTeams.find(it => it.entityId === teamId);
    return fixturePageTeam;
  }, [fixture, teamId]);
  const uniformsOptions = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return (uniforms || []).map((it) => {
      return ({
        value: it.uniformId,
        label: it.nameLocal || it.nameLatin,
      });
    })
  }, [isLoading, uniforms]);
  const onSelectChange = useCallback(async (uniformId) => {
    await updateFixtureTeamUniform({
      uniformId,
    });
  }, [updateFixtureTeamUniform]);
  
  return (
    <StyledContainer>
      <Select
        className="FixtureTeamUniformSelect"
        allowClear
        showSearch
        loading={isLoading || isUpdateInProgress}
        disabled={disabled || isUpdateInProgress}
        placeholder={formatMessage({ id: "uniform.select" })}
        value={fixtureTeam?.uniformId}
        options={uniformsOptions}
        onChange={onSelectChange}
      />
    </StyledContainer>
  );
}