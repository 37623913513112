import { Button } from "antd"
import { useIntl } from "react-intl"
import { useState } from "react"
import { AddModal } from "./AddModal"
// TODO: refactor store (do not use zustand when you can use react-query directly)
import { useSeasonsStore } from "../../../entities/season"
import { useFixturesStore } from "../../../entities/fixtures"

type Props = {
  type: string
}

export const AddPerson = ({ type }: Props) => {
  const {formatMessage} = useIntl()
  const [open, setOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activeSeason = useSeasonsStore((state: any) => state?.activeSeason)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fixture = useFixturesStore((store: any) => store?.fixture)

  const getIsButtonDisabled = () => {
    switch (type) {
      case "fixtureStaff":
      case "fixtureRoster":
        return !fixture?.areRosterAndStaffEditable
      case "seasonStaff":
      case "seasonRoster":
        return !activeSeason?.areRosterAndStaffEditable
    }

    return true
  }

  const getButtonTitle = () => {
    switch (type) {
      case "fixtureStaff":
      case "seasonStaff":
        return formatMessage({id: "staff.new"})
      default:
        return formatMessage({id: "players.new"})
    }
  }

  return <>
    <Button
      type="primary"
      onClick={() => setOpen(true)}
      disabled={getIsButtonDisabled()}
      className="text-upper"
      icon={<i className="fa-solid fa-plus" />}
    >
      {getButtonTitle()}
    </Button>
    {open && <AddModal open={open} closeModal={() => setOpen(false)} type={type}/>}
  </>
}