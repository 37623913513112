import { Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useFetchFixtureRoster, useFetchSeasonRoster } from "../api";
import { useFixtureRosterStore, useRosterListColumns, useSeasonRosterStore } from "../model";
import { DeleteRoster } from "./DeleteRoster";
import { useRole } from "~shared/hooks";
import { useParams } from "react-router-dom";
import { useRosterPositionsStore } from "../../../shared";

export const RosterList = ({ type, searchText = "", canEdit = true, isEditMode = false, onColumnChanged = undefined, onColumnBlur = undefined }) => {
  const { isLoading: isLoadingSeasonRoster } = useFetchSeasonRoster({
    enabled: type === "seasonRoster",
  });
  const { isLoading: isLoadingFixtureRoster } = useFetchFixtureRoster({
    enabled: type === "fixtureRoster",
  });
  const seasonRoster = useSeasonRosterStore(state => state?.seasonRoster);
  const fixtureRoster = useFixtureRosterStore(store => store?.fixtureRoster);

  const {sport} = useParams();
  const rosterPositions = useRosterPositionsStore(store => store?.[sport] || store?.basketball);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const tableColumns = useRosterListColumns({
    type,
    canEdit: typeof canEdit === "undefined" ? true : canEdit,
    isEditMode,
    rosterPositions,
    onColumnChanged,
    onColumnBlur
  });
  const { isAdmin } = useRole();

  const refreshData = useCallback(() => {
    let sourceData = (type === "seasonRoster" ? seasonRoster : fixtureRoster) || [];
    sourceData = sourceData
      .filter(person => person?.dcPersonId || person?.personId)
      .filter((person) => person?.nameFullLocal?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1
        || person?.personId === searchText
        || person?.dcPersonId === searchText)
    setData(prev => isEditMode ? prev : sourceData);
  }, [type, isEditMode, seasonRoster, fixtureRoster, searchText]);


  const onSelectChange = (newSelectedRowKeys, newSelectedRow) => {
    setSelectedRows(newSelectedRow);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const resetSelectedRows = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return <Table
    loading={type === "seasonRoster" ? isLoadingSeasonRoster : isLoadingFixtureRoster}
    size={"small"}
    dataSource={data}
    rowSelection={isAdmin ? null : rowSelection}
    title={() => selectedRows?.length ? <DeleteRoster type={type} resetSelectedRows={resetSelectedRows}
                                                      persons={selectedRows}/> : null}
    rowKey={(record) => record?.dcPersonId ? record?.dcPersonId : record?.personId}
    pagination={false}
    columns={tableColumns}
  />;
};