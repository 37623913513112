import Dropzone from "react-dropzone";

const allowedFileTypes = ["image/png", "image/jpeg"];

const ImageUploader = (props) => {
  const { handleDrop } = props;

  function doDrop(files) {
    if (allowedFileTypes.includes(files[0].type)) {
      handleDrop(files);
    }
  }

  return (
    <Dropzone onDrop={(acceptedFiles) => doDrop(acceptedFiles)} accept={({"image/jpeg": [".jpg"], "image/png": [".png"]})}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <section className={"dropZone dragActive-" + isDragActive}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p style={{
              border: "1px dashed #ccc",
              padding: "15px 10px",
              lineHeight: "1.1rem",
              textAlign: "center",
              display: "block",
              background: "#eceff1",
              marginTop: "5px",
            }}>Drop an image or click</p>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default ImageUploader;
