import { create } from "zustand";
import { devtools } from "zustand/middleware";

const DEFAULT_STAFF_ROLES = [
  "COACH",
  "COACH_ASSISTANT",
  "COACH_HEAD",
  "DIRECTOR",
  "MEDIA_OFFICER",
  "REFEREE",
  "SECRETARY",
  "TIMER",
  "TRAINER",
];

export const useStaffRolesStore = create(devtools(() => ({
  basketball: DEFAULT_STAFF_ROLES,
  hockey: [
    "COACH",
    "COACH_HEAD",
    "COACH_HEAD_INTERIM",
    "COACH_ASSISTANT",
    "COACH_ASSISTANT_HEAD",
    "COACH_ASSOCIATE_HEAD",
    "COACH_SC",
    "COACH_SENIOR",
    "COACH_DEVELOPMENT",
    "COACH_SPECIALIST",
    "COACH_OTHER",
    "CAPTAIN",
    "CAPTAIN_VICE",
    "DOCTOR",
    "PHYSIOTHERAPIST",
    "MEDICAL_STAFF",
    "TRAINER",
    "TRAINER_ASSISTANT",
    "MATCH_OFFICIAL",
    "COMMISSIONER",
    "STATISTICIAN",
    "SCOREKEEPER",
    "SCOREKEEPER_ASSISTANT",
    "TIMEKEEPER",
    "SCORER",
    "SCORER_ASSISTANT",
    "TIMER",
    "GROUNDSKEEPER",
    "CEO",
    "COMMITTEE",
    "PRESIDENT",
    "DIRECTOR",
    "PRESIDENT_VICE",
    "SECRETARY",
    "TREASURER",
    "MANAGER",
    "GENERAL",
    "OTHER",
    "REFEREE",
    "UMPIRE",
    "UMPIRE_1",
    "UMPIRE_2",
    "UMPIRE_MANAGER",
    "REFEREE_ASSISTANT",
    "REFEREE_RESERVE",
    "REFEREE_ASSISTANT_RESERVE",
    "CREW_CHIEF",
    "UMPIRE_RESERVE",
    "UMPIRE_VIDEO",
    "JUDGE_SCORING",
    "JUDGE_TIMING",
    "TECHNICAL_DELEGATE",
    "TECHNICAL_DELEGATE_ASSISTANT",
    "TECHNICAL_OFFICIAL",
    "VIDEO_TECHNICIAN",
    "TRANSLATOR",
    "MEDIA_OFFICER",
  ],
  handball: ["DELEGATE", "COACH_HEAD", "COACH_ASSISTANT", "DIRECTOR", "MEDIA_OFFICER", "TRAINER"],
})), { name: "staffRoles" });