import { Space, Tag } from "antd";
import { PersonLogoBadge } from "./PersonLogoBadge";

export const PersonLabel = ({ person, alreadyAdded, newlyCreated }) => {
  return <Space>
    <PersonLogoBadge img={person?.imageHeadUrl}/>
    {person?.nameFullLocal}
    {alreadyAdded && (
      <Tag className={"ml-5 text-upper"} color="default">
        Already Added
      </Tag>
    )}
    {newlyCreated && (
      <Tag className={"ml-5 text-upper"} color="geekblue">
        Newly Created
      </Tag>
    )}
  </Space>
}