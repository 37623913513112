import { Table } from "antd"
import { useFetchFixtures } from "../api/useFixtureAPI"
import { useFixturesStore } from "../model"
import { useColumnFixtureTableForCopy } from "./useColumnFixtureTableForCopy"
import { useParams } from "react-router-dom"

export const FixtureListForCopy = ({ setFixtureId }) => {
  const { fixtureId } = useParams()
  const { isLoading } = useFetchFixtures()
  const fixtures = useFixturesStore(state => state?.fixtures)
  const columns = useColumnFixtureTableForCopy({ setFixtureId })

  return <Table size={"small"}
                columns={columns}
                loading={isLoading}
                dataSource={fixtures?.data?.filter(fixture => fixture?.fixtureId !== fixtureId)}
                rowKey={"fixtureId"}
  />
}