import { Link, useParams } from "react-router-dom";
import { useTeamsStore } from "../../../entities/teams/model";
import { Select, Space, theme, Typography } from "antd";
import { useTeamsQuery, useTeamQuery } from "../../../entities/teams/api";
import { useNavigate } from "react-router-dom";
import { TeamLogoBadge } from "../../../shared";

const { useToken } = theme;

export const TeamSelect = () => {
  const { token } = useToken();
  const { customerId, organizationId, sport, teamId, seasonId } = useParams();
  useTeamQuery();
  const { isLoading, data } = useTeamsQuery(organizationId);
  const activeTeam = useTeamsStore(state => state?.activeTeam);
  const navigate = useNavigate();

  const handleChangeTeam = (value) => {
    navigate(`${customerId}/${organizationId}/${sport}/teams/${value}`);
  };

  if (!activeTeam || !data?.data?.length) return <Typography.Text>Teams</Typography.Text>;

  if (seasonId) {
    return <Link to={`${customerId}/${organizationId}/${sport}/teams/${teamId}`}>
      <Space>
        <TeamLogoBadge size={25} img={activeTeam?.images?.filter(img => img?.imageType === "LOGO")?.[0]?.url}/>
        <Typography.Text style={{ color: token.colorPrimary }} strong>{activeTeam?.nameFullLocal}</Typography.Text>
      </Space>
    </Link>;
  }

  return <>
    <Space size={0}>
      <TeamLogoBadge size={25} img={activeTeam?.images?.filter(img => img?.imageType === "LOGO")?.[0]?.url}/>
      <Select
        popupMatchSelectWidth={false}
        bordered={false}
        showSearch
        onChange={handleChangeTeam}
        filterOption={(input, option) =>
          (option?.label ?? "")?.toLowerCase()?.includes(input.toLowerCase())
        }
        optionRender={({ data }) => {
          return <Space>
            <TeamLogoBadge img={data?.img}/>
            {data?.label}
          </Space>;
        }}
        defaultValue={teamId}
        loading={isLoading}
        options={data?.data?.map(x => {
          return {
            label: x?.nameFullLocal,
            value: x?.entityId,
            img: x?.imageLogoUrl,
          };
        })}>
      </Select>
    </Space>
  </>;
};