import { Divider, Row, Skeleton, Space, Switch, Typography } from "antd";
import { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

// Project
import { BackToButton, SearchInput, TaskStatusTag } from "../../shared";
import { useFetchSeason, useSeasonsStore } from "../../entities/season";
import { RosterList } from "../../entities/roster";
import { SubmitSeasonStaff } from "../../features/SubmitSeasonStaff";
import { CopyPersons } from "../../features/CopyPersons";
import { AddPerson } from "../../features/AddPerson";
import { useUpdateRosterByPerson } from "../../entities/roster/api";

export const SeasonRoster = () => {
  const { customerId, organizationId, sport, teamId } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading } = useFetchSeason();
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const activeSeason = useSeasonsStore(state => state?.activeSeason);

  const {
    updated: updatedPerson,
    isLoading: isUpdating,
    mutate: updateRoster
  } = useUpdateRosterByPerson({ type: "seasonRoster" })

  const canEdit = useCallback((column, record) => {
    const isTarget = record === updatedPerson;
    return !(isTarget && isUpdating);
  }, [isUpdating, updatedPerson]);
  
  const onColumnChanged = useCallback(async ({ column, value, record }) => {
    const dataCopy = {
      [column]: value,
    }
    dataCopy[column] = column === "number" ? String(dataCopy[column]) : dataCopy[column];
    await updateRoster(dataCopy, record)
  }, [updateRoster]);

  const onIsEditModeChange = useCallback((checked) => {
    setIsEditMode(checked);
  }, []);

  return <>
    <Row className={"mt-15"} justify={"space-between"}>
      {isLoading
        ? <Skeleton.Input/>
        : <Space align={"center"}>
          <Typography.Title level={3} style={{ margin: 0 }}>
            {formatMessage({
              id: "roster",
              defaultMessage: "Roster",
            })} - {activeSeason?.nameLocal}</Typography.Title>
          {activeSeason?.taskStatus && <TaskStatusTag status={activeSeason?.taskStatus}/>}
        </Space>}
      <BackToButton to={`/${customerId}/${organizationId}/${sport}/teams/${teamId}`} btnText={formatMessage({
        id: "back.to.team",
        defaultMessage: "Back to team",
      })}/>
    </Row>
    <Divider/>
    <Row className={"mb-10"} justify={"space-between"} align={"middle"}>
      <Typography.Text strong>Season Roster</Typography.Text>
      <Space>
        <span>{formatMessage({ id: "edit.mode", defaultMessage: "Edit mode" })}</span>
        <Switch checked={isEditMode} disabled={!activeSeason?.areRosterAndStaffEditable || false} onChange={onIsEditModeChange} />
        <SearchInput doSearch={setSearchText}/>
        <CopyPersons type={"seasonRoster"}/>
        <AddPerson type={"seasonRoster"}/>
        <SubmitSeasonStaff type={"seasonRoster"}/>
      </Space>
    </Row>
    <RosterList searchText={searchText} type="seasonRoster" canEdit={canEdit} isEditMode={isEditMode} onColumnChanged={onColumnChanged} />
  </>;
};