import { Tabs, Typography } from "antd";
import UploadForm from "./UploadForm";
import { useTeamsStore } from "../../../entities/teams/model";
import { useIntl } from "react-intl";

export const UpdateImageWrap = () => {
  const { formatMessage } = useIntl();
  const activeTeam = useTeamsStore(state => state?.activeTeam);

  return <Tabs
    centered
    type="card"
    tabBarStyle={{
      marginBottom: 0,
    }}
    items={[
      {
        key: "logo",
        label: <Typography.Text className={"text-upper"}>
          {formatMessage({
            id: "image.logo",
            defaultMessage: "Logo",
          })}
        </Typography.Text>,
        children: <UploadForm imageType={"logo"}
                              image={activeTeam?.images?.filter(img => img?.imageType === "LOGO")?.[0]}/>,
      },
      {
        key: "logo_alternate",
        label: <Typography.Text className={"text-upper"}>
          {formatMessage({
            id: "image.logo.alternate",
            defaultMessage: "Logo Alternate",
          })}
        </Typography.Text>,
        children: <UploadForm imageType={"logo_alternate"}
                              image={activeTeam?.images?.filter(img => img?.imageType === "LOGO_ALTERNATE")?.[0]}/>,
      },
      {
        key: "logo_background",
        label: <Typography.Text className={"text-upper"}>
          {formatMessage({
            id: "image.logo.background",
            defaultMessage: "Logo Background",
          })}
        </Typography.Text>,
        children: <UploadForm imageType={"logo_background"}
                              image={activeTeam?.images?.filter(img => img?.imageType === "LOGO_BACKGROUND")?.[0]}/>,
      },
    ]}/>;
};