import { Col, Row, Tabs, Typography } from "antd"
import { AdminFixtureTaskHistory } from "../../../entities/task"
import { RosterList } from "../../../entities/roster"
import { StaffList } from "../../../entities/staff"
import { TaskActions } from "../../../widgets/taskActionButtons"
import { useFixturesStore } from "../../../entities/fixtures"

const items = [
  {
    key: "roster",
    label: <Typography.Text className={"text-upper"}>Roster</Typography.Text>,
    children: <RosterList type={"fixtureRoster"}/>
  },
  {
    key: "staff",
    label: <Typography.Text className={"text-upper"}>Staff</Typography.Text>,
    children: <StaffList type={"fixtureStaff"}/>
  }
]

export const FixtureTabs = () => {
  const fixture = useFixturesStore(store => store?.fixture)

  return <Row gutter={5}>
    <Col xs={20} sm={20} md={16} lg={16} xl={17} xxl={20}>
      <Tabs className={"mt-15"} centered={true} defaultActiveKey={"roster"} items={items}>
      </Tabs>
    </Col>
    <Col xs={4} sm={40} md={8} lg={8} xl={7} xxl={4}>
      <div className={"mb-15 mt-30"} style={{
        display: "flex",
        justifyContent: "end",
        minHeight: "33px"
      }}>
        <TaskActions type={"fixtureRoster"} status={fixture?.taskStatus}/>
      </div>
      <AdminFixtureTaskHistory/>
    </Col>
  </Row>
}