import { Button, Modal, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import {
  useDeleteFixtureStaff,
  useDeleteSeasonStaff,
} from "../api";
import { useSeasonsStore } from "../../season";
import { useFixturesStore } from "../../fixtures";

export const DeleteStaff = ({ resetSelectedRows, persons, type, buttonType }) => {
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const activeSeason = useSeasonsStore(state => state?.activeSeason);
  const activeFixture = useFixturesStore(state => state?.fixture);
  const {
    isLoading: isLoadingDeleteSeasonStaff,
    mutate: deleteSeasonStaff,
    isSuccess: isSuccessDeleteSeasonStaff,
  } = useDeleteSeasonStaff();
  const {
    isLoading: isLoadingDeleteFixtureStaff,
    mutate: deleteFixtureStaff,
    isSuccess: isSuccessDeleteFixtureStaff,
  } = useDeleteFixtureStaff();
  useEffect(() => {
    if (isSuccessDeleteSeasonStaff || isSuccessDeleteFixtureStaff) {
      if (resetSelectedRows) resetSelectedRows();
      handleCancel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDeleteSeasonStaff, isSuccessDeleteFixtureStaff]);

  useEffect(() => {
    switch (type) {
      case "seasonStaff":
      case "seasonRoster":
        setEditable(activeSeason?.areRosterAndStaffEditable);
        break;
      case "fixtureRoster":
      case "fixtureStaff":
        setEditable(activeFixture?.areRosterAndStaffEditable);
    }
  }, [type, activeFixture, activeSeason]);

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = async () => {
    const dcPersonId = persons?.filter(person => person?.dcPersonId)?.map(person => person?.dcPersonId);
    const personId = persons?.filter(person => person?.personId)?.map(person => person?.personId);

    try {
      if (type === "seasonStaff") {
        deleteSeasonStaff({
          personIds: personId,
          dcPersonIds: dcPersonId,
        });
      } else {
        deleteFixtureStaff({
          personIds: personId,
          dcPersonIds: dcPersonId,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return <>
    {buttonType !== "small"
      ? <Space>
        <Button disabled={!editable} danger onClick={showModal}
                type={"primary"}>Delete</Button>
        <Typography.Text>Selected {persons?.length} item(s)</Typography.Text>
      </Space>
      : <Button disabled={!editable} onClick={showModal} type={"text"}
                icon={<i className="fa-solid fa-trash-can"></i>}/>
    }
    <Modal
      title={`Are you sure you want to delete ${persons?.length} person(s)?`}
      open={open}
      onOk={handleOk}
      okText={"Delete"}
      okButtonProps={{
        danger: true,
      }}
      confirmLoading={isLoadingDeleteSeasonStaff || isLoadingDeleteFixtureStaff}
      onCancel={handleCancel}
    />
  </>;
};