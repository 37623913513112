import { Form, Select } from "antd";
import { useIntl } from "react-intl";
import { useStaffRolesStore } from "../../../shared";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const StaffForm = ({ person, setFormRef }) => {
  const { sport } = useParams();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const staffRoles = useStaffRolesStore(store => store?.[sport] || store?.basketball);

  useEffect(() => {
    setFormRef(form);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Form
    form={form}
    initialValues={person}
    labelCol={{
      span: 5,
    }}
    wrapperCol={{
      span: 10,
    }}
  >
    <Form.Item
      label={formatMessage({
        id: "role",
        defaultMessage: "Role",
      })}
      name={"role"}
    >
      <Select
        placeholder={"Select role..."}
        showSearch
        options={staffRoles?.map(role => {
          return {
            label: formatMessage({
              id: `roles.${role}`,
              defaultMessage: role,
            }),
            value: role,
          };
        })}
      />
    </Form.Item>
  </Form>;
};