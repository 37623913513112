import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CustomerSelection } from "../pages/CustomerSelection";
import { OrganizationSelection } from "../pages/OrganizationSelection";
import { TeamsList, Team, EditTeam } from "../pages/Teams";
import { Error } from "../pages/Error";
import { Root } from "../pages/Root";
import { FixturesPage } from "../pages/Fixtures";
import { FixtureRosterPage, FixtureStaffPage, Page403, Page404, SeasonRosterPage, SeasonStaffPage } from "../pages";
import { RoleSelectionPage } from "../pages/Role";
import { AdminDashboardPage } from "../pages/AdminDashboard";
import { AdminSeasonViewPage } from "../pages/AdminSeasonView";
import { AdminFixtureViewPage } from "../pages/AdminFixtureView";
import { LogoutRedirectPage } from "../pages/Logout";

const router = createBrowserRouter([
  {
    path: "403",
    element: <Page403/>,
  },
  {
    path: "404",
    element: <Page404/>,
  },
  {
    path: "logout",
    element: <LogoutRedirectPage/>,
  },
  {
    path: "/",
    element: <Root/>,
    errorElement: <Error/>,
    children: [
      {
        path: "/",
        element: <CustomerSelection/>,
      },
      {
        path: ":customerId",
        element: <OrganizationSelection/>,
      },
      // team pages
      {
        path: ":customerId/:organizationId/:sport/teams",
        element: <TeamsList/>,
      },
      {
        path: ":customerId/:organizationId/:sport/teams/:teamId",
        element: <Team/>,
      },
      {
        path: ":customerId/:organizationId/:sport/teams/:teamId/edit",
        element: <EditTeam/>,
      },
      // season pages
      {
        path: ":customerId/:organizationId/:sport/teams/:teamId/seasons/:seasonId/fixtures",
        element: <FixturesPage/>,
      },
      {
        path: ":customerId/:organizationId/:sport/teams/:teamId/seasons/:seasonId/fixtures/:fixtureId/staff",
        element: <FixtureStaffPage/>,
      },
      {
        path: ":customerId/:organizationId/:sport/teams/:teamId/seasons/:seasonId/fixtures/:fixtureId/roster",
        element: <FixtureRosterPage/>,
      },
      {
        path: ":customerId/:organizationId/:sport/teams/:teamId/seasons/:seasonId/roster",
        element: <SeasonRosterPage/>,
      },
      {
        path: ":customerId/:organizationId/:sport/teams/:teamId/seasons/:seasonId/staff",
        element: <SeasonStaffPage/>,
      },
      //admin pages
      {
        path: ":customerId/:organizationId/:sport/role",
        element: <RoleSelectionPage/>,
      },
      {
        path: ":customerId/:organizationId/:sport/:role",
        element: <AdminDashboardPage/>,
      },
      {
        path: ":customerId/:organizationId/:sport/:role/teams/:teamId/seasons/:seasonId/view",
        element: <AdminSeasonViewPage/>,
      },
      {
        path: ":customerId/:organizationId/:sport/:role/teams/:teamId/seasons/:seasonId/fixtures/:fixtureId/view",
        element: <AdminFixtureViewPage/>,
      },
      {
        path: ":customerId/:organizationId/:sport/:role/teams/:teamId/fixtures/:fixtureId/view",
        element: <AdminDashboardPage/>,
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router}/>;
};

export default Router;