import { Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return <Button
    className={"ml-15 mb-25"}
    onClick={() => logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })}
    icon={<i style={{
      fontSize: "1.5rem",
    }} className="fa-solid fa-right-from-bracket"/>} size={"large"} type={"text"}></Button>;
};