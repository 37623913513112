import { Button, Col, Row, Select, Space, Table, Typography } from "antd"
import { useIntl } from "react-intl"
import moment from "moment"
import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useFetchSeasons } from "../api"
import { useSeasonsStore } from "../model"
import { CompetitionLogoBadge, SearchInput, SeasonLogoBadge } from "../../../shared/ui"

type Props = {
  setSeasonId: (seasonId: string | null) => void
  type?: string
}

// TODO: move types of api when it will be created
type Season = {
  // TODO: add AgeGroup type
  ageGroup: string
  areRosterAndStaffEditable: boolean
  competitionImageUrl: string | null
  competitionNameLatin: string | null
  competitionNameLocal: string
  containsFixtureTasksInRejectedStatus: boolean
  /** e.g. "2026-12-30T00:00:00" */
  endDate: string
  entityId: string
  entityImageLogoAlternateUrl: string | null
  entityImageLogoBackgroundUrl: string | null
  entityImageLogoUrl: string | null
  entityNameLatin: string | null
  entityNameLocal: string
  gender: string
  imageUrl: null
  isRegistrationOpened: true
  nameLatin: string
  nameLocal: string
  seasonId: string
  /** e.g. "2026-12-30T00:00:00" */
  startDate: string
  // TODO: add SeasonStatus type
  status: string
  taskHistoryRecords: TaskHistory[]
  // TODO: add TaskStatus type
  taskStatus: string
  year: number
}

// TODO: move types of api when it will be created
type TaskHistory = {
  /** e.g. "2024-07-16T08:43:48.69645" */
  added: string
  text: string
  type: string
  /** e.g. "2024-07-16T08:43:48.69645" */
  updated: string
  userEmail: string
}

export const SeasonsListForCopy = ({ setSeasonId, type }: Props) => {
  const { seasonId } = useParams()
  const { formatMessage } = useIntl()
  // TODO: be more specific with type
  const [activeFilter, setActiveFilter] = useState("DEFAULT")
  const [searchText, setSearchText] = useState("")
  useFetchSeasons()
  const seasons: Season[] = useSeasonsStore(state => state?.seasons)

  // TODO: not memo but debounce - meaning, do not fill memory with bunch of memoization data, but improve performance by reducing number of calls
  const filteredSeason = useMemo(() => {
    let data = seasons
      ?.filter(season => activeFilter === "DEFAULT" ? season?.status === "ACTIVE" || season?.status === "PENDING" : season?.status === activeFilter)
      ?.filter((season) => season?.nameLocal?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1)
    switch (type) {
      case "seasonRoster":
      case "seasonStaff":
        data = data?.filter(season => season?.seasonId !== seasonId)
        break
    }

    return data
  }, [seasons, type, seasonId, activeFilter, searchText])

  const selectOptionsData = [{
    intlId: "default.view",
    defaultMessage: "Default View",
    value: "DEFAULT"
  }, {
    intlId: "active.seasons",
    defaultMessage: "Active Seasons",
    value: "ACTIVE"
  }, {
    intlId: "pending.seasons",
    defaultMessage: "Pending Seasons",
    value: "PENDING"
  }, {
    intlId: "inactive.seasons",
    defaultMessage: "Inactive Seasons",
    value: "INACTIVE"
  }]

  return (
    <Row className="mb-15">
      <Col span={24}>
        <Row justify="space-between" align="middle" className="mb-10">
          <Typography.Text strong>
            {formatMessage({
              id: "seasons",
              defaultMessage: "Seasons"
            })}
          </Typography.Text>
          <Space align="center">
            <Select
              popupMatchSelectWidth={false}
              defaultValue="DEFAULT"
              onChange={setActiveFilter}
              options={
                selectOptionsData.map(option => ({
                  label: formatMessage({
                    id: option.intlId,
                    defaultMessage: option.defaultMessage
                  }),
                  value: option.value
                }))
              }
            />
            <SearchInput doSearch={setSearchText} />
          </Space>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          size="small"
          dataSource={filteredSeason}
          rowKey="seasonId"
          columns={[
            {
              title: formatMessage({
                id: "year",
                defaultMessage: "year"
              }),
              dataIndex: "year",
              width: 1,
              align: "center"
            },
            {
              title: formatMessage({
                id: "competition.name",
                defaultMessage: "Competition Name"
              }),
              dataIndex: "competitionNameLocal",
              render: (competitionNameLocal, record) => <Space>
                <CompetitionLogoBadge img={record?.competitionImageUrl}/>
                {competitionNameLocal}
              </Space>,
            },
            {
              title: formatMessage({
                id: "season.name",
                defaultMessage: "Season Name"
              }),
              dataIndex: "nameLocal",
              render: (nameLocal, record) => <Space>
                <SeasonLogoBadge img={record?.imageUrl}/>
                {nameLocal}
              </Space>,
            },
            {
              title: formatMessage({
                id: "gender",
                defaultMessage: "Gender"
              }),
              dataIndex: "gender",
              render: (gender) => formatMessage({
                id: "gender." + gender,
                defaultMessage: gender
              }),
              align: "center"
            },
            {
              title: formatMessage({
                id: "age.group",
                defaultMessage: "Age Group"
              }),
              dataIndex: "ageGroup",
              render: (ageGroup) => formatMessage({
                id: "age.group." + ageGroup,
                defaultMessage: ageGroup
              }),
              align: "center"
            },
            {
              title: formatMessage({
                id: "start.date",
                defaultMessage: "Start Date"
              }),
              dataIndex: "startDate",
              render: startDate => moment(startDate).format("DD/MM/YYYY"),
              align: "center"
            },
            {
              title: formatMessage({
                id: "end.date",
                defaultMessage: "End Date"
              }),
              dataIndex: "endDate",
              render: endDate => moment(endDate).format("DD/MM/YYYY"),
              align: "center"
            },
            {
              title: formatMessage({
                id: "status",
                defaultMessage: "Status"
              }),
              dataIndex: "status",
              render: (status) => formatMessage({
                id: "status." + status,
                defaultMessage: status
              }),
              align: "center"
            },
            {
              dataIndex: "seasonId",
              render: (seasonId) => (
                <Button
                  onClick={() => setSeasonId(seasonId)}
                  type="text"
                  icon={<i className="fa-solid fa-eye" />}
                />
              )
            }
          ]}
        />
      </Col>
    </Row>
  )
}