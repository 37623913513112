import { useApi } from "../../../shared/api";
import { useQuery } from "@tanstack/react-query";
import { useCustomersStore } from "../model";

export const useFetchCustomers = async () => {
  const { fetchData } = useApi();
  const setCustomers = useCustomersStore(state => state?.set);

  return useQuery({
    queryKey: [],
    queryFn: () => fetchData(`/common/customers`),
    onSuccess: (data) => {
      setCustomers(data?.data);
    },
  });
};