import { Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSubmitSeasonTask } from "../../../entities/season";
import { useSubmitFixtureTask } from "../../../entities/fixtures";

export const SubmitModal = ({ open, handleCloseModal, type }) => {
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [headerTitleText, setHeaderTitleText] = useState("");
  const [bodyText, setBodyText] = useState([]);
  const [okButtonText, setOkButtonText] = useState([""]);

  const {
    mutate: submitSeasonTask, isLoading: isLoadingSeasonTask, isSuccess: isSuccessSeasonTask,
  } = useSubmitSeasonTask();
  const {
    mutate: submitFixtureTask, isLoading: isLoadingFixtureTask, isSuccess: isSuccessFixtureTask,
  } = useSubmitFixtureTask();

  useEffect(() => {
    switch (type) {
      case "seasonStaff":
      case "fixtureStaff":
        if (readyToSubmit) {
          setOkButtonText("Submit Staff");
        } else {
          setOkButtonText("Yes");
        }
        break;
      case "seasonRoster":
      case "fixtureRoster":
        if (readyToSubmit) {
          setOkButtonText("Submit Roster");
        } else {
          setOkButtonText("Yes");
        }
        break;
      default:
        return "Unexpected error. Please reload the page.";
    }
  }, [readyToSubmit, type]);

  useEffect(() => {
    switch (type) {
      case "seasonStaff":
        if (readyToSubmit) {
          setHeaderTitleText("Submit Season Roster & Staff?");
        } else {
          setHeaderTitleText("Submit Season Roster & Staff?");
        }
        break;
      case "fixtureStaff":
        if (readyToSubmit) {
          setHeaderTitleText("Submit Fixture Roster & Staff?");
        } else {
          setHeaderTitleText("Submit Fixture Roster & Staff?");
        }
        break;
      case "seasonRoster":
        if (readyToSubmit) {
          setHeaderTitleText("Submit Season Roster & Staff?");
        } else {
          setHeaderTitleText("Submit Season Roster & Staff?");
        }
        break;
      case "fixtureRoster":
        if (readyToSubmit) {
          setHeaderTitleText("Submit Fixture Roster & Staff?");
        } else {
          setHeaderTitleText("Submit Fixture Roster & Staff?");
        }
        break;
      default:
        return "Unexpected error. Please reload the page.";
    }
  }, [readyToSubmit, type]);

  useEffect(() => {
    switch (type) {
      case "seasonStaff":
        if (readyToSubmit) {
          setBodyText([]);
        } else {
          setBodyText(["Have you added all the Team Staff roles you need for the Season?", "Have you selected the Player roster?", "By selecting YES, both Player & Staff rosters will be submitted."]);
        }
        break;
      case "fixtureStaff":
        if (readyToSubmit) {
          setBodyText([]);
        } else {
          setBodyText(["Have you added all the Team Staff roles you need for this Match?", "Have you selected the Player roster?", "By selecting YES, both Player & Staff rosters will be submitted."]);
        }
        break;
      case "seasonRoster":
        if (readyToSubmit) {
          setBodyText([]);
        } else {
          setBodyText(["Have you added all the players & staff you need for the Season Roster?", "Are the Player Numbers correct?", "Are the Player Positions correct?", "Have you selected a Captain?", "Have you selected Team Staff?", "By selecting YES, both Player & Staff rosters will be submitted."]);
        }
        break;
      case "fixtureRoster":
        if (readyToSubmit) {
          setBodyText([]);
        } else {
          setBodyText(["Have you added all the players you need for the Fixture Roster?", "Are the Player Numbers correct?", "Are the Player Positions correct?", "Have you selected a Captain?", "Have you selected Team Staff?", "By selecting YES, both Player & Staff rosters will be submitted."]);
        }
        break;
      default:
        return "Unexpected error. Please reload the page.";
    }
  }, [readyToSubmit, type]);

  useEffect(() => {
    if (isSuccessSeasonTask || isSuccessFixtureTask) {
      onCancel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSeasonTask, isSuccessFixtureTask]);

  const onCancel = () => {
    setReadyToSubmit(false);
    handleCloseModal();
  };

  const handleChangeReadyStatus = () => {
    if (readyToSubmit) {
      submit();
    } else {
      setReadyToSubmit(prev => !prev);
    }
  };

  const submit = () => {
    switch (type) {
      case "seasonStaff":
      case "seasonRoster":
        submitSeasonTask();
        break;
      case "fixtureStaff":
      case "fixtureRoster":
        submitFixtureTask();
        break;
    }
  };

  return <Modal
    open={open}
    destroyOnClose={true}
    onOk={handleChangeReadyStatus}
    title={headerTitleText}
    onCancel={onCancel}
    cancelText={"No"}
    okButtonProps={{
      className: "text-upper", loading: isLoadingSeasonTask || isLoadingFixtureTask,
    }}
    cancelButtonProps={{
      className: "text-upper",
    }}
    okText={okButtonText}
  >
    {bodyText?.map(text => <p className={"mt-0 mb-5"} key={text}><Typography.Text>{text}</Typography.Text></p>)}
  </Modal>;
};