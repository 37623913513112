import { create } from "zustand"
import { devtools } from "zustand/middleware"

export const useVenuesStore = create(devtools((set) => ({
  venues: [],
  set: (data) => {
    set({
      venues: data
    })
  }
})), { name: "venues" });