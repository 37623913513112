import { Layout, Space } from "antd";
import { UserHeaderProfile } from "../../../entities/user";
import "./index.css";
import { ChangeLanguage } from "../../../features/ChangeLanguage";
import { ChangeUserRole } from "../../../features/ChangeUserRole";
import { HeaderLogo } from "./HeaderLogo";

export const Header = () => {
  return <Layout.Header style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderBottom: "1px solid #ccc",
    padding: "0 25px",
    height: "50px",
  }}>
    <HeaderLogo/>
    <Space size={15}>
      <ChangeUserRole/>
      <ChangeLanguage/>
      <UserHeaderProfile/>
    </Space>
  </Layout.Header>;
};