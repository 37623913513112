import { Button, Modal } from "antd"
import { useIntl } from "react-intl"
import { useEffect, useMemo, useRef } from "react"
import {
  useCopyStaffFromFixtureToFixture,
  useCopyStaffFromSeasonToFixture,
  useCopyStaffFromSeasonToSeason
} from "../../entities/staff/api"
import {
  useCopyRosterFromFixtureToFixture, useCopyRosterFromSeasonToFixture, useCopyRosterFromSeasonToSeason
} from "../../entities/roster/api"

export const CopyButton = ({
  type, fromSeasonId = null, fromFixtureId = null, closeModal
}) => {
  const { formatMessage } = useIntl()
  const [{ confirm }, contextHolder] = Modal.useModal()
  // staff
  const {
    mutate: copyStaffFromSeasonToSeason,
    isLoading: isLoadingCopyStaffFromSeasonToSeason,
    isSuccess: isSuccessCopyStaffFromSeasonToSeason
  } = useCopyStaffFromSeasonToSeason()
  const {
    mutate: copyStaffFromSeasonToFixture,
    isLoading: isLoadingCopyStaffFromSeasonToFixture,
    isSuccess: isSuccessCopyStaffFromSeasonToFixture
  } = useCopyStaffFromSeasonToFixture()
  const {
    mutate: copyStaffFromFixtureToFixture,
    isLoading: isLoadingCopyStaffFromFixtureToFixture,
    isSuccess: isSuccessCopyStaffFromFixtureToFixture
  } = useCopyStaffFromFixtureToFixture()
  // roster
  const {
    mutate: copyRosterFromSeasonToSeason,
    isLoading: isLoadingCopyRosterFromSeasonToSeason,
    isSuccess: isSuccessCopyRosterFromSeasonToSeason
  } = useCopyRosterFromSeasonToSeason()
  const {
    mutate: copyRosterFromSeasonToFixture,
    isLoading: isLoadingCopyRosterFromSeasonToFixture,
    isSuccess: isSuccessCopyRosterFromSeasonToFixture
  } = useCopyRosterFromSeasonToFixture()
  const {
    mutate: copyRosterFromFixtureToFixture,
    isLoading: isLoadingCopyRosterFromFixtureToFixture,
    isSuccess: isSuccessCopyRosterFromFixtureToFixture
  } = useCopyRosterFromFixtureToFixture()

  const buttonText = useMemo(() => {
    switch (type) {
      case "fixtureRoster":
      case "seasonRoster":
        return formatMessage({
          id: "roster.copy", defaultMessage: "Copy Roster"
        })
      case "fixtureStaff":
      case "seasonStaff":
        return formatMessage({
          id: "staff.copy", defaultMessage: "Copy Staff"
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const copyWarningMessage = useMemo(() => {
    switch (type) {
      case "fixtureRoster":
        return "Warning - any existing players previously added to the Match Roster will be overridden by the copied Roster."
      case "seasonRoster":
        return "Warning - any existing players previously added to the Season Roster will be overridden by the copied Roster."
      case "fixtureStaff":
        return "Warning - any existing persons previously added to the Match Staff will be overridden by the copied Staff."
      case "seasonStaff":
        return "Warning - any existing persons previously added to the Season Staff will be overridden by the copied Staff."
    }
  }, [type])

  const isLoading = useMemo(() => {
    if (type === "fixtureStaff") {
      if (fromSeasonId) return isLoadingCopyStaffFromSeasonToFixture
      if (fromFixtureId) return isLoadingCopyStaffFromFixtureToFixture
    }
    if (type === "fixtureRoster") {
      if (fromSeasonId) return isLoadingCopyRosterFromSeasonToFixture
      if (fromFixtureId) return isLoadingCopyRosterFromFixtureToFixture
    }
    if (type === "seasonRoster") {
      if (fromSeasonId) return isLoadingCopyRosterFromSeasonToSeason
    }
    if (type === "seasonStaff") {
      if (fromSeasonId) return isLoadingCopyStaffFromSeasonToSeason
    }
  }, [isLoadingCopyStaffFromSeasonToFixture, isLoadingCopyStaffFromFixtureToFixture, isLoadingCopyRosterFromSeasonToFixture, isLoadingCopyRosterFromFixtureToFixture, isLoadingCopyRosterFromSeasonToSeason, isLoadingCopyStaffFromSeasonToSeason, type, fromSeasonId, fromFixtureId])

  useEffect(() => {
    const isSomeSuccess = isSuccessCopyStaffFromSeasonToFixture || isSuccessCopyRosterFromSeasonToFixture || isSuccessCopyStaffFromFixtureToFixture || isSuccessCopyRosterFromFixtureToFixture || isSuccessCopyRosterFromSeasonToSeason || isSuccessCopyStaffFromSeasonToSeason

    if (isSomeSuccess) {
      closeModal?.()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCopyStaffFromSeasonToFixture, isSuccessCopyRosterFromSeasonToFixture, isSuccessCopyStaffFromFixtureToFixture, isSuccessCopyRosterFromFixtureToFixture, isSuccessCopyRosterFromSeasonToSeason, isSuccessCopyStaffFromSeasonToSeason])

  const copyFn = () => {
    if (type === "fixtureStaff") {
      if (fromSeasonId) {
        copyStaffFromSeasonToFixture({
          fromSeasonId
        })
      }
      if (fromFixtureId) {
        copyStaffFromFixtureToFixture({
          fromFixtureId
        })
      }
    }
    if (type === "fixtureRoster") {
      if (fromSeasonId) {
        copyRosterFromSeasonToFixture({
          fromSeasonId
        })
      }
      if (fromFixtureId) {
        copyRosterFromFixtureToFixture({
          fromFixtureId
        })
      }
    }
    if (type === "seasonRoster") {
      if (fromSeasonId) {
        copyRosterFromSeasonToSeason({
          fromSeasonId
        })
      }
    }

    if (type === "seasonStaff") {
      if (fromSeasonId) {
        copyStaffFromSeasonToSeason({
          fromSeasonId
        })
      }
    }
  }

  const isOpen = useRef(false)
  const showConfirm = () => {
    if (isOpen.current) return

    isOpen.current = true
    confirm({
      icon: null, content: copyWarningMessage, okText: "Copy", onOk: () => copyFn()
    }).then(() => {
      isOpen.current = false
    })
  }

  return (
    <>
      {contextHolder}
      <Button
        onClick={showConfirm}
        type="primary"
        loading={isLoading}
        className="text-upper"
      >
        {buttonText}
      </Button>
    </>
  )
}