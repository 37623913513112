import { useApi } from "../../../shared/api";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { notification } from "antd";
import { useTaskStore } from "../model";

export const useFetchFixtureTask = () => {
  const { fetchData } = useApi();
  const { organizationId, sport, teamId, seasonId, fixtureId } = useParams();
  const setTask = useTaskStore(store => store?.setTask);

  return useQuery({
    queryKey: ["task", teamId, seasonId, fixtureId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/task`),
    onSuccess: (data) => {
      setTask(data?.data?.[0] || null);
    },
    onError: err => {
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch task",
        description: err?.message,
      });
    },
  });
};

export const useApproveFixtureTask = () => {
  const { mutateData } = useApi();
  const { organizationId, sport, teamId, seasonId, fixtureId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/task/approve`,
        method: "PATCH",
        data: {},
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["task", teamId, seasonId, fixtureId] });
      queryClient.invalidateQueries({ queryKey: ["fixtures", teamId, seasonId] });
      queryClient.invalidateQueries({ queryKey: ["fixtures", teamId, seasonId, fixtureId] });
      notification.success({
        placement: "bottomRight",
        message: "Reject roster/staff successfully",
      });
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Reject roster/staff has failed",
        description: error?.message,
      });
    },
  });
};

export const usePublishFixtureTask = () => {
  const { mutateData } = useApi();
  const { organizationId, sport, teamId, seasonId, fixtureId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/task/publish`,
        method: "PATCH",
        data: {},
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["task", teamId, seasonId, fixtureId] });
      queryClient.invalidateQueries({ queryKey: ["fixtures", teamId, seasonId] });
      queryClient.invalidateQueries({ queryKey: ["fixtures", teamId, seasonId, fixtureId] });
      notification.success({
        placement: "bottomRight",
        message: "Publish roster/staff successfully",
      });
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Publish roster/staff has failed",
        description: error?.message,
      });
    },
  });
};

export const useDeleteFixtureTask = () => {
  const { mutateData } = useApi();
  const { organizationId, sport } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId, seasonId, fixtureId }) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/task`,
        method: "DELETE",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fixtures"] });
      notification.success({
        placement: "bottomRight",
        message: "Delete fixture task successfully",
      });
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Delete fixture task has failed",
        description: error?.message,
      });
    },
  });
};