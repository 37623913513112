import { TeamPageInfo } from "../../../entities/teams/ui"
import { useTeamQuery } from "../../../entities/teams/api"
import { Skeleton } from "antd"
import { useClubsQuery } from "../../../entities/club"
import { useVenuesQuery } from "../../../entities/venues"

export const Team = () => {
  const { isLoading } = useTeamQuery();
  const { isLoading: isLoadingClubs } = useClubsQuery()
  const { isLoading: isLoadingVenues } = useVenuesQuery()


  if (isLoading || isLoadingClubs || isLoadingVenues) return <Skeleton />

  return <TeamPageInfo />
}