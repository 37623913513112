import { Typography } from "antd";
import { useFixturesStore } from "../model";
import { useEffect, useState } from "react";
import moment from "moment";
import { useIntl } from "react-intl";

export const StaffRosterFixtureTitle = ({ type }) => {
  const [title, setTitle] = useState("");
  const { formatMessage } = useIntl();
  const fixture = useFixturesStore(store => store?.fixture);

  useEffect(() => {
    if (fixture) {
      const teams = `${fixture?.homeEntity?.nameLocal} v ${fixture?.awayEntity?.nameLocal}`;
      const date = fixture?.startDateTimeLocal ? moment(fixture?.startDateTimeLocal).format("DD/MM/YYYY") : "NO DATE";
      const time = fixture?.startDateTimeLocal ? moment(fixture?.startDateTimeLocal).format("HH:mm") : "NO TIME";
      const venue = fixture?.venue ? fixture?.venue?.nameLocal : "NO VENUE";

      setTitle(`${teams} - ${date} - ${time} - ${venue}`);
    }
  }, [fixture]);

  if (!fixture) return "";

  if (!type) return <Typography.Title level={3} style={{ margin: 0 }}>{title}</Typography.Title>;

  return <Typography.Title level={3} style={{ margin: 0 }}>
    {type === "staff" ? formatMessage({
      id: "staff",
      defaultMessage: "Staff",
    }) : formatMessage({
      id: "roster",
      defaultMessage: "Roster",
    })} - {title}</Typography.Title>;
};