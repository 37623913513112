import { useTaskStore } from "../model"
import { List, Typography } from "antd"
import { useEffect, useState } from "react"
import moment from "moment"
import { shallow } from "zustand/shallow"

export const AdminTaskHistory = () => {
  const task = useTaskStore(store => store?.task, shallow)
  const [data, setData] = useState([])

  useEffect(() => {
    if (task) {
      setData(task?.historyRecords?.reverse())
    }
  }, [task])

  return <List
    header={<Typography.Text className={"ml-15"} strong>Change history</Typography.Text>}
    size={"small"}
    rowKey={"added"}
    dataSource={data}
    style={{
      maxHeight: "400px",
      overflowY: "auto",
      backgroundColor: "#fff",
      borderRadius: "8px"
    }}
    renderItem={(item) => {
      return <List.Item>
        <List.Item.Meta
          title={`[${moment.utc(item?.added).local().format("DD/MM/YYYY HH:mm:ss")}] ${item?.userEmail}`}
          description={item?.text}
        />
      </List.Item>
    }}
  />
}