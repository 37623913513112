import {useParams} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useIntl} from "react-intl";
import {notification} from "antd";
import {useApi} from "~shared/api";

export const useUpdateFixtureEntityUniform = () => {
  const {sport, organizationId, seasonId, fixtureId, teamId} = useParams();
  const {mutateData} = useApi();
  const queryClient = useQueryClient();
  const {formatMessage} = useIntl();

  return useMutation({
    mutationFn: ({ uniformId }: { uniformId: string | null }) => {
      const url = `${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/uniforms`;
      return mutateData({
        url: `/${url}`,
        method: "PATCH",
        data: {
          uniformId
        }
      });
    },
    onSuccess: async () => {
      notification.success({
        message: formatMessage({id: 'fixtureEntity.uniform.select.success' }),
        placement: 'bottomRight',
      });

      await queryClient.invalidateQueries({
        queryKey: ["fixtures", teamId, seasonId, fixtureId]
      });
    },
    onError: async(error) => {
      console.error("Unable to update fixture entity uniform", error);
      notification.success({
        message: formatMessage({id: 'fixtureEntity.uniform.select.error' }),
        placement: 'bottomRight',
      });
    }
  });
}
