import { useApi } from "../../../shared/api"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { notification } from "antd"
import { useVenuesStore } from "../model"

export const useVenuesQuery = () => {
  const { fetchData } = useApi();
  const { sport, organizationId } = useParams();
  const setVenues = useVenuesStore(store => store?.set);

  return useQuery({
    queryKey: ["venues", organizationId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/venues`),
    onSuccess: data => {
      setVenues(data?.data || [])
    },
    onError: () => {
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch venues"
      })
    }
  });
}