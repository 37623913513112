import {useApi} from "../../../shared/api"
import {useParams} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {notification} from "antd"
import {usePersonsStore} from "../model"
import {useIntl} from "react-intl"
import {useMemo} from "react";
import {isEmpty} from "lodash";

export const useFetchPersons = ({searchText, offsetDc, offsetDb, limit}) => {
  const {fetchData} = useApi();
  const {organizationId, sport, teamId} = useParams();
  const {updatePersons} = usePersonsStore();

  const url = useMemo(() => {
    const params = new URLSearchParams();
    params.append("offsetDc", offsetDc);
    params.append("offsetDb", offsetDb);
    params.append("limit", limit);
    if (!isEmpty(searchText)) {
      params.append("nameFullLocalContains", searchText);
    }
    return `/${sport}/o/${organizationId}/entities/${teamId}/persons?${params.toString()}`
  }, [sport, organizationId, teamId, searchText, offsetDc, offsetDb, limit]);

  return useQuery({
    queryKey: ["persons", organizationId, teamId, searchText, offsetDc, offsetDb, limit],
    queryFn: () => fetchData(url),
    onSuccess: data => updatePersons(data?.data, data?.data?.length === limit),
    onError: err => {
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch persons",
        description: err?.message
      })
    }
  });
}

export const useCreatePerson = () => {
  const {mutateData} = useApi()
  const {organizationId, sport} = useParams()
  const {formatMessage} = useIntl()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => {
      return mutateData({
        url: `/persons`,
        method: "POST",
        data: {
          sport: sport,
          organizationId: organizationId,
          ...data
        }
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ["persons", organizationId]})
      notification.success({
        placement: "bottomRight",
        message: formatMessage({
          id: "person.add.success",
          defaultMessage: "Person added successfully"
        })
      })
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: formatMessage({
          id: "person.add.failed",
          defaultMessage: "Add person has failed"
        }),
        description: error?.message
      })
    }
  })
}