import { Button, Layout, Menu, theme, Typography } from "antd"
import { useMemo, useState } from "react"
import { LogoutButton } from "../../../features/auth"
import {useConfigStore} from "../../../shared";

const { useToken } = theme

export const Sidedrawer = () => {
  const [collapsed, setCollapsed] = useState(true)
  const config = useConfigStore(state => state?.config)
  const { token } = useToken()
  const productItems = useMemo(() => [
    {
      key: "competition-admin-menu-item",
      label: <Typography.Text strong>Competition Admin</Typography.Text>,
      icon: <i style={{ color: token.colorPrimary }} className="multi-color-icon-competition"/>,
      link: config?.environments?.apps[config?.env].competitions
    },
    {
      key: "rankings-menu-item",
      label: <Typography.Text strong>Rankings</Typography.Text>,
      icon: <i style={{ color: token.colorPrimary }} className="fa-solid fa-star"/>,
      link: config?.environments?.apps[config?.env].rankings
    },
    {
      key: "team-manger-menu-item",
      label: <Typography.Text strong>Team Manager</Typography.Text>,
      icon: <i style={{ color: "#ff0000" }} className="fa-solid fa-users"/>
    }
  ], [token, config]);
  
  return <Layout.Sider style={{
    overflow: "auto",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
    borderRight: "1px solid #ccc"
  }} collapsedWidth={65} width={250} trigger={null} collapsible collapsed={collapsed} id={"sidedrawer"}>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh"
      }}
    >
      <div>
        <Button type={"text"} size={"large"}
                icon={collapsed ? <i className="fa-solid fa-bars"/> : <i className="fa-solid fa-xmark"/>}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  marginLeft: "13px",
                  marginTop: "7px"
                }}
        />
        <Menu mode="inline"
              style={{
                marginTop: "20px",
                backgroundColor: "#f2f2f2"
              }}
              onClick={(menuItem) => {
                const redirectLink = productItems.find(x => x.key === menuItem.key)?.link;
                if (redirectLink) {
                  window.location.href = redirectLink;
                }
              }}
              items={productItems}>
        </Menu>
      </div>
      <LogoutButton/>
    </div>
  </Layout.Sider>
}
