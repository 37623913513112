import React from 'react';
import ReactDOM from 'react-dom/client';
import { Tracing } from "~shared/Tracing";
import './app/ui/index.css';
import App from './app/App';

async function main() {
  try {
    Tracing.init("TeamManager");
    const container = document.getElementById('root');
    if (!container) {
      throw new Error("Root element not found");
    }
    const root = ReactDOM.createRoot(container);
    root.render(
      // TODO: enable React Strict Mode
      // <React.StrictMode>
        <App />
      // </React.StrictMode>
    );
  } catch (error) {
    console.error("Unable to boot the application", error);
    Tracing.capture(error);
  }
}

main();
