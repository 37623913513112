import { Button } from "antd";
import { ConfirmModal } from "./ConfirmModal";
import { useState } from "react";

export const DeleteButton = ({ type, entity }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return <>
    <Button className={"text-upper"} danger={true} type={"primary"}
            onClick={handleOpen}
            icon={<i className="fa-solid fa-trash-can"></i>}>Delete task</Button>
    <ConfirmModal type={type} entity={entity} open={open} handleClose={handleClose}/>
  </>;
};