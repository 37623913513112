import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useCustomersStore = create(devtools((set) => ({
  customers: null,
  redirectLinkForErrorPages: "/",
  set: data => {
    set({
      customers: data,
    });
  },
  setRedirectLinkForErrorPages: data => {
    set({
      redirectLinkForErrorPages: data,
    });
  },
})), { name: "customers" });