import { useCallback, useRef } from "react";

import { useUpdateSeasonStaffPersonInternal, useUpdateSeasonStaffPersonDC } from "./useSeasonStaffAPI";
import { useUpdateFixtureStaffPersonInternal, useUpdateFixtureStaffPersonDC } from "./useFixtureStaffAPI";

export * from "./useSeasonStaffAPI"
export * from "./useFixtureStaffAPI"
export * from "./useStaffCopyAPI"

export const useUpdateStaffByPerson = ({ type }) => {
    const lastUpdateRef = useRef();
    //roster season
    const {
      isLoading: isLoadingInternalSeasonStaff,
      isSuccess: isSuccessInternalSeasonStaff,
      mutate: updateInternalSeasonStaff
    } = useUpdateSeasonStaffPersonInternal()
    const {
      isLoading: isLoadingDCSeasonStaff,
      isSuccess: isSuccessDCSeasonStaff,
      mutate: updateDCSeasonStaff
    } = useUpdateSeasonStaffPersonDC()
    // roster fixture
    const {
      isLoading: isLoadingInternalFixtureStaff,
      isSuccess: isSuccessInternalFixtureStaff,
      mutate: updateInternalFixtureStaff
    } = useUpdateFixtureStaffPersonInternal()
    const {
      isLoading: isLoadingDCFixtureStaff,
      isSuccess: isSuccessDCFixtureStaff,
      mutate: updateDCFixtureStaff
    } = useUpdateFixtureStaffPersonDC()
  
    const mutate = useCallback(async (dataCopy, person) => {
      lastUpdateRef.current = person;
      if (person?.personId) {
        if (type === "seasonStaff") {
          updateInternalSeasonStaff({
            ...dataCopy, personId: person?.personId
          })
        }
        if (type === "fixtureStaff") {
          updateInternalFixtureStaff({
            ...dataCopy, personId: person?.personId
          })
        }
      }
      if (person?.dcPersonId) {
        if (type === "seasonStaff") {
          updateDCSeasonStaff({
            ...dataCopy, dcPersonId: person?.dcPersonId
          })
        }
        if (type === "fixtureStaff") {
          updateDCFixtureStaff({
            ...dataCopy, dcPersonId: person?.dcPersonId
          })
        }
      }
    }, [type, updateInternalSeasonStaff, updateInternalFixtureStaff, updateDCSeasonStaff, updateDCFixtureStaff]);
  
    const isLoading = isLoadingInternalSeasonStaff || isLoadingDCSeasonStaff || isLoadingInternalFixtureStaff || isLoadingDCFixtureStaff;
  
    return {
      updated: isLoading ? lastUpdateRef.current : undefined,
      mutate,
      isLoading: isLoading,
      isSuccess: isSuccessInternalSeasonStaff && isSuccessInternalFixtureStaff && isSuccessDCSeasonStaff && isSuccessDCFixtureStaff
    };
  };