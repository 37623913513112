import { useFetchSeason } from "../../../entities/season";
import { useSeasonsStore } from "../../../entities/season";
import { BackToButton } from "../../../shared";
import { Divider, Row, Typography } from "antd";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { FixtureList } from "../../../entities/fixtures";

export const Fixtures = () => {
  const { customerId, organizationId, sport, teamId } = useParams();
  const { formatMessage } = useIntl();
  useFetchSeason();
  const activeSeason = useSeasonsStore(state => state?.activeSeason);

  return <>
    <Row className={"mt-15"} justify={"space-between"}>
      <Typography.Title level={3} style={{ margin: 0 }}>
        {formatMessage({
          id: "season",
          defaultMessage: "Season",
        })} - {activeSeason?.nameLocal}</Typography.Title>
      <BackToButton to={`/${customerId}/${organizationId}/${sport}/teams/${teamId}`} btnText={formatMessage({
        id: "back.to.team",
        defaultMessage: "Back to team",
      })}/>
    </Row>
    <Divider/>
    <Row className={"mb-10"}>
      <Typography.Text strong>{formatMessage({
        id: "fixtures",
        defaultMessage: "Fixtures",
      })}</Typography.Text>
    </Row>
    <FixtureList/>
  </>;
};