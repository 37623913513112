import {useParams} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useIntl} from "react-intl";
import {notification} from "antd";
import {useApi} from "~shared/api";
import {IUniformUpsertModel} from "../models/IUniformUpsertModel";
import {useUpsertUniformItem} from "./useUpsertUniformItem";

export const useUpsertUniform = () => {
  const {sport, organizationId, teamId} = useParams();
  const {mutateData} = useApi();
  const queryClient = useQueryClient();
  const {mutateAsync: upsertUniformItem} = useUpsertUniformItem();
  const {formatMessage} = useIntl();

  return useMutation({
    mutationFn: (upsertModel: IUniformUpsertModel) => {
      let url = `${sport}/o/${organizationId}/entities/${teamId}/uniforms`;
      if (upsertModel.uniformId) {
        url = `${url}/${upsertModel.uniformId}`;
      }
      return mutateData({
        url: `/${url}`,
        method: upsertModel.uniformId ? "PUT" : "POST",
        data: upsertModel.upsertData
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: async (response: any, upsertModel: IUniformUpsertModel) => {

      const uniformId = response?.data?.[0]?.uniformId;
      if (uniformId) {

        for (let i = 0; i < upsertModel.items?.length; i++) {
          const {uniformItemId, ...uniformItemUpsertData} = upsertModel.items[i];
          await upsertUniformItem({
            uniformId,
            uniformItemId,
            upsertData: uniformItemUpsertData
          })
        }

        await queryClient.invalidateQueries({
          queryKey: ["uniformItems", sport, organizationId, uniformId]
        });

        await queryClient.invalidateQueries({
          queryKey: ["uniforms", sport, organizationId, teamId]
        });

        notification.success({
          message: formatMessage({id: upsertModel.uniformId ? 'uniform.update.success' : 'uniform.add.success'}),
          placement: 'bottomRight',
        });
      }
    }
  });
}
