import { Button } from "antd";
import { useIntl } from "react-intl";
import { EditModal } from "./EditModal";
import { useEffect, useState } from "react";
import { useSeasonsStore } from "../../../entities/season";
import { useFixturesStore } from "../../../entities/fixtures";

export const EditButton = (props) => {
  const [open, setModal] = useState(false);
  const { type } = props;
  const { formatMessage } = useIntl();
  const [editable, setEditable] = useState(false);
  const activeSeason = useSeasonsStore(state => state?.activeSeason);
  const activeFixture = useFixturesStore(state => state?.fixture);

  useEffect(() => {
    switch (type) {
      case "seasonStaff":
      case "seasonRoster":
        setEditable(activeSeason?.areRosterAndStaffEditable);
        break;
      case "fixtureRoster":
      case "fixtureStaff":
        setEditable(activeFixture?.areRosterAndStaffEditable);
    }
  }, [type, activeFixture, activeSeason]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return <>
    <Button disabled={!editable} title={formatMessage({
      id: "edit", defaultMessage: "Edit",
    })} type={"text"}
            icon={<i className="fa-solid fa-pen-to-square"/>}
            onClick={openModal}
    />
    {open && <EditModal {...props} closeModal={closeModal}/>}
  </>;
};