import { useApi } from "../../../shared/api";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import { useFixturesStore } from "../model";

export const useFetchFixtures = () => {
  const { fetchData } = useApi();
  const { organizationId, sport, teamId, seasonId } = useParams();
  const setFixtures = useFixturesStore(state => state.setFixtures);

  return useQuery({
    queryKey: ["fixtures", teamId, seasonId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures?include=fixtures,venues,entities,season_stages,season_pools,season_series&sortBy=-startTimeUTC&limit=1000`),
    onSuccess: data => {
      setFixtures(data || null);
    },
    onError: err => {
      console.error("onError", err);
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch fixtures",
        description: err?.message,
      });
    },
  });
};

export const useFetchFixture = () => {
  const { fetchData } = useApi();
  const { organizationId, sport, teamId, seasonId, fixtureId } = useParams();
  const setFixture = useFixturesStore(state => state?.setFixture);

  return useQuery({
    queryKey: ["fixtures", teamId, seasonId, fixtureId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}?include=fixtures,venues,entities,season_stages,season_pools,season_series&sortBy=-startTimeUTC`),
    enabled: Boolean(fixtureId && sport && organizationId && teamId && seasonId),
    onSuccess: data => {
      setFixture(data?.data?.[0] || null);
    },
    onError: err => {
      console.error("onError", err);
      // TODO: use-query and API as it goes must not care about UI. It is a server logic, don't mess it with UI. Move showing error outside of this hook.
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch fixture",
        description: err?.message,
      });
    },
  });
};