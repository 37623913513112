import {useAddPersonsToFixtureStaff, useAddPersonsToSeasonStaff} from "../../../entities/staff/api"
import {Button} from "antd"

export const AddStaffButton = ({type, person}) => {
  const {mutate: addSeasonStaff, isLoading: isLoadingAddSeasonStaff} = useAddPersonsToSeasonStaff()
  const {mutate: addFixtureStaff, isLoading: isLoadingAddFixtureStaff} = useAddPersonsToFixtureStaff()

  const onClickHandler = async () => {

    const personData = {
      [person?.dcPersonId ? "dcPersonId" : "personId"]: person?.dcPersonId || person?.personId
    }
    if (person?.role) {
      personData.role = person.role
    }

    const data = {
      persons: [
        personData
      ]
    }

    try {
      if (type === "seasonStaff") {
        addSeasonStaff(data)
      } else if (type === "fixtureStaff") {
        addFixtureStaff(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return <Button onClick={onClickHandler}
                 loading={isLoadingAddSeasonStaff || isLoadingAddFixtureStaff}
                 icon={<i className="fa-solid fa-plus"/>}/>

}