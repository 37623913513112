import { BackToButton, BaseEntityCard } from "../../../shared";
import { Col, Row, Space, Spin, Typography } from "antd";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useOrganizationsStore } from "../../../entities/organization/model";
import { useEffect, useState } from "react";

export const RoleSelection = () => {
  const { customerId, organizationId, sport } = useParams();
  const { formatMessage } = useIntl();
  const organizations = useOrganizationsStore(store => store?.organizations);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setRoles(organizations?.filter(org => org?.organizationId === organizationId)?.[0]?.availableUserRoles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  return <>
    <Row justify={"space-between"} className={"mt-10"}>
      <Space direction={"vertical"} size={0}>
        <Typography.Title className={"mt-0"} level={2}>{formatMessage({
          id: "select.role",
          defaultMessage: "Select Role",
        })}</Typography.Title>
        <Typography.Text>{formatMessage({
          id: "available.roles",
          defaultMessage: "Available roles",
        })}</Typography.Text>
      </Space>
      <BackToButton to={`/${customerId}`} btnText={"Back to organizations"}/>
    </Row>
    <Row justify={"center"}>
      <Col xs={24} sm={10} md={12} lg={10} xl={8} xxl={5}>
        <div style={{
          boxShadow: "rgba(0, 0, 0, 0.12) 0px 12px 40px",
          padding: "10px",
          marginTop: "10px",
        }}>
          {!organizations
            ? <Row justify={"center"}><Spin/></Row>
            : <Space direction={"vertical"} style={{ width: "100%" }}>
              {roles?.includes("competition-admin") &&
                <BaseEntityCard to={`/${customerId}/${organizationId}/${sport}/admin`} name={"Admin"}/>}
              {roles?.includes("team-manager") &&
                <BaseEntityCard to={`/${customerId}/${organizationId}/${sport}/teams`} name={"Team Manager"}/>}
            </Space>}
        </div>
      </Col>
    </Row>
  </>;
};