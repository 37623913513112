import { Link } from "react-router-dom";
import { Space } from "antd";
import { TeamLogoBadge } from "./TeamLogoBadge";

import("./BaseEntityCard.css");

export const BaseEntityCard = ({ to, name, desc, img = null, showImg = false }) => {
  return <Link className={"base-entity-card"}
               to={to}>
    <div className={"p-15 wrap"}>
      <Space>
        {showImg && <TeamLogoBadge img={img}/>}
        <div className={"title text-upper"}>{name}</div>
      </Space>
      {desc && <div className={"desc text-upper mt-5"}>{desc}</div>}
    </div>
  </Link>;
};