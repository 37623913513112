import { create } from "zustand"
import { devtools } from "zustand/middleware"

export const useOrganizationsStore = create(devtools((set) => ({
  organizations: [],
  set: data => {
    set({
      organizations: data
    })
  }
})), { name: "organizations" });