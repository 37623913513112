import { Modal, Typography } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { RosterForm } from "./RosterForm"
import { useUpdate } from "./useUpdate"
import { StaffForm } from "./StaffForm"

const PersonForm = (props) => {
  const { type } = props

  if (["seasonRoster", "fixtureRoster"].includes(type)) return <RosterForm {...props} />
  if (["seasonStaff", "fixtureStaff"].includes(type)) return <StaffForm {...props} />
}

export const EditModal = ({ type, person, closeModal }) => {
  const { formatMessage } = useIntl()
  const [personFormRef, setPersonFormRef] = useState(null)
  const { update, isLoading, isSuccess } = useUpdate({ type, person })

  const setFormRef = (form) => {
    setPersonFormRef(form)
  }

  const modalTitle = useMemo(() => {
    switch (type) {
      case "seasonStaff":
      case "fixtureStaff":
        return formatMessage({
          id: "staff.edit",
          defaultMessage: "Edit Staff"
        })
      case "seasonRoster":
      case "fixtureRoster":
        return formatMessage({
          id: "player.edit",
          defaultMessage: "Edit Person"
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const onOk = useCallback(() => {
    update({
      type,
      person,
      form: personFormRef
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, personFormRef])

  useEffect(() => {
    if (isSuccess) {
      closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return <Modal open={true}
                title={modalTitle}
                onCancel={closeModal}
                onOk={onOk}
                okText={formatMessage({
                  id: "update",
                  defaultMessage: "Update"
                })}
                okButtonProps={{
                  loading: isLoading
                }}
  >
    <Typography.Title level={3}>{person?.nameFullLocal}</Typography.Title>
    <PersonForm type={type} person={person} setFormRef={setFormRef}/>
  </Modal>
}