import { Button } from "antd"
import { useState } from "react"
import { PublishModal } from "./PublishModal"

export const ButtonPublish = ({ type }) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return <>
    <Button
      icon={<i className="fa-solid fa-paper-plane"></i>}
      onClick={openModal}
      type={"primary"}
      className={"text-upper"}
    >Publish</Button>
    <PublishModal open={open} closeModal={closeModal} type={type}/>
  </>
}