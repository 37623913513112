import {Input} from "antd"
import {useState} from "react";
import {useDebouncedCallback} from "use-debounce";

export const SearchInput = ({doSearch, initial = "", placeholder = "", className = ""}) => {
  const [searchTerm, setSearchTerm] = useState(initial);
  const debouncedDoSearch = useDebouncedCallback(doSearch, 300);

  const onChangeHandler = (e) => {
    const newValue = e?.target?.value;
    setSearchTerm(newValue);
    debouncedDoSearch(newValue);
  };

  return <div className={className}>
    <Input
      onChange={onChangeHandler}
      value={searchTerm}
      style={{
        borderRadius: "18px",
        background: "#d1d1d1"
      }}
      size={"large"}
      className={"search-input pl-25 pr-25"}
      prefix={<i className="fas fa-search mr-10"/>}
      placeholder={placeholder || "Search..."}
    />
  </div>
}