import {Select, Table} from "antd";
import {shallow} from "zustand/shallow";
import {useIntl} from "react-intl";
import {useCallback} from "react";
import {useParams} from "react-router-dom";
import {useStaffRolesStore} from "../../../shared";
import {AddStaffButton} from "./AddStaffButton";
import {PersonLabel} from "../../../shared/ui/PersonLabel";
import {
  getNationalityColumn,
  getPersonStatusColumn,
  getDobColumn,
} from "~shared/common";

export const StaffList = ({
                            type,
                            persons,
                            isLoading,
                            pagination,
                            alreadyAddedPersons,
                            onTableChanged
                          }) => {
  const {sport} = useParams();
  const staffRoles = useStaffRolesStore(store => store?.[sport] || store?.basketball, shallow);
  const {formatMessage} = useIntl();
  
  const getAlreadyAddedPerson = useCallback(
    (person) => alreadyAddedPersons.filter(
      x => x.dcPersonId === person.dcPersonId && x.personId === person.personId)?.[0] ?? null,
    [alreadyAddedPersons]);
  const isPersonAlreadyAdded = useCallback(
    (person) => getAlreadyAddedPerson(person) !== null, [getAlreadyAddedPerson]);

  const tableColumns = [
    {
      title: formatMessage({
        id: "name.full",
        defaultMessage: "Full Name"
      }),
      dataIndex: "nameFullLocal",
      render: (value, record) =>
        <PersonLabel person={record} alreadyAdded={isPersonAlreadyAdded(record)} newlyCreated={record?.personId}/>
    },
    getDobColumn(formatMessage),
    getNationalityColumn(formatMessage),
    {
      title: formatMessage({id: "gender"}),
      dataIndex: "gender",
      width: 75,
      render: (gender) => formatMessage({
        id: "gender." + gender,
        defaultMessage: gender,
      }),
      align: "center",
    },
    getPersonStatusColumn(formatMessage),
    {
      title: "Role",
      align: "center",
      dataIndex: "role",
      width: 250,
      render: (role, record) => !isPersonAlreadyAdded(record)
        ? <Select
          onChange={(value) => record.role = value}
          placeholder={"Select role..."}
          style={{width: "150px"}}
          showSearch
          defaultValue={role}
          defaultActiveFirstOption={true}
          options={staffRoles?.map(role => {
            return {
              label: formatMessage({
                id: `roles.${role}`,
                defaultMessage: role,
              }),
              value: role,
            };
          })}/>
        : getAlreadyAddedPerson(record)?.role
          ? formatMessage({
            id: `roles.${getAlreadyAddedPerson(record).role}`,
            defaultMessage: role
          })
          : ""
    },
    {
      render: (value, record) => !isPersonAlreadyAdded(record) && <AddStaffButton type={type} person={record}/>,
      width: 50
    },
  ];

  return <Table
    rowKey={(record) => record?.dcPersonId ? "dc:" + record?.dcPersonId.toString() : "db: " + record?.personId?.toString()}
    size={"small"}
    loading={isLoading}
    onChange={onTableChanged}
    pagination={pagination}
    columns={tableColumns}
    dataSource={persons}
  />;
};