import React, { useCallback, useEffect, useState } from "react";
import { Button, Spin } from "antd";
import ImageUploader from "./ImageUploader";
import { useUploadTeamImage, useResetTeamImage } from "../../../entities/teams/api";

const UploadForm = ({ imageType, image }) => {
  const [base64file, setBase64File] = useState(null);
  const [file, setFile] = useState();
  const { isLoading, mutate } = useUploadTeamImage();
  const { isLoading: isLoadingReset, mutate: resetTeamImage, isSuccess: isSuccessReset } = useResetTeamImage();

  useEffect(() => {
    if (isSuccessReset) {
      setBase64File(null);
    }
  }, [isSuccessReset]);

  useEffect(() => {
    if (!file && image) {
      setBase64File(image?.url + "?size=400");
    }
  }, [image, file]);

  const handleClear = () => {
    resetTeamImage({
      imageId: image?.imageId,
    });
  };

  const handleDrop = useCallback(
    (files) => {
      if (files && files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = function () {
          setBase64File(reader.result);
          setFile(files[0]);
        };
        reader.onerror = function (error) {
          console.error("Error: ", error);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setBase64File, setFile, imageType],
  );

  const handleLoad = useCallback(async () => {
    if (file) {
      try {
        await mutate({ data: file, imageType });
        setFile(null);
      } catch (e) {
        console.error(e);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      <div className="upload" style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#ffffff",
        padding: "10px",
        flexDirection: "column",
        height: "max-content",
      }}>
        <div style={{
          width: "290px",
          padding: "5px",
          minHeight: "300px",
        }}>
          {(isLoading || isLoadingReset) ? (
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "inherit",
            }}>
              <Spin size={"large"}/>
            </div>
          ) : (
            <div style={{
              top: 0,
              width: "100%",
              height: "100%",
            }}>
              <div style={{
                position: "relative",
                width: "100%",
                backgroundColor: "#F3F3F3",
                height: "290px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
                {base64file || image?.url ? (
                  <img style={{
                    height: "fit-content",
                    width: "100%",
                    margin: 0,
                  }} alt={imageType} src={base64file || `${image.url}?size=400`}/>
                ) : (
                  <svg style={{
                    height: "100%",
                  }}>
                    <line style={{
                      stroke: "#707070",
                      strokeWidth: 1,
                    }} x1="0" y1="100%" x2="100%" y2="0"></line>
                    <line style={{
                      stroke: "#707070",
                      strokeWidth: 1,
                    }} x1="0" y1="0" x2="100%" y2="100%"></line>
                  </svg>
                )}
              </div>
              <ImageUploader
                handleDrop={handleDrop}
                data={{}}
                type={"LOGO"}
                element={"entityId"}
                newDesign
              />
            </div>
          )}
        </div>
        <div style={{
          marginTop: "5px",
          display: "flex",
          gap: "5px",
          justifyContent: "center",
        }}>
          <Button disabled={!file} loading={isLoading} className={"text-upper"} type={"primary"}
                  onClick={handleLoad}>Upload</Button>
          <Button loading={isLoadingReset} danger={true} disabled={!image} className={"text-upper"}
                  onClick={handleClear}>Clear</Button>
        </div>
      </div>
    </>
  );
};

export default UploadForm;
