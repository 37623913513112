/* eslint-disable @typescript-eslint/no-explicit-any */
import {Button, Modal, Row, Space} from "antd";
import {useState} from "react";
import {cloneDeep} from "lodash";
import {useParams} from "react-router-dom";
import {CreatePersonForm, useCreatePerson} from "../../../entities/person";
import {SearchInput} from "../../../shared";
import {PersonListForSeasonRosterOrStaff} from "./PersonListForSeasonRosterOrStaff";
import {PersonListForFixtureRosterOrStaff} from "./PersonListForFixtureRosterOrStaff";

type Props = {
  open: boolean;
  closeModal: () => void;
  type: string;
};

export const AddModal = ({ open, closeModal, type }: Props) => {
  const {sport, organizationId, teamId} = useParams();
  const [createPersonForm, setCreatePersonForm] = useState<any>(null);
  const [mode, setMode] = useState("add");
  const {mutateAsync: addPersonRequest, isLoading: isLoadingAddPerson} = useCreatePerson();

  const [inputSearchText, setInputSearchText] = useState("");
  const searchText = inputSearchText?.length < 2 ? "" : inputSearchText;

  const isFixtureTable = type === "fixtureStaff" || type === "fixtureRoster";
  const isSeasonTable = type === "seasonStaff" || type === "seasonRoster";

  const tableSharedProps = {
    type,
    textToSearch: searchText
  };

  const personsTable = isFixtureTable
    ? <PersonListForFixtureRosterOrStaff {...tableSharedProps} />
    : isSeasonTable
      ? <PersonListForSeasonRosterOrStaff {...tableSharedProps} />
      : null;

  const onClickCreatePersonHandler = async () => {
    try {
      const data = await createPersonForm.validateFields();
      const copyData = cloneDeep(data);
      if (copyData?.dateOfBirth) {
        copyData.dateOfBirth = copyData.dateOfBirth.format("YYYY-MM-DD");
      }
      // add tech fields
      copyData.sport = sport;
      copyData.organizationId = organizationId;
      copyData.dcEntityId = teamId;
      copyData.force = false;

      const res = await addPersonRequest(copyData);
      setInputSearchText(res?.data?.[0]?.nameFullLocal ?? "");
      setMode("add");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      open={open}
      footer={false}
      onCancel={closeModal}
      destroyOnClose
      width="70%"
    >
      <Row justify="space-between" align="middle" className="mt-25 pb-15">
        {mode === "add" ? (
          <>
            <div></div>
            <Space>
              <SearchInput initial={inputSearchText} doSearch={(value: any) => setInputSearchText(value)} />
              {(type === "seasonStaff" || type === "seasonRoster") &&
                <Button
                  icon={<i className="fa-solid fa-plus" />}
                  type="primary"
                  onClick={() => setMode("create")}
                  className="text-upper"
                >
                  New Person
                </Button>
              }
            </Space>
          </>
        ) : (
          <Button
            className="text-upper"
            onClick={() => setMode("add")}
            icon={<i className={"fa-solid fa-angle-left"}/>}
          >
            Back
          </Button>
        )}
      </Row>
      {mode === "add"
        ? personsTable
        : (
          <>
            <CreatePersonForm setFormRef={(form: any) => setCreatePersonForm(form)}/>
            <Row justify="end">
              <Button
                loading={isLoadingAddPerson}
                onClick={onClickCreatePersonHandler}
                type="primary"
                className="text-upper"
              >
                Create person
              </Button>
            </Row>
          </>
        )
      }
    </Modal>
  );
};