import { useCallback, useState } from "react";
import { Divider, Row, Skeleton, Space, Switch, Typography } from "antd";
import { BackToButton, SearchInput, TaskStatusTag } from "../../../shared";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useFetchSeason, useSeasonsStore } from "../../../entities/season";
import { StaffList } from "../../../entities/staff";
import { SubmitSeasonStaff } from "../../../features/SubmitSeasonStaff";
import { AddPerson } from "../../../features/AddPerson";
import { CopyPersons } from "../../../features/CopyPersons";
import { useUpdateStaffByPerson } from "../../../entities/staff/api";

export const SeasonStaff = () => {
  const { customerId, organizationId, sport, teamId } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading } = useFetchSeason();
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const activeSeason = useSeasonsStore(state => state?.activeSeason);

  const {
    updated: updatedPerson,
    isLoading: isUpdating,
    mutate: updateStaff
  } = useUpdateStaffByPerson({ type: "seasonStaff" })

  const canEdit = useCallback((column, record) => {
    const isTarget = record === updatedPerson;
    return !(isTarget && isUpdating);
  }, [isUpdating, updatedPerson]);
  
  const onColumnChanged = useCallback(async ({ column, value, record }) => {
    const dataCopy = {
      [column]: value,
    }
    await updateStaff(dataCopy, record)
  }, [updateStaff]);

  const onIsEditModeChange = useCallback((checked) => {
    setIsEditMode(checked);
  }, []);

  return <>
    <Row className={"mt-15"} justify={"space-between"}>
      {isLoading ? <Skeleton.Input/> : <Space align={"center"}>
        <Typography.Title level={3} style={{ margin: 0 }}>
          {formatMessage({
            id: "staff",
            defaultMessage: "Staff",
          })} - {activeSeason?.nameLocal}</Typography.Title>
        {activeSeason?.taskStatus && <TaskStatusTag status={activeSeason?.taskStatus}/>}
      </Space>}
      <BackToButton to={`/${customerId}/${organizationId}/${sport}/teams/${teamId}`} btnText={formatMessage({
        id: "back.to.team",
        defaultMessage: "Back to team",
      })}/>
    </Row>
    <Divider/>
    <Row className={"mb-10"} justify={"space-between"} align={"middle"}>
      <Typography.Text strong>Season Staff</Typography.Text>
      <Space>
        <span>{formatMessage({ id: "edit.mode", defaultMessage: "Edit mode" })}</span>
        <Switch checked={isEditMode} disabled={!activeSeason?.areRosterAndStaffEditable || false} onChange={onIsEditModeChange} />
        <SearchInput doSearch={setSearchText}/>
        <CopyPersons type={"seasonStaff"}/>
        <AddPerson type={"seasonStaff"}/>
        <SubmitSeasonStaff type={"seasonStaff"}/>
      </Space>
    </Row>
    <StaffList searchText={searchText} type={"seasonStaff"} canEdit={canEdit} isEditMode={isEditMode} onColumnChanged={onColumnChanged} />
  </>;
};