import { Col, Row, Space, Typography } from "antd";
import { useIntl } from "react-intl";
import { BackToButton } from "../../../shared";
import { OrganizationsTable } from "./OrganizationsTable";

export const OrganizationSelection = () => {
  const { formatMessage } = useIntl();

  return <>
    <Row justify={"space-between"} className={"mt-10"}>
      <Space direction={"vertical"} size={0}>
        <Typography.Title className={"mt-0"} level={2}>{formatMessage({
          id: "select.organization",
          defaultMessage: "Select Organization",
        })}</Typography.Title>
        <Typography.Text>{formatMessage({
          id: "available.organizations",
          defaultMessage: "Available organizations",
        })}</Typography.Text>
      </Space>
      <BackToButton to={"/"} btnText={"Back to customers"}/>
    </Row>
    <Row justify={"center"}>
      <Col xs={24} sm={10} md={12} lg={10} xl={8} xxl={5}>
        <OrganizationsTable/>
      </Col>
    </Row>
  </>;
};