import { Space } from "antd"
import { ButtonReview } from "../../../features/ReviewSeason"
import { ButtonReject } from "../../../features/RejectSeason"
import { ButtonApprove } from "../../../features/TaskApprove"
import { ButtonPublish } from "../../../features/TaskPublish"
import { SubmitSeasonStaff } from "../../../features/SubmitSeasonStaff"
import { useRole } from "~shared/hooks"

export const TaskActions = ({ type, status }) => {
  const { isAdmin } = useRole();

  if (isAdmin) {
    if (status === "SUBMITTED") {
      return <Space>
        <ButtonReview type={type}/>
      </Space>
    }
    if (status === "REVIEW") {
      return <Space>
        <ButtonApprove type={type}/>
        <ButtonReject type={type}/>
      </Space>
    }
    if (status === "APPROVED") {
      return <Space>
        <ButtonPublish type={type}/>
      </Space>
    }
  } else {
    if (status === "DRAFT") {
      return <Space>
        <SubmitSeasonStaff type={type}/>
      </Space>
    }
  }

  return null
}