export const environments = {
  api: {
    local: "https://api.team-manager.dev.connect-nonprod.sportradar.dev",
    dev: "https://api.team-manager.dev.connect-nonprod.sportradar.dev",
    stg: "https://api.team-manager.stg.connect-nonprod.sportradar.dev",
    prod: "https://api.team-manager.connect.sportradar.com"
  },
  basedomain: {
    local: "localhost",
    dev: "https://team-manager.dev.connect-nonprod.sportradar.dev",
    stg: "https://team-manager.stg.connect-nonprod.sportradar.dev",
    prod: "https://team-manager.connect.sportradar.com"
  },
  apps: {
    local: {
      competitions: "https://competitions.dev.connect-nonprod.sportradar.dev",
      rankings: "https://rankings.dev.connect-nonprod.sportradar.dev"
    },
    dev: {
      competitions: "https://competitions.dev.connect-nonprod.sportradar.dev",
      rankings: "https://rankings.dev.connect-nonprod.sportradar.dev"
    },
    stg: {
      competitions: "https://competitions.stg.connect-nonprod.sportradar.dev",
      rankings: "https://rankings.stg.connect-nonprod.sportradar.dev/"
    },
    prod: {
      competitions: "https://competitions.connect.sportradar.com",
      rankings: "https://rankings.connect.sportradar.com"
    }
  }
}

const getEnvironment = (host) => {
  let domainEnv = "dev"; // if not specified
  // Detect environment from current host
  for (let key in environments.basedomain) {
    if (environments.basedomain[key]?.includes(host)) {
      domainEnv = key;
      break;
    }
  }
  const nodeEnv = import.meta.env.NODE_ENV;
  const searchParams = new URLSearchParams(window.location.search);
  const queryEnv = searchParams.get("env");
  const buildEnv = import.meta.env.ENVIRONMENT;
  // Env inference
  let decodedEnv = domainEnv; // infer from domain (keep backwards compatibility)
  decodedEnv = buildEnv || decodedEnv; // infer from build
  decodedEnv = queryEnv || decodedEnv; // infer from query string
  // eslint-disable-next-line no-console
  console.debug("Current environment is", decodedEnv, {
    nodeEnv,
    domainEnv,
    buildEnv,
    queryEnv,
  });
  return decodedEnv;
}

export const ENVIRONMENT = getEnvironment(window.location.hostname);