import { Header } from "../../../widgets/header";
import { Sidedrawer } from "../../../widgets/sidedrawer";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { Footer } from "../../../widgets/footer/ui/Footer";
import { BreadCrumbs } from "../../../features/BreadCrumbs/ui";
import { useFetchOrganization } from "../../../entities/organization/api";

export const Root = () => {
  useFetchOrganization();

  return <Layout>
    <Sidedrawer/>
    <Layout style={{
      marginLeft: 65,
    }}>
      <Header/>
      <Layout.Content style={{ padding: "20px 25px", paddingBottom: "100px" }}>
        <BreadCrumbs/>
        <Outlet/>
      </Layout.Content>
      <Footer/>
    </Layout>
  </Layout>;
};