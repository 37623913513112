import { useApi } from "../../../shared/api"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { useClubsStore } from "../model"
import { notification } from "antd"

export const useClubsQuery = () => {
  const { fetchData } = useApi();
  const { sport, organizationId } = useParams();
  const setClubs = useClubsStore(store => store?.set);

  return useQuery({
    queryKey: ["entityGroups", organizationId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entityGroups`),
    onSuccess: data => {
      setClubs(data?.data || [])
    },
    onError: () => {
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch clubs"
      })
    }
  });
}