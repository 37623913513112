import { Modal, Typography } from "antd"
import { usePublishFixtureTask, usePublishSeasonTask } from "../../../entities/task"
import { useEffect, useState } from "react"

export const PublishModal = ({ open, closeModal, type }) => {
  const {
    mutate: publishSeasonTask,
    isLoading: isLoadingSeasonTask,
    isSuccess: isSuccessSeasonTask
  } = usePublishSeasonTask()
  const {
    mutate: publishFixtureTask,
    isLoading: isLoadingFixtureTask,
    isSuccess: isSuccessFixtureTask
  } = usePublishFixtureTask()
  const [modalTitle, setModalTitle] = useState("Publish season Roster & Staff?")

  useEffect(() => {
    if (type === "fixtureRoster" || type === "fixtureStaff") {
      setModalTitle("Publish fixture Roster & Staff?")
    }
  }, [type])

  useEffect(() => {
    if (isSuccessSeasonTask || isSuccessFixtureTask) {
      closeModal()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSeasonTask, isSuccessFixtureTask])

  const handlePublish = () => {
    switch (type) {
      case "fixtureStaff":
      case "fixtureRoster":
        return publishFixtureTask()
      case "seasonRoster":
      case "seasonStaff":
        return publishSeasonTask()
    }
  }

  return <Modal
    title={modalTitle}
    destroyOnClose={true}
    open={open}
    onCancel={closeModal}
    okText={"Confirm"}
    onOk={handlePublish}
    okButtonProps={{
      loading: isLoadingSeasonTask || isLoadingFixtureTask
    }}
  >
    <Typography.Paragraph>Are you sure you are ready to make this roster & staff publicly
      available?</Typography.Paragraph>
  </Modal>
}