type ItemProps = {
  colorPrimary: string;
  colorSecondary?: string;
  colorTertiary?: string;
  lettering?: string;
}
export const UniformItemShortSleeveSvg = ({colorPrimary, colorSecondary}: ItemProps) => (
  <svg id="uniformItemShortSleeveSvg" data-name="uniformItemShortSleeveSvg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="199.999" height="242.026" viewBox="0 0 199.999 242.026">
    <defs>
      <clipPath id="clip-path-short-sleeve">
        <rect id="Rectangle_9648" data-name="Rectangle 9648" width="49.484" height="91.418" fill={colorPrimary}/>
      </clipPath>
      <clipPath id="clip-path-2-short-sleeve">
        <rect id="Rectangle_9649" data-name="Rectangle 9649" width="49.485" height="91.418" fill={colorPrimary}/>
      </clipPath>
      <clipPath id="clip-path-3-short-sleeve">
        <rect id="Rectangle_9650" data-name="Rectangle 9650" width="45.424" height="15.563" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_18808" data-name="Group 18808" transform="translate(0 23.2)" opacity="0.8">
      <g id="Group_18807" data-name="Group 18807" transform="translate(0 0)">
        <g id="Group_18806" data-name="Group 18806" clipPath="url(#clip-path-short-sleeve)">
          <path id="Path_10598" data-name="Path 10598" d="M49.01,27.139h0a1.688,1.688,0,0,0-.8.136L35.856,32.66c-.051.023-.1.048-.153.075C12.319,45.5,10.879,64.356,10.8,65.906a1.562,1.562,0,0,1-.028.226l-9.14,46.594a1.671,1.671,0,0,0,1.118,1.906c19.559,6.384,32.446,3.3,35.81,2.253a1.688,1.688,0,0,0,1.122-1.138C53.339,68.76,51.228,35.671,50.555,28.638a1.666,1.666,0,0,0-1.546-1.5" transform="translate(-1.6 -27.134)" fill={colorSecondary}/>
        </g>
      </g>
    </g>
    <g id="Group_18811" data-name="Group 18811" transform="translate(150.515 23.2)" opacity="0.8">
      <g id="Group_18810" data-name="Group 18810" transform="translate(0 0)">
        <g id="Group_18809" data-name="Group 18809" clipPath="url(#clip-path-2-short-sleeve)">
          <path id="Path_10599" data-name="Path 10599" d="M168.342,27.139h0a1.688,1.688,0,0,1,.8.136L181.5,32.66c.051.023.1.048.153.075,23.384,12.77,24.825,31.621,24.9,33.171a1.562,1.562,0,0,0,.028.226l9.14,46.594a1.671,1.671,0,0,1-1.118,1.906c-19.559,6.384-32.446,3.3-35.81,2.253a1.688,1.688,0,0,1-1.122-1.138C164.013,68.76,166.123,35.671,166.8,28.638a1.666,1.666,0,0,1,1.546-1.5" transform="translate(-166.267 -27.134)" fill={colorSecondary}/>
        </g>
      </g>
    </g>
    <path id="Path_10600" data-name="Path 10600" d="M86.123,7.948c.005.015.012.03.017.046-.005-.017-.01-.033-.015-.049l0,0" transform="translate(-8.864 -2.179)" fill={colorSecondary}/>
    <path id="Path_10601" data-name="Path 10601" d="M137.456,13.885l-5.18-6.966s-.914,15.29-24.052,16.09c-23.139-.8-24.053-16.09-24.053-16.09l-5.18,6.966s-1,12.652,29.222,16.925v0h.022v0c30.224-4.273,29.221-16.925,29.221-16.925" transform="translate(-8.251 -2.085)" fill={colorPrimary}/>
    <path id="Path_10602" data-name="Path 10602" d="M131.935,5.525s-4.28-3.7-23.128-3.926h-.022C89.939,1.828,85.66,5.525,85.66,5.525a12.493,12.493,0,0,0,.425,1.84c.121-.2,5.062-1.575,22.712-1.6,17.651.029,22.593,1.406,22.713,1.6a12.584,12.584,0,0,0,.426-1.84" transform="translate(-8.824 -1.599)" fill={colorPrimary}/>
    <path id="Path_10603" data-name="Path 10603" d="M135.821,7.948c-.005.015-.012.03-.017.046.005-.017.01-.033.015-.049l0,0" transform="translate(-13.134 -2.179)" fill={colorSecondary}/>
    <g id="Group_18814" data-name="Group 18814" transform="translate(77.261 4.162)" opacity="0.3">
      <g id="Group_18813" data-name="Group 18813" transform="translate(0 0)">
        <g id="Group_18812" data-name="Group 18812" clipPath="url(#clip-path-3-short-sleeve)">
          <path id="Path_10604" data-name="Path 10604" d="M108.849,6.18h-.022c-17.64.029-22.581,1.406-22.7,1.6,0,.016.009.032.015.049a21.274,21.274,0,0,0,1.183,2.86c3.318,6.583,9.9,10.27,21.5,11.048v0h.022v0c11.608-.779,18.185-4.466,21.5-11.048a21.4,21.4,0,0,0,1.184-2.86l.014-.049c-.119-.2-5.059-1.574-22.7-1.6" transform="translate(-86.126 -6.18)" fill={colorPrimary}/>
        </g>
      </g>
    </g>
    <path id="Path_10605" data-name="Path 10605" d="M168.571,227.147c-3.494-23.866-2.454-105.673-2.388-112.218a2.111,2.111,0,0,0-.085-.609C152.526,67.937,154.316,34.991,155.026,27.1a2.16,2.16,0,0,1,2-1.956l-21.921-9.557s1.016,12.72-29.453,16.96c-.232.033-.451.045-.679.073-.229-.027-.449-.04-.681-.073-30.468-4.24-29.453-16.96-29.453-16.96L52.919,25.143a2.158,2.158,0,0,1,2,1.956c.71,7.892,2.5,40.838-11.071,87.221a2.02,2.02,0,0,0-.084.609c.065,6.545,1.105,88.353-2.389,112.218a5.552,5.552,0,0,0,3.374,5.9c6.7,2.81,10.36,4.314,10.841,4.511.04.016.075.03.116.044,20.539,7.1,49.267,7.3,49.267,7.3s28.726-.2,49.265-7.3c.041-.014.076-.027.117-.044.481-.2,4.143-1.7,10.839-4.511a5.551,5.551,0,0,0,3.375-5.9" transform="translate(-5.014 -2.878)" fill={colorPrimary}/>
  </svg>
)

export const UniformItemLongSleeveSvg = ({colorPrimary, colorSecondary}: ItemProps) => (
  <svg id="uniformItemLongSleeveSvg" data-name="uniformItemLongSleeveSvg" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink" width="206.038" height="242.026" viewBox="0 0 206.038 242.026">
    <defs>
      <clipPath id="clip-path-long-sleeve">
        <rect id="Rectangle_9650" data-name="Rectangle 9650" width="45.424" height="15.563" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_18807" data-name="Group 18807" transform="translate(3.97 23.2)" opacity="0.8">
      <path id="Path_10598" data-name="Path 10598"
            d="M49.01,27.139h0a1.688,1.688,0,0,0-.8.136L35.856,32.66c-.051.023-.1.048-.153.075C12.319,45.5,10.879,64.356,10.8,65.906a1.562,1.562,0,0,1-.028.226l-9.14,46.594c-.161.822-3.683,113.645-2.882,113.906,12.381,4.041,22.088,4.287,28.7,3.628,3.356-.333,9.873-112.991,11.108-113.374a1.688,1.688,0,0,0,1.122-1.138C53.339,68.76,51.228,35.671,50.555,28.638a1.666,1.666,0,0,0-1.546-1.5"
            transform="translate(-2.601 -27.134)" fill={colorSecondary}/>
    </g>
    <g id="Group_19762" data-name="Group 19762" transform="translate(153.585 23.2)" opacity="0.8">
      <path id="Path_10598-2" data-name="Path 10598"
            d="M3.39,27.139h0a1.688,1.688,0,0,1,.8.136L16.543,32.66c.051.023.1.048.153.075C40.08,45.5,41.52,64.356,41.6,65.906a1.562,1.562,0,0,0,.028.226l9.14,46.594c.161.822,3.683,113.646,2.882,113.906-12.381,4.041-22.088,4.287-28.7,3.628-3.356-.333-9.873-112.992-11.108-113.375a1.688,1.688,0,0,1-1.122-1.138C-.939,68.761,1.171,35.671,1.844,28.638a1.666,1.666,0,0,1,1.546-1.5"
            transform="translate(-1.315 -27.134)" fill={colorSecondary}/>
    </g>
    <path id="Path_10600" data-name="Path 10600"
          d="M86.123,7.948c.005.015.012.03.017.046-.005-.017-.01-.033-.015-.049l0,0"
          transform="translate(-5.895 -2.179)" fill={colorPrimary}/>
    <path id="Path_10601" data-name="Path 10601"
          d="M137.456,13.885l-5.18-6.966s-.914,15.29-24.052,16.09c-23.139-.8-24.053-16.09-24.053-16.09l-5.18,6.966s-1,12.652,29.222,16.925v0h.022v0c30.224-4.273,29.221-16.925,29.221-16.925"
          transform="translate(-5.281 -2.085)" fill={colorPrimary}/>
    <path id="Path_10602" data-name="Path 10602"
          d="M131.935,5.525s-4.28-3.7-23.128-3.926h-.022C89.939,1.828,85.66,5.525,85.66,5.525a12.493,12.493,0,0,0,.425,1.84c.121-.2,5.062-1.575,22.712-1.6,17.651.029,22.593,1.406,22.713,1.6a12.584,12.584,0,0,0,.426-1.84"
          transform="translate(-5.854 -1.599)" fill={colorPrimary}/>
    <path id="Path_10603" data-name="Path 10603"
          d="M135.821,7.948c-.005.015-.012.03-.017.046.005-.017.01-.033.015-.049l0,0"
          transform="translate(-10.164 -2.179)" fill={colorPrimary}/>
    <g id="Group_18814" data-name="Group 18814" transform="translate(80.23 4.162)" opacity="0.3">
      <g id="Group_18813" data-name="Group 18813" transform="translate(0 0)">
        <g id="Group_18812" data-name="Group 18812" clipPath="url(#clip-path-long-sleeve)">
          <path id="Path_10604" data-name="Path 10604"
                d="M108.849,6.18h-.022c-17.64.029-22.581,1.406-22.7,1.6,0,.016.009.032.015.049a21.274,21.274,0,0,0,1.183,2.86c3.318,6.583,9.9,10.27,21.5,11.048v0h.022v0c11.608-.779,18.185-4.466,21.5-11.048a21.4,21.4,0,0,0,1.184-2.86l.014-.049c-.119-.2-5.059-1.574-22.7-1.6"
                transform="translate(-86.126 -6.18)" fill={colorPrimary}/>
        </g>
      </g>
    </g>
    <path id="Path_10605" data-name="Path 10605"
          d="M168.571,227.147c-3.494-23.866-2.454-105.673-2.388-112.218a2.111,2.111,0,0,0-.085-.609C152.526,67.937,154.316,34.991,155.026,27.1a2.16,2.16,0,0,1,2-1.956l-21.921-9.557s1.016,12.72-29.453,16.96c-.232.033-.451.045-.679.073-.229-.027-.449-.04-.681-.073-30.468-4.24-29.453-16.96-29.453-16.96L52.919,25.143a2.158,2.158,0,0,1,2,1.956c.71,7.892,2.5,40.838-11.071,87.221a2.02,2.02,0,0,0-.084.609c.065,6.545,1.105,88.353-2.389,112.218a5.552,5.552,0,0,0,3.374,5.9c6.7,2.81,10.36,4.314,10.841,4.511.04.016.075.03.116.044,20.539,7.1,49.267,7.3,49.267,7.3s28.726-.2,49.265-7.3c.041-.014.076-.027.117-.044.481-.2,4.143-1.7,10.839-4.511a5.551,5.551,0,0,0,3.375-5.9"
          transform="translate(-2.044 -2.878)" fill={colorPrimary}/>
  </svg>
)

export const UniformItemSleevelessSvg = ({colorPrimary}: ItemProps) => (
  <svg id="uniformItemSleevelessSvg" data-name="uniformItemSleevelessSvg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
       width="127.309" height="242.026" viewBox="0 0 127.309 242.026">
    <defs>
      <clipPath id="clip-path-sleeveless">
        <rect id="Rectangle_9650" data-name="Rectangle 9650" width="45.424" height="15.563" fill="none"/>
      </clipPath>
    </defs>
    <path id="Path_10600" data-name="Path 10600"
          d="M86.123,7.948c.005.015.012.03.017.046-.005-.017-.01-.033-.015-.049l0,0"
          transform="translate(-45.171 -2.179)" fill={colorPrimary}/>
    <path id="Path_10601" data-name="Path 10601"
          d="M137.456,13.885l-5.18-6.966s-.914,15.29-24.052,16.09c-23.139-.8-24.053-16.09-24.053-16.09l-5.18,6.966s-1,12.652,29.222,16.925v0h.022v0c30.224-4.273,29.221-16.925,29.221-16.925"
          transform="translate(-44.558 -2.085)" fill={colorPrimary}/>
    <path id="Path_10602" data-name="Path 10602"
          d="M131.935,5.525s-4.28-3.7-23.128-3.926h-.022C89.939,1.828,85.66,5.525,85.66,5.525a12.493,12.493,0,0,0,.425,1.84c.121-.2,5.062-1.575,22.712-1.6,17.651.029,22.593,1.406,22.713,1.6a12.584,12.584,0,0,0,.426-1.84"
          transform="translate(-45.131 -1.599)" fill={colorPrimary}/>
    <path id="Path_10603" data-name="Path 10603"
          d="M135.821,7.948c-.005.015-.012.03-.017.046.005-.017.01-.033.015-.049l0,0"
          transform="translate(-49.44 -2.179)" fill={colorPrimary}/>
    <g id="Group_18814" data-name="Group 18814" transform="translate(40.954 4.162)" opacity="0.3">
      <g id="Group_18813" data-name="Group 18813" transform="translate(0 0)">
        <g id="Group_18812" data-name="Group 18812" clipPath="url(#clip-path-sleeveless)">
          <path id="Path_10604" data-name="Path 10604"
                d="M108.849,6.18h-.022c-17.64.029-22.581,1.406-22.7,1.6,0,.016.009.032.015.049a21.274,21.274,0,0,0,1.183,2.86c3.318,6.583,9.9,10.27,21.5,11.048v0h.022v0c11.608-.779,18.185-4.466,21.5-11.048a21.4,21.4,0,0,0,1.184-2.86l.014-.049c-.119-.2-5.059-1.574-22.7-1.6"
                transform="translate(-86.126 -6.18)" fill={colorPrimary}/>
        </g>
      </g>
    </g>
    <path id="Path_10605" data-name="Path 10605"
          d="M168.571,227.147c-3.494-23.866-2.454-105.673-2.388-112.218a2.111,2.111,0,0,0-.085-.609C152.526,67.937,154.316,34.991,155.026,27.1a2.16,2.16,0,0,1,2-1.956l-21.921-9.557s1.016,12.72-29.453,16.96c-.232.033-.451.045-.679.073-.229-.027-.449-.04-.681-.073-30.468-4.24-29.453-16.96-29.453-16.96L52.919,25.143a2.158,2.158,0,0,1,2,1.956c.71,7.892,2.5,40.838-11.071,87.221a2.02,2.02,0,0,0-.084.609c.065,6.545,1.105,88.353-2.389,112.218a5.552,5.552,0,0,0,3.374,5.9c6.7,2.81,10.36,4.314,10.841,4.511.04.016.075.03.116.044,20.539,7.1,49.267,7.3,49.267,7.3s28.726-.2,49.265-7.3c.041-.014.076-.027.117-.044.481-.2,4.143-1.7,10.839-4.511a5.551,5.551,0,0,0,3.375-5.9"
          transform="translate(-41.32 -2.878)" fill={colorPrimary}/>
  </svg>
)


export const UniformItemShortsSvg = ({colorPrimary}: ItemProps) => (
  <svg id="uniformItemShortsSvg" data-name="uniformItemShortsSvg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="141.58"
       height="131.063" viewBox="0 0 141.58 131.063">
    <defs>
      <clipPath id="clip-path-shorts">
        <rect id="Rectangle_9646" data-name="Rectangle 9646" width="113.848" height="23.081" fill={colorPrimary}/>
      </clipPath>
      <clipPath id="clip-path-2-shorts">
        <rect id="Rectangle_9647" data-name="Rectangle 9647" width="107.786" height="9.273" fill="none"/>
      </clipPath>
    </defs>
    <path id="Path_10591" data-name="Path 10591"
          d="M287.6,88.771a2.56,2.56,0,0,1-1.014-.677c-.652.334-.938,1.449-1.046,2.17-1.112,7.391-7.414,48.659-9.068,80.968,0,.025,0,.049-.006.074a55.094,55.094,0,0,1-3.424,12.024l-2.2,5.446a.858.858,0,0,0,.57,1.15l3.783,1.029s18.176,5.418,34.717,4.335a61.779,61.779,0,0,1,27.789,4.722.856.856,0,0,0,1.2-.726c.461-6.671,2.7-38.378,4.449-49.867a.743.743,0,0,0,.01-.124V96.619a.853.853,0,0,0-.832-.859c-6.123-.138-38.611-1.136-54.927-6.989"
          transform="translate(-270.777 -69.466)" fill={colorPrimary}/>
    <path id="Path_10592" data-name="Path 10592"
          d="M410.329,88.771a2.56,2.56,0,0,0,1.014-.677c.652.334.938,1.449,1.046,2.17,1.113,7.394,7.419,48.691,9.07,81.007-.364,0,1.272,19.685,1.272,19.685s-18.176,5.418-34.717,4.335a60.581,60.581,0,0,0-28.9,5.238s-2.586-38.36-4.544-51.171V95.777c2.8-.048,38.414-.783,55.76-7.005"
          transform="translate(-281.15 -69.466)" fill={colorPrimary}/>
    <g id="Group_18802" data-name="Group 18802" transform="translate(16.108 2.01)" opacity="0.8">
      <g id="Group_18801" data-name="Group 18801" transform="translate(0 0)">
        <g id="Group_18800" data-name="Group 18800" clipPath="url(#clip-path-shorts)">
          <path id="Path_10593" data-name="Path 10593"
                d="M401.8,79.852c0-.181,1.272-3.453,0-5.089,0-.182.181-3.818-1.817-5.635-8.484,6.526-46.246,7.194-53.893,7.262-7.647-.068-45.409-.736-53.893-7.262-2,1.817-1.818,5.453-1.818,5.635-1.272,1.636,0,4.908,0,5.089-1.73.931-1.22,3.221-.822,4.385a7.519,7.519,0,0,0,.277.7c16.814,6.349,52.245,7.19,56.165,7.265v0l.091,0,.091,0v0c3.919-.074,39.35-.916,56.165-7.265a7.514,7.514,0,0,0,.277-.7c.4-1.164.908-3.453-.822-4.385"
                transform="translate(-289.162 -69.128)" fill={colorPrimary}/>
        </g>
      </g>
    </g>
    <g id="Group_18805" data-name="Group 18805" transform="translate(19.525)" opacity="0.3">
      <g id="Group_18804" data-name="Group 18804" transform="translate(0 0)">
        <g id="Group_18803" data-name="Group 18803" clipPath="url(#clip-path-2-shorts)">
          <path id="Path_10594" data-name="Path 10594"
                d="M347.045,76.105c7.848-.073,45.351-.761,53.8-7.262,0,0-36.994-2.064-53.893-2.008-16.9-.056-53.893,2.008-53.893,2.008,8.451,6.5,45.954,7.189,53.8,7.262v0h.181Z"
                transform="translate(-293.061 -66.834)" fill={colorPrimary}/>
        </g>
      </g>
    </g>
  </svg>
)

export const UniformItemSkirtsSvg = ({colorPrimary}: ItemProps) => (
  <svg id="uniformItemSkirtsSvg" data-name="uniformItemSkirtsSvg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
       width="141.58" height="134.58" viewBox="0 0 141.58 134.58">
    <defs>
      <clipPath id="clip-path-skirts">
        <rect id="Rectangle_9646" data-name="Rectangle 9646" width="113.848" height="23.081" fill={colorPrimary}/>
      </clipPath>
      <clipPath id="clip-path-2-skirts">
        <rect id="Rectangle_9647" data-name="Rectangle 9647" width="107.786" height="9.273" fill="none"/>
      </clipPath>
    </defs>
    <path id="Path_10591" data-name="Path 10591"
          d="M287.6,88.771a2.56,2.56,0,0,1-1.014-.677c-.652.334-.938,1.449-1.046,2.17-1.112,7.391-7.414,48.659-9.068,80.968,0,.025,0,.049-.006.074a55.094,55.094,0,0,1-3.424,12.024l-2.2,5.446a.858.858,0,0,0,.57,1.15l3.783,1.029s18.176,5.418,34.717,4.335c13.619-.892,32.4,7.236,35.789,8.722.542.238,1.107-.862,1.2-.726,16.243-37.218,15.493-44.468,16.449-48.867.007-.042.01-1.082.01-57.8a.853.853,0,0,0-.832-.859c-36.145,1.081-58.611-1.136-74.927-6.989"
          transform="translate(-270.777 -69.466)" fill={colorPrimary}/>
    <path id="Path_10592" data-name="Path 10592"
          d="M410.329,88.771a2.56,2.56,0,0,0,1.014-.677c.652.334.938,1.449,1.046,2.17,1.113,7.394,7.419,48.691,9.07,81.007-.364,0,1.272,19.685,1.272,19.685s-18.176,5.418-34.717,4.335-27.9,5.238-27.9,5.238,16.414-36.36,14.456-49.171V95.777a122.7,122.7,0,0,0,35.76-7.005"
          transform="translate(-281.15 -69.466)" fill={colorPrimary}/>
    <g id="Group_18802" data-name="Group 18802" transform="translate(16.109 2.01)" opacity="0.8">
      <g id="Group_18801" data-name="Group 18801" transform="translate(0 0)">
        <g id="Group_18800" data-name="Group 18800" clipPath="url(#clip-path-skirts)">
          <path id="Path_10593" data-name="Path 10593" d="M401.8,79.852c0-.181,1.272-3.453,0-5.089,0-.182.181-3.818-1.817-5.635-8.484,6.526-46.246,7.194-53.893,7.262-7.647-.068-45.409-.736-53.893-7.262-2,1.817-1.818,5.453-1.818,5.635-1.272,1.636,0,4.908,0,5.089-1.73.931-1.22,3.221-.822,4.385a7.519,7.519,0,0,0,.277.7c16.814,6.349,52.245,7.19,56.165,7.265v0l.091,0,.091,0v0c3.919-.074,39.35-.916,56.165-7.265a7.514,7.514,0,0,0,.277-.7c.4-1.164.908-3.453-.822-4.385"
                transform="translate(-289.162 -69.128)" fill={colorPrimary}/>
        </g>
      </g>
    </g>
    <g id="Group_18805" data-name="Group 18805" transform="translate(19.526 0)" opacity="0.3">
      <g id="Group_18804" data-name="Group 18804" transform="translate(0 0)">
        <g id="Group_18803" data-name="Group 18803" clipPath="url(#clip-path-2-skirts)">
          <path id="Path_10594" data-name="Path 10594" d="M347.045,76.105c7.848-.073,45.351-.761,53.8-7.262,0,0-36.994-2.064-53.893-2.008-16.9-.056-53.893,2.008-53.893,2.008,8.451,6.5,45.954,7.189,53.8,7.262v0h.181Z"
                transform="translate(-293.061 -66.834)" fill={colorPrimary}/>
        </g>
      </g>
    </g>
  </svg>
)

export const UniformItemSocksSvg = ({colorPrimary}: ItemProps) => (
  <svg id="uniformItemSocksSvg" data-name="uniformItemSocksSvg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="71.069"
       height="100.222" viewBox="0 0 71.069 100.222">
    <defs>
      <clipPath id="clip-path-socks">
        <rect id="Rectangle_9651" data-name="Rectangle 9651" width="44.288" height="93.312" fill={colorPrimary}/>
      </clipPath>
    </defs>
    <g id="Group_18817" data-name="Group 18817" transform="translate(0 3.455)" opacity="0.8">
      <g id="Group_18816" data-name="Group 18816" transform="translate(0 0)">
        <g id="Group_18815" data-name="Group 18815" clipPath="url(#clip-path-socks)">
          <path id="Path_10607" data-name="Path 10607"
                d="M521.233,171.22c.2-.13,17.668-11.585,21.268-13.8,3.322-2.04,3.153-7.883,3.151-7.942l-.025-57.27H527.744l.024,56.52s.214,5.758-3.171,7.836-19.77,12.824-19.77,12.824-6.412,4.631-1.781,12.586c3.465,5.952,12.111,2.613,12.111,2.613l2.88-1.529a10.3,10.3,0,0,1,3.2-11.842"
                transform="translate(-501.367 -92.213)" fill={colorPrimary}/>
        </g>
      </g>
    </g>
    <path id="Path_10608" data-name="Path 10608"
          d="M550.794,87.785h22.573v59.94a15.666,15.666,0,0,0,2.295,7.141c2.3,3.954,2.678,10.713-5.866,15.049S537.276,187,537.276,187s-9.287,3.587-13.008-2.805c-4.973-8.545,1.913-13.519,1.913-13.519s17.6-11.541,21.234-13.773,3.406-8.416,3.406-8.416Z"
          transform="translate(-506.001 -87.785)" fill={colorPrimary}/>
  </svg>
)
