import {Alert, Checkbox, InputNumber, Select, Table} from "antd";
import {useIntl} from "react-intl";
import {useCallback} from "react";
import {useParams} from "react-router-dom";
import {useRosterPositionsStore} from "../../../shared";
import {AddRosterButton} from "./AddRosterButton";
import {PersonLabel} from "../../../shared/ui/PersonLabel";
import {useIsFixtureRosterReachedMaxSize} from "../model";
import {useIsFixtureStarterReachedMaxSize} from "../model/useIsFixtureStarterReachedMaxSize";
import {
  getDobColumn,
  getNationalityColumn,
  getPersonStatusColumn,
  getRosterStatusColumn
} from "~shared/common";

export const PlayerList = ({
                             type,
                             persons,
                             isLoading,
                             pagination,
                             alreadyAddedPersons,
                             onTableChanged,
                           }) => {
  const {sport} = useParams();
  const rosterPositions = useRosterPositionsStore(store => store?.[sport] || store?.basketball);
  const {formatMessage} = useIntl();
  const reachedMaxFixtureRosterSize = useIsFixtureRosterReachedMaxSize({type});
  const reachedMaxFixtureStarterSize = useIsFixtureStarterReachedMaxSize({type});

  const getAlreadyAddedPerson = useCallback(
    (person) => alreadyAddedPersons.filter(
      x => x.dcPersonId === person.dcPersonId && x.personId === person.personId)?.[0] ?? null,
    [alreadyAddedPersons]);
  const isPersonAlreadyAdded = useCallback(
    (person) => getAlreadyAddedPerson(person) !== null, [getAlreadyAddedPerson]);

  const isFixtureRoster = type === "fixtureRoster";
  
  const tableColumns = [
    {
      title: formatMessage({
        id: "name.full",
        defaultMessage: "Full Name"
      }),
      dataIndex: "nameFullLocal",
      render: (value, record) =>
        <PersonLabel person={record} alreadyAdded={isPersonAlreadyAdded(record)} newlyCreated={record?.personId}/>
    },
    getDobColumn(formatMessage),
    getNationalityColumn(formatMessage),
    getPersonStatusColumn(formatMessage),
    {
      title: "Number",
      align: "center",
      dataIndex: "number",
      width: 75,
      render: (value, record) => !isPersonAlreadyAdded(record)
        ? <InputNumber min={1} defaultValue={value} onChange={(value) => {
          record.number = value?.toString() ?? "";
        }}/>
        : getAlreadyAddedPerson(record)?.number,
    },
    {
      title: "Position",
      dataIndex: "position",
      width: 120,
      align: "center",
      render: (position, record) => !isPersonAlreadyAdded(record)
        ? <Select
          style={{width: "150px"}}
          showSearch
          defaultValue={position}
          placeholder={"Select position..."}
          filterOption={(input, option) => (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())}
          onChange={(value) => record.position = value}
          options={[...rosterPositions?.map(position => {
            return {
              value: position,
              label: formatMessage({
                id: `roster.${position}`,
                defaultMessage: position,
              }),
              dcPersonId: record?.dcPersonId,
              personId: record?.personId,
            };
          }) ?? []]}/>
        : getAlreadyAddedPerson(record)?.position
          ? formatMessage({
            id: `roster.${getAlreadyAddedPerson(record).position}`,
            defaultMessage: getAlreadyAddedPerson(record).position
          })
          : "",
    },
    ...isFixtureRoster ? [getRosterStatusColumn(formatMessage)] : [],
    {
      title: "Captain",
      dataIndex: "isCaptain",
      render: (isCaptain, record) => !isPersonAlreadyAdded(record)
        ? <Checkbox defaultChecked={isCaptain} onChange={(e) => record.isCaptain = e.target.checked}/>
        : getAlreadyAddedPerson(record)?.isCaptain ? <i className="fa-solid fa-check"></i> : "",
      align: "center",
      width: 75,
    },
    ...(isFixtureRoster ? [{
      title: formatMessage({
        id: "starting",
        defaultMessage: "Starting"
      }),
      width: 75,
      dataIndex: "isStarter",
      render: (isStarter, record) => !isPersonAlreadyAdded(record)
        ? <Checkbox defaultChecked={isStarter} onChange={(e) => record.isStarter = e.target.checked}/>
        : getAlreadyAddedPerson(record)?.isStarter ? <i className="fa-solid fa-check"></i> : "",
      align: "center",
    }] : []),
    {
      render: (value, record) => !isPersonAlreadyAdded(record) && <AddRosterButton type={type} person={record}/>,
      width: 50
    },
  ];

  const alertSharedProps = {
    className: "mb-15",
    showIcon: true,
    type: "warning",
  };

  return (
    <>
      {reachedMaxFixtureRosterSize &&
        <Alert {...alertSharedProps} description="ROSTER LIMIT HAS BEEN MET" />}
      {reachedMaxFixtureStarterSize &&
        <Alert {...alertSharedProps} description="STARTING PLAYER LIMIT HAS BEEN MET" />}
      <Table 
        rowKey={(record) => record?.dcPersonId ? "dc:" + record?.dcPersonId.toString() : "db: " + record?.personId?.toString()}             
        size="small"             
        loading={isLoading}             
        onChange={onTableChanged}             
        pagination={pagination}             
        columns={tableColumns}             
        dataSource={persons}
      />
    </>
  );
};