/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Row, Tabs, Typography } from "antd"
import { useIntl } from "react-intl"
import { FixtureListForCopy } from "../../entities/fixtures/ui/FixtureListForCopy"
import { useCallback, useState } from "react"
import { PublishedStaffList } from "../../entities/staff"
import { CopyButton } from "./CopyButton"
import { PublishedRosterList } from "../../entities/roster"
import { useParams } from "react-router-dom"

type Props = {
  type: "fixtureStaff" | "fixtureRoster"
  closeModal: () => void
}

export const CopyForFixtureWrapper = ({ type, closeModal }: Props) => {
  const { formatMessage } = useIntl()
  const [viewFixtureId, setViewFixtureId] = useState<string | null>(null)
  const [viewSeasonId, setViewSeasonId] = useState<string | null>(null)

  const changeViewSeasonId = useCallback((seasonId: string | null) => {
    setViewSeasonId(seasonId)
    setViewFixtureId(null)
  }, [])

  const changeViewFixtureId = useCallback((seasonId: string | null) => {
    setViewFixtureId(seasonId)
    setViewSeasonId(null)
  }, [])

  const isFixtureStaff = type === "fixtureStaff"
  const isFixtureRoster = type === "fixtureRoster"

  const { seasonId: seasonIdParams } = useParams<{ seasonId: string }>()

  const items = [
    {
      key: "fixtures",
      label: formatMessage({
        id: "fixtures",
        defaultMessage: "Fixtures"
      }),
      children: <FixtureListForCopy setFixtureId={changeViewFixtureId} />
    },
    {
      key: "season",
      label: formatMessage({
        id: "season",
        defaultMessage: "Season"
      }),
      children: (
        <>
          {isFixtureRoster && <PublishedRosterList seasonId={seasonIdParams as any} />}
          {isFixtureStaff && <PublishedStaffList seasonId={seasonIdParams as any} />}
          <Row justify="end" className="mt-15">
            <CopyButton
              fromSeasonId={seasonIdParams as any}
              closeModal={closeModal}
              type={type}
            />
          </Row>
        </>
      )
    }
  ]

  return <>
    <Row>
      <Col span={24}>
        {(viewSeasonId || viewFixtureId) ? (
          <>
            <Button
              onClick={() => {
                changeViewSeasonId(null)
                changeViewFixtureId(null)
              }}
              icon={<i className="fa-solid fa-angle-left" />}
              className="text-upper"
            >
              Back
            </Button>
            {isFixtureStaff && <PublishedStaffList seasonId={viewSeasonId as any} fixtureId={viewFixtureId as any} />}
            {isFixtureRoster && <PublishedRosterList seasonId={viewSeasonId as any} fixtureId={viewFixtureId as any} />}
            {!isFixtureStaff && !isFixtureRoster && (
              <Typography.Title type="danger">
                Something went wrong.
              </Typography.Title>
            )}
            <Row justify="end" className="mt-15">
              <CopyButton
                fromFixtureId={viewFixtureId as any}
                fromSeasonId={viewSeasonId as any}
                closeModal={closeModal}
                type={type}
              />
            </Row>
          </>
        ) : <Tabs size="large" centered items={items} />}
      </Col>
    </Row>
  </>
}