import {useParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {useApi} from "~shared/api";
import {IUniformItemUpsertModel} from "../models/IUniformItemUpsertModel";

export const useUpsertUniformItem = () => {
  const {sport, organizationId, teamId} = useParams();
  const {mutateData} = useApi();

  return useMutation({
    mutationFn: async (upsertModel: IUniformItemUpsertModel) => {
      let url = `/${sport}/o/${organizationId}/entities/${teamId}/uniforms/${upsertModel.uniformId}/items`;
      if (upsertModel.uniformItemId) {
        url = `/${sport}/o/${organizationId}/entities/${teamId}/uniforms/items/${upsertModel.uniformItemId}`
      }
      return await mutateData({
        url: url,
        method: upsertModel.uniformItemId ? "PUT" : "POST",
        data: upsertModel.upsertData
      });
    }
  });
}
