import { useApi } from "../../../shared/api";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { notification } from "antd";

export const useUploadTeamImage = () => {
  const { mutateData } = useApi();
  const { sport, organizationId, teamId } = useParams();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: ({ data, imageType = "logo" }) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/image/${imageType}`,
        method: "PUT",
        data: data,
        imageType,
      });
    },
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: formatMessage({
          id: "team.update.success",
          defaultMessage: "Team updated successfully",
        }),
      });
      queryClient.invalidateQueries({ queryKey: ["team", teamId] });
      queryClient.invalidateQueries({ queryKey: ["teams", organizationId] });
    },
    onError: (error) => {
      notification.error({
        message: formatMessage({
          id: "team.update.failed",
          defaultMessage: "Update team has failed",
        }),
        description: error?.message,
        placement: "bottomRight",
      });
      console.error(error?.message, "onError");
    },
  });
};

export const useResetTeamImage = () => {
  const { mutateData } = useApi();
  const { sport, organizationId, teamId } = useParams();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: ({ imageId }) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/image/${imageId}`,
        method: "DELETE",
      });
    },
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: formatMessage({
          id: "team.update.success",
          defaultMessage: "Team updated successfully",
        }),
      });
      queryClient.invalidateQueries({ queryKey: ["team", teamId] });
      queryClient.invalidateQueries({ queryKey: ["teams", organizationId] });
    },
    onError: (error) => {
      notification.error({
        message: formatMessage({
          id: "team.update.failed",
          defaultMessage: "Update team has failed",
        }),
        description: error?.message,
        placement: "bottomRight",
      });
      console.error(error?.message, "onError");
    },
  });
};