import {shallow} from "zustand/shallow";
import {useFixtureRosterStore, useSeasonRosterStore} from "../../../entities/roster/model";
import {PlayerList} from "./PlayerList";
import {useFetchPublishedSeasonRoster} from "../../../entities/roster/api";
import {useEffect, useMemo} from "react";
import {StaffList} from "./StaffList";
import {sortBy} from "lodash";
import {useFixtureStaffStore, useSeasonStaffStore} from "../../../entities/staff";
import {useFetchPublishedSeasonStaff} from "../../../entities/staff/api";
import {useParams} from "react-router-dom";

export const PersonListForFixtureRosterOrStaff = ({type, textToSearch}) => {
  const {seasonId} = useParams();
  const fixtureRoster = useFixtureRosterStore(store => store?.fixtureRoster, shallow);
  const fixtureStaff = useFixtureStaffStore(store => store?.fixtureStaff, shallow);
  const seasonRosterPublished = useSeasonRosterStore(store => store?.seasonRosterPublished, shallow);
  const seasonStaffPublished = useSeasonStaffStore(store => store?.seasonStaffPublished, shallow);
  const {refetch: getPublishedSeasonRoster, isLoading: isPublishedSeasonRosterLoading} = useFetchPublishedSeasonRoster(seasonId);
  const {refetch: getPublishedSeasonStaff, isLoading: isPublishedSeasonStaffLoading} = useFetchPublishedSeasonStaff(seasonId);
  const isLoading = useMemo(() => {
    if (type === "fixtureRoster") {
      return isPublishedSeasonRosterLoading;
    } else if (type === "fixtureStaff") {
      return isPublishedSeasonStaffLoading;
    }
    return false;
  }, [type, isPublishedSeasonRosterLoading, isPublishedSeasonStaffLoading]);

  const persons = useMemo(() => {

    let publishedPersons = [];
    if (type === "fixtureRoster") {
      publishedPersons = seasonRosterPublished;
    } else if (type === "fixtureStaff") {
      publishedPersons = seasonStaffPublished;
    }

    const resultList = !textToSearch
      ? publishedPersons
      : publishedPersons.filter(x => x.nameFullLocal?.toLowerCase().indexOf(textToSearch.toLowerCase()) !== -1);
    return sortBy(resultList, [x => x.nameFullLocal, x => x.nameFullLatin, x => x.dob]);
  }, [type, seasonRosterPublished, seasonStaffPublished, textToSearch])

  const alreadyAddedPersons = useMemo(() => {
    if (type === "fixtureRoster") {
      return fixtureRoster;
    } else if (type === "fixtureStaff") {
      return fixtureStaff;
    }
    return [];
  }, [type, fixtureRoster, fixtureStaff]);
  
  useEffect(() => {
    if (type === "fixtureRoster") {
      getPublishedSeasonRoster();
    } else if (type === "fixtureStaff") {
      getPublishedSeasonStaff();
    }
  }, [type, getPublishedSeasonRoster, getPublishedSeasonStaff]);
  
  if (type === "fixtureRoster") {
    return <PlayerList type={type}
                       persons={persons}
                       isLoading={isLoading}
                       alreadyAddedPersons={alreadyAddedPersons}/>
  } else if (type === "fixtureStaff") {
    return <StaffList type={type}
                      persons={persons}
                      isLoading={isLoading}
                      alreadyAddedPersons={alreadyAddedPersons}/>
  } else {
    return null;
  }
};