import { Space, Tag, Typography } from "antd";
import { useFixturesStore } from "../../entities/fixtures";
import { useFixtureRosterStore } from "../../entities/roster/model";
import { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

const hasMaxBeenReached = (currentSize, maxSize) => currentSize > maxSize && maxSize > 0;

export const FixtureRosterMaxSizeInfo = () => {
  const fixture = useFixturesStore(store => store?.fixture);
  const fixtureRoster = useFixtureRosterStore(store => store?.fixtureRoster);
  const maxFixtureRosterSize = fixture?.maximumRosterSize || null;
  const maxFixtureRosterStarterSize = fixture?.maximumRosterStarterSize || null;
  const [starterSize, setStarterSize] = useState(0);

  useEffect(() => {
    setStarterSize(fixtureRoster?.filter(x => x?.isStarter)?.length);
  }, [fixtureRoster]);

  return <Space direction={"vertical"} size={0}>
    <Space>
      <Typography.Text strong>Team Roster List</Typography.Text>
      <Tag icon={hasMaxBeenReached(fixtureRoster?.length, maxFixtureRosterSize) ? <CloseCircleOutlined/> : ""}
           color={hasMaxBeenReached(fixtureRoster?.length, maxFixtureRosterSize) ? "red" : ""}
           style={{ fontSize: "16px" }}
           title={"title"}>
        {fixtureRoster?.length} / {maxFixtureRosterSize || "(max value not set)"}
      </Tag>
    </Space>
    <Space>
      <Typography.Text strong>Team Starter Size</Typography.Text>
      <Tag icon={hasMaxBeenReached(starterSize, maxFixtureRosterStarterSize) ? <CloseCircleOutlined/> : ""}
           color={hasMaxBeenReached(starterSize, maxFixtureRosterStarterSize) ? "red" : ""} style={{ fontSize: "16px" }}
           title={"title"}>{starterSize} / {maxFixtureRosterStarterSize || "(max value not set)"}</Tag>
    </Space>
  </Space>;
};