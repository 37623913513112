import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Result } from "antd";

export const LogoutRedirectPage = () => {
  const [searchParams] = useSearchParams();
  const param = searchParams.get("returnTo");

  useEffect(() => {
    window.location.href = param;
  }, [param]);

  return <Result
    icon={<LoadingOutlined/>}
    title="Redirection to previous page..."
  />;
};