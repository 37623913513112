import { useCallback, useEffect, useState } from "react"
import { Table } from "antd"
import { useFetchFixtureStaff, useFetchSeasonStaff } from "../api"
import { useFixtureStaffStore, useSeasonStaffStore, useStaffListColumns } from "../model"
import { DeleteStaff } from "./DeleteStaff"
import { useRole } from "~shared/hooks"
import { useStaffRolesStore } from "~shared/model"
import { useParams } from "react-router-dom"

export const StaffList = ({ type, searchText = "", canEdit = true, isEditMode = false, onColumnChanged = undefined }) => {
  const { sport } = useParams();
  const { isLoading: isLoadingFixtureStaff } = useFetchFixtureStaff({
    enabled: type === "fixtureStaff"
  })
  const { isLoading: isLoadingSeasonStaff } = useFetchSeasonStaff({
    enabled: type === "seasonStaff"
  })
  const seasonStaff = useSeasonStaffStore(store => store?.seasonStaff)
  const fixtureStaff = useFixtureStaffStore(store => store?.fixtureStaff)
  const staffRoles = useStaffRolesStore(store => store?.[sport] || store?.basketball);
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [data, setData] = useState([])
  const columns = useStaffListColumns({
    type,
    canEdit: typeof canEdit === "undefined" ? true : canEdit,
    isEditMode,
    onColumnChanged,
    rolesList: staffRoles
  })
  const { isAdmin } = useRole()

  const generateStaffData = useCallback(() => {
    const sourceData = type === "seasonStaff" ? seasonStaff : fixtureStaff

    setData(sourceData
      ?.filter(person => person?.dcPersonId || person?.personId)
      ?.filter((person) => person?.nameFullLocal?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1
        || person?.personId === searchText
        || person?.dcPersonId === searchText))
  }, [seasonStaff, fixtureStaff, type, searchText])

  useEffect(() => {
    generateStaffData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonStaff, fixtureStaff, type, searchText])

  const resetSelectedRows = () => {
    setSelectedRows([])
    setSelectedRowKeys([])
  }

  const onSelectChange = (newSelectedRowKeys, newSelectedRow) => {
    setSelectedRows(newSelectedRow)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  return <Table
    dataSource={data}
    size={"small"}
    rowSelection={isAdmin ? null : rowSelection}
    rowKey={(record) => record?.dcPersonId ? record?.dcPersonId : record?.personId}
    loading={type === "seasonStaff" ? isLoadingSeasonStaff : isLoadingFixtureStaff}
    title={() => selectedRows?.length ? <DeleteStaff type={type} resetSelectedRows={resetSelectedRows}
                                                     persons={selectedRows}/> : null}
    columns={columns}
    pagination={false}
  />
}