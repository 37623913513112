import { Checkbox, Form, InputNumber, Select } from "antd";
import { useIntl } from "react-intl";
import { useRosterPositionsStore } from "../../../shared";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const RosterForm = ({ person, setFormRef, type }) => {
  const { sport } = useParams();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const rosterPositions = useRosterPositionsStore(store => store?.[sport] || store?.basketball);

  useEffect(() => {
    setFormRef(form);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Form
    form={form}
    initialValues={person}
    labelCol={{
      span: 5,
    }}
    wrapperCol={{
      span: 10,
    }}
  >
    <Form.Item
      label={formatMessage({
        id: "position",
        defaultMessage: "Position",
      })}
      name="position"
    >
      <Select
        placeholder={"Select position..."}
        showSearch
        options={[...rosterPositions?.map(position => {
          return {
            value: position,
            label: formatMessage({
              id: `roster.${position}`,
              defaultMessage: position,
            }),
          };
        }) ?? []]}
      />
    </Form.Item>
    <Form.Item
      label={formatMessage({
        id: "number",
        defaultMessage: "Number",
      })}
      name={"number"}
    >
      <InputNumber min={1} style={{ width: "100%" }}/>
    </Form.Item>
    <Form.Item
      valuePropName="checked"
      label={formatMessage({
        id: "form.captain",
        defaultMessage: "Captain?",
      })}
      name={"isCaptain"}
    >
      <Checkbox/>
    </Form.Item>
    {type === "fixtureRoster" ? <Form.Item
      valuePropName="checked"
      label={formatMessage({
        id: "form.starter",
        defaultMessage: "Starter?",
      })}
      name={"isStarter"}
    >
      <Checkbox/>
    </Form.Item> : ""}
  </Form>;
};