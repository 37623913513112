import { Modal, Typography } from "antd"
import { useApproveFixtureTask, useApproveSeasonTask } from "../../../entities/task"
import { useEffect, useState } from "react"

export const ApproveModal = ({ open, closeModal, type }) => {
  const {
    mutate: approveSeasonTask,
    isLoading: isLoadingSeasonTask,
    isSuccess: isSuccessSeasonTask
  } = useApproveSeasonTask()
  const {
    mutate: approveFixtureTask,
    isLoading: isLoadingFixtureTask,
    isSuccess: isSuccessFixtureTask
  } = useApproveFixtureTask()
  const [modalTitle, setModalTitle] = useState("Approve season Roster & Staff?")
  const [modalText, setModalText] = useState("This action approves both the Roster and the Staff that have been submitted for this season by this Team Manager.")

  useEffect(() => {
    if (type === "fixtureRoster" || type === "fixtureStaff") {
      setModalTitle("Approve fixture Roster & Staff?")
      setModalText("This action approves both the Roster and the Staff that have been submitted for this fixture by this Team Manager.")
    }
  }, [type])

  useEffect(() => {
    if (isSuccessSeasonTask || isSuccessFixtureTask) {
      closeModal()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSeasonTask, isSuccessFixtureTask])

  const handleApprove = () => {
    switch (type) {
      case "fixtureStaff":
      case "fixtureRoster":
        return approveFixtureTask()
      case "seasonRoster":
      case "seasonStaff":
        return approveSeasonTask()
    }
  }

  return <Modal
    title={modalTitle}
    destroyOnClose={true}
    open={open}
    onCancel={closeModal}
    okText={"Confirm"}
    onOk={handleApprove}
    okButtonProps={{
      loading: isLoadingSeasonTask || isLoadingFixtureTask
    }}
  >
    <Typography.Paragraph>{modalText}</Typography.Paragraph>
    <Typography.Paragraph>Are you happy to confirm both?</Typography.Paragraph>
  </Modal>
}