// vendors
import { User } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

const getSentryConfig = (app: string) => {
  const appDsnMap: { [key: string]: string } = {
    TeamManager: import.meta.env.SENTRY_DSN,
  };
  const dsn = appDsnMap[app];
  let denyUrls = ["0.0.0.0", "localhost", "127.0.0.1", "::1"];
  if (import.meta.env.TRACING_DEBUG) {
    denyUrls = [];
  }
  return {
    release: import.meta.env.PROJECT_VERSION,
    tracesSampleRate: 1.0,
    dsn,
    environment: import.meta.env.ENVIRONMENT,
    denyUrls,
    debug: import.meta.env.TRACING_DEBUG === "yes",
  };
};

export const Tracing = {
  init: (app: string) => {
    try {
      const config = getSentryConfig(app);
      if (config.environment === "local") {
        return;
      }
      console.debug("Configuring tracing", config);
      Sentry.init({
        ...config,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.breadcrumbsIntegration({
            console: false,
          }),
        ],
      });
    } catch (error) {
      console.error("Tracing init", error);
    }
  },
  capture: (exception: any) => {
    try {
      Sentry.captureException(exception);
    } catch (error: any) {
      console.error("Tracing.capture error", error.message, exception);
    }
  },
  captureMessage: (message: any, context: any) => {
    try {
      Sentry.captureMessage(message, context);
    } catch (error: any) {
      console.error("Tracing.captureMessage error", error.message, message);
    }
  },
  setUser: (user: User) => {
    try {
      Sentry.setUser({
        id: user.sub,
        username: user.preferred_username || user.nickname || user.name || user.email,
        email: user.email,
        ip_address: "{{auto}}",
      });
    } catch (error: any) {
      console.error("Tracing.setUser error", error.message, user);
    }
  },
};
