import { Tag } from "antd";
import { TaskStatusTagForFixture } from "./TaskStatusTagForFixture";
import { useParams } from "react-router-dom";
import { TaskStatusTagForSeason } from "./TaskStatusTagForSeason";
import { TaskStatusTagWithTextReason } from "./TaskStatusTagWithTextReason";

const color = {
  DRAFT: "volcano",
  SUBMITTED: "volcano",
  REVIEW: "volcano",
  APPROVED: "geekblue",
  REJECTED: "red",
  PUBLISHING: "geekblue",
  PUBLISHED: "green",
};

export const TaskStatusTag = ({ status, rejectReason }) => {
  const { fixtureId } = useParams();

  if (!status) return null;

  if (status === "REJECTED") {
    if (rejectReason) {
      return <TaskStatusTagWithTextReason text={rejectReason}/>;
    }

    if (fixtureId) {
      return <TaskStatusTagForFixture/>;
    } else {
      return <TaskStatusTagForSeason/>;
    }
  }

  return <Tag color={color[status]} style={{ fontSize: "16px" }} className={"mt-5"}>
    {status}
  </Tag>;
};