import { Table } from "antd";
import { useFetchPublishedFixtureStaff, useFetchPublishedSeasonStaff } from "../api"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { getRoleColumn, getNameFullLocalColumns, getPersonStatusColumn } from "~shared/common";

export const PublishedStaffList = ({ seasonId = null, fixtureId = null }) => {
  const [data, setData] = useState([])
  const { formatMessage } = useIntl()
  const { refetch: fetchSeasonStaff, isLoading: isLoadingSeasonStaff } = useFetchPublishedSeasonStaff(seasonId)
  const { refetch: fetchFixtureStaff, isLoading: isLoadingFixtureStaff } = useFetchPublishedFixtureStaff(fixtureId)

  // TODO: fix this state duplication - use useQuery directly
  const fetchSeasonStaffFn = async () => {
    try {
      const res = await fetchSeasonStaff({
        seasonId
      })
      setData(res?.data?.data || [])
    } catch (e) {
      console.error(e)
    }
  }

  // TODO: fix this state duplication - use useQuery directly
  const fetchFixtureStaffFn = async () => {
    try {
      const res = await fetchFixtureStaff({
        fixtureId
      })
      setData(res?.data?.data || [])
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (seasonId) {
      fetchSeasonStaffFn()
    }
    if (fixtureId) {
      fetchFixtureStaffFn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonId, fixtureId])

  return (
    <Table
      dataSource={data}
      loading={seasonId ? isLoadingSeasonStaff : isLoadingFixtureStaff}
      size="small"
      columns={[
        getNameFullLocalColumns(formatMessage),
        getPersonStatusColumn(formatMessage, true),
        getRoleColumn(formatMessage)
      ]}
      rowKey={(record) => record?.dcPersonId ? record?.dcPersonId : record?.personId}
    />
  )
}