import { Table } from "antd"
import { useFetchFixtures } from "../api"
import { useFixturesStore } from "../model"
import { useColumnFixtureTable } from "./useColumnFixtureTable"

export const FixtureList = () => {
  const { isLoading } = useFetchFixtures()
  const fixtures = useFixturesStore(state => state?.fixtures)
  const columns = useColumnFixtureTable({ fixtures })

  return <Table size={"small"}
                columns={columns}
                loading={isLoading}
                dataSource={fixtures?.data}
                rowKey={"fixtureId"}
  />
}