import { create } from "zustand"
import { devtools } from "zustand/middleware"

export const useTaskStore = create(devtools((set) => ({
  task: null,
  setTask: data => {
    set({
      task: data
    })
  }
})), { name: "task" })