import { Space } from "antd";
import { useIntl } from "react-intl";
import { EditPerson } from "../../../features/EditPerson";
import { DeleteRoster } from "../ui/DeleteRoster";
import { useRole } from "~shared/hooks";
import {
  getDobColumn,
  getNationalityColumn,
  getPersonStatusColumn,
  getNameFullLocalColumns,
  getGetPositionColumn,
  getIsCaptainColumn,
  getRosterStatusColumn,
  getRosterNumberColumn,
  getIsStarterColumn
} from "~shared/common";
import { useMemo } from "react";


export const useRosterListColumns = ({ type, canEdit = true, isEditMode = false, rosterPositions = [], onColumnChanged = undefined }) => {
  const { formatMessage } = useIntl();
  const { isTeamManager } = useRole();
  const isFixtureRoster = type === "fixtureRoster";


  const columns = useMemo(() => {
    return [
      // getNumberColumn(formatMessage),
      getRosterNumberColumn(formatMessage, { canEdit, isEditMode, onColumnChanged }),
      getNameFullLocalColumns(formatMessage, true),
      getDobColumn(formatMessage),
      getNationalityColumn(formatMessage),
      getPersonStatusColumn(formatMessage, true),
      getGetPositionColumn(formatMessage, { canEdit, isEditMode, rosterPositions, onColumnChanged }),
      ...isFixtureRoster ? [getRosterStatusColumn(formatMessage)] : [],
      getIsCaptainColumn(formatMessage, { canEdit, isEditMode, onColumnChanged }),
      ...(isFixtureRoster ? [getIsStarterColumn(formatMessage, { canEdit, isEditMode, onColumnChanged })] : []),
      ...isTeamManager ? [{
        render: (value, record) => (
          <Space>
            <EditPerson type={type} person={record} />
            <DeleteRoster buttonType="small" persons={[record]} type={type} />
          </Space>
        ),
        width: 1,
        align: "center",
      }] : []
    ];
  }, [type, canEdit, isEditMode, rosterPositions, onColumnChanged, formatMessage, isTeamManager, isFixtureRoster]);

  return columns;
};