import { Button, Row, Select, Space, Table, theme, Typography } from "antd";
import { useFetchSeasonsByOrganization } from "../api";
import { useIntl } from "react-intl";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { useCallback, useState } from "react";
import { CompetitionLogoBadge, SeasonLogoBadge, TablePagination, TaskStatusTag, TeamLogoBadge } from "../../../shared";
import { DeleteTask } from "../../../features/DeleteTask";

const { useToken } = theme;

const FilterOptions = [
  {
    label: "Default view",
    value: "draft,submitted,review,rejected,approved,publishing",
  },
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Submitted",
    value: "submitted",
  },
  {
    label: "Review",
    value: "review",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Publishing",
    value: "publishing",
  },
  {
    label: "Published",
    value: "published",
  },
];

export const AdminSeasonList = () => {
  const { token } = useToken();
  const { formatMessage } = useIntl();
  const [filterValue, setFilterValue] = useState("draft,submitted,review,rejected,approved,publishing");
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const { isLoading, data } = useFetchSeasonsByOrganization({
    taskStatus: filterValue,
    page: tableParams?.current,
    pageSize: tableParams?.pageSize,
  });

  const handleChangePagination = useCallback(() => {
    return setTableParams;
  }, []);

  const handleChangeFilter = (value) => {
    setFilterValue(value);
    setTableParams({
      current: 1,
      pageSize: 10,
    });
  };

  return <>
    <Row justify={"space-between"} className={"mb-15"} align={"middle"}>
      <Typography.Text>Seasons Roster & Staff</Typography.Text>
      <Space>
        <Typography.Text>Task status</Typography.Text>
        <Select defaultValue={filterValue} onChange={handleChangeFilter} options={FilterOptions}
                popupMatchSelectWidth={false}/>
      </Space>
    </Row>
    <Table dataSource={data?.data}
           className={"mb-15"}
           size={"small"}
           pagination={false}
           rowKey={(record) => record?.entityId + record?.seasonId}
           loading={isLoading}
           columns={[
             {
               title: formatMessage({
                 id: "competition.name",
                 defaultMessage: "Competition Name",
               }),
               dataIndex: "competitionNameLocal",
               render: (competitionNameLocal, record) => <Space>
                 <CompetitionLogoBadge img={record?.competitionImageUrl}/>
                 {competitionNameLocal}
               </Space>,
             },
             {
               title: formatMessage({
                 id: "season.name",
                 defaultMessage: "Season Name",
               }),
               dataIndex: "nameLocal",
               render: (nameLocal, record) => <Space>
                 <SeasonLogoBadge img={record?.imageUrl}/>
                 {nameLocal}
               </Space>,
             },
             {
               title: formatMessage({
                 id: "team.name",
                 defaultMessage: "Team name",
               }),
               dataIndex: "entityNameLocal",
               render: (entityNameLocal, record) => {
                 return <Space size={5}>
                   <TeamLogoBadge img={record?.entityImageLogoUrl}/>
                   {entityNameLocal}
                 </Space>;
               },
             },
             {
               title: formatMessage({
                 id: "start.date",
                 defaultMessage: "Start Date",
               }),
               dataIndex: "startDate",
               render: startDate => moment(startDate).format("DD/MM/YYYY"),
               align: "center",
             },
             {
               title: formatMessage({
                 id: "end.date",
                 defaultMessage: "End Date",
               }),
               dataIndex: "endDate",
               render: endDate => moment(endDate).format("DD/MM/YYYY"),
               align: "center",
             },
             {
               title: formatMessage({
                 id: "registration.open",
                 defaultMessage: "Registration Open?",
               }),
               dataIndex: "isRegistrationOpened",
               render: (isRegistrationOpened) => isRegistrationOpened
                 ? <i style={{ color: token.colorSuccess }} className={"fa-solid fa-check"}></i>
                 : <i style={{ color: token.colorError }} className="fa-solid fa-xmark"></i>,
               align: "center",
             },
             {
               title: formatMessage({
                 id: "status",
                 defaultMessage: "Status",
               }),
               dataIndex: "taskStatus",
               align: "center",
               width: 1,
               render: (taskStatus, record) => <TaskStatusTag
                 rejectReason={record?.taskHistoryRecords[record?.taskHistoryRecords?.length - 1]?.text}
                 status={taskStatus}/>,
             },
             {
               render: (value, record) => <Space>
                 <Link to={`teams/${record?.entityId}/seasons/${record?.seasonId}/view`}>
                   <Button className={"text-upper"}
                           type={"primary"}>
                     view roster & staff</Button>
                 </Link>
                 <DeleteTask entity={record} type={"season"}/>
               </Space>,
               width: 1,
             },
           ]}
           footer={() => <Row justify={"end"}>
             <TablePagination tableParams={tableParams} total={data?.meta?.count}
                              changePagination={handleChangePagination}/>
           </Row>
           }
    />
  </>;
};