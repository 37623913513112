import { Button, Modal, Typography } from "antd";
import { useFixtureStaffStore, useSeasonStaffStore } from "../../../entities/staff";
import { useEffect, useState } from "react";
import { SubmitModal } from "./SubmitModal";

export const RolesAreNotSetModalWarning = ({ type, open, handleCloseModal }) => {
  const fixtureStaff = useFixtureStaffStore(store => store?.fixtureStaff);
  const seasonStaff = useSeasonStaffStore(store => store?.seasonStaff);
  const [rolesAreNotSet, setRolesAreNotSet] = useState(false);

  const checkMissedRole = (personList) => {
    if (!personList?.length) {
      setRolesAreNotSet(true);
    } else {
      const noRoles = personList?.filter(person => !person?.role);
      setRolesAreNotSet(!!noRoles?.length);
    }
  };

  useEffect(() => {
    switch (type) {
      case "seasonStaff":
        checkMissedRole(seasonStaff);
        break;
      case "fixtureStaff":
        checkMissedRole(fixtureStaff);
        break;
    }
  }, [type, fixtureStaff, seasonStaff]);

  if (rolesAreNotSet) {
    return <Modal footer={[<Button type={"primary"} onClick={handleCloseModal}>OK</Button>]}
                  title={"Missed staff roles"} open={open} onCancel={handleCloseModal} closable={true}
                  onOk={handleCloseModal}>
      <Typography.Text>Roles are not set for all persons. Please check.</Typography.Text>
    </Modal>;
  } else {
    return <SubmitModal type={type} open={open} handleCloseModal={handleCloseModal}/>;
  }
};