import { Button } from "antd";
import { useAddPersonsToFixtureRoster, useAddPersonsToSeasonRoster } from "../../../entities/roster/api";
import { useIsFixtureRosterReachedMaxSize } from "../model";

export const AddRosterButton = ({ type, person }) => {
  const { mutate: addFixtureRoster, isLoading: isLoadingAddFixtureRoster } = useAddPersonsToFixtureRoster();
  const { mutate: addSeasonRoster, isLoading: isLoadingAddSeasonRoster } = useAddPersonsToSeasonRoster();
  const disableButton = useIsFixtureRosterReachedMaxSize({ type });

  const onClickHandler = async () => {

    const personData = {
      [person?.dcPersonId ? "dcPersonId" : "personId"]: person?.dcPersonId || person?.personId,
    };
    
    if (person?.isCaptain) {
      personData.isCaptain = person?.isCaptain;
    }
    
    if (type === "fixtureRoster" && person?.isStarter) {
      personData.isStarter = person?.isStarter;
    }
    
    if (person?.position) {
      personData.position = person?.position;
    }
    
    if (person?.number){
      personData.number = person?.number;
    } 

    const data = {
      players: [
        personData,
      ],
    };

    try {
      if (type === "seasonRoster") {
        addSeasonRoster(data);
      } else if (type === "fixtureRoster") {
        addFixtureRoster(data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return <Button onClick={onClickHandler}
                 disabled={disableButton}
                 loading={isLoadingAddSeasonRoster || isLoadingAddFixtureRoster}
                 icon={<i className="fa-solid fa-plus"/>}/>;
};