import { Divider, Row, Skeleton, Space, Switch } from "antd";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

// Project
import { useUpdateRosterByPerson } from "../../entities/roster/api";
import { BackToButton, SearchInput, TaskStatusTag } from "../../shared";
import { StaffRosterFixtureTitle, useFetchFixture, useFixturesStore } from "../../entities/fixtures";
import { AddPerson } from "../../features/AddPerson";
import { RosterList } from "../../entities/roster";
import { SubmitSeasonStaff } from "../../features/SubmitSeasonStaff";
import { CopyPersons } from "../../features/CopyPersons";

// Page
import { FixtureTeamUniformSelect } from "./FixtureTeamUniformSelect";
import { FixtureRosterMaxSizeInfo } from "./FixtureRosterMaxSizeInfo";

export const FixtureRoster = () => {
  const { customerId, organizationId, sport, teamId, seasonId } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading } = useFetchFixture();
  const fixture = useFixturesStore(store => store?.fixture);
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    updated: updatedPerson,
    isLoading: isUpdating,
    mutate: updateRoster
  } = useUpdateRosterByPerson({ type: "fixtureRoster" })

  const canEdit = useCallback((column, record) => {
    const isTarget = record === updatedPerson;
    return !(isTarget && isUpdating);
  }, [isUpdating, updatedPerson]);
  
  const onColumnChanged = useCallback(async ({ column, value, record }) => {
    const dataCopy = {
      [column]: value,
    }
    dataCopy[column] = column === "number" ? String(dataCopy[column]) : dataCopy[column];
    await updateRoster(dataCopy, record)
  }, [updateRoster]);

  const onIsEditModeChange = useCallback((checked) => {
    setIsEditMode(checked);
  }, []);

  return <>
    <Row className={"mt-15"} justify={"space-between"}>
      {isLoading ? <Skeleton.Input/> : <Space align={"center"}>
        <StaffRosterFixtureTitle type={"roster"}/>
        {fixture?.taskStatus && <TaskStatusTag status={fixture?.taskStatus}/>}
      </Space>}
      <BackToButton to={`/${customerId}/${organizationId}/${sport}/teams/${teamId}/seasons/${seasonId}/fixtures`}
                    btnText={formatMessage({
                      id: "back.to.fixtures", defaultMessage: "Back to fixtures",
                    })}/>
    </Row>
    <Divider/>
    <Row className={"mb-10"} justify={"space-between"} align={"middle"}>
      <FixtureRosterMaxSizeInfo/>
      <Space>
        <span>{formatMessage({ id: "edit.mode", defaultMessage: "Edit mode" })}</span>
        <Switch checked={isEditMode} disabled={!fixture?.areRosterAndStaffEditable || false} onChange={onIsEditModeChange} />
        <SearchInput doSearch={setSearchText}/>
        <FixtureTeamUniformSelect disabled={!fixture?.areRosterAndStaffEditable || false} fixture={fixture} teamId={teamId} />
        <CopyPersons type={"fixtureRoster"}/>
        <AddPerson type={"fixtureRoster"}/>
        <SubmitSeasonStaff type={"fixtureRoster"}/>
      </Space>
    </Row>
    <RosterList searchText={searchText} type={"fixtureRoster"} canEdit={canEdit} isEditMode={isEditMode} onColumnChanged={onColumnChanged} />
  </>;
};