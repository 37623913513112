import {shallow} from "zustand/shallow";
import {useFetchPersons, usePersonsStore} from "../../../entities/person";
import {useCallback, useEffect, useMemo} from "react";
import {useSeasonRosterStore} from "../../../entities/roster/model";
import {PlayerList} from "./PlayerList";
import {isEmpty} from "lodash";
import {useSeasonStaffStore} from "../../../entities/staff";
import {StaffList} from "./StaffList";

const DefaultPageSize = 50;

export const PersonListForSeasonRosterOrStaff = ({type, textToSearch}) => {
  const {
    persons,
    moreDataExists,
    offsetDc,
    offsetDb,
    currentPage,
    searchText,
    isLoading,
    updateOffsets,
    updateCurrentPage,
    updateSearchText
  } = usePersonsStore(store => store, shallow);
  const seasonRoster = useSeasonRosterStore(store => store?.seasonRoster, shallow);
  const seasonStaff = useSeasonStaffStore(store => store?.seasonStaff, shallow);
  const total = (persons || []).length;
  const pagination = {
    total: total,
    current: currentPage,
    showSizeChanger: false,
    showQuickJumper: false,
    itemRender: (_, type, element) => type === "prev" || type === "next" ? element : ""
  };

  const alreadyAddedPersons = useMemo(() => {
    if (type === "seasonRoster") {
      return seasonRoster;
    } else if (type === "seasonStaff") {
      return seasonStaff;
    }
    return [];
  }, [type, seasonRoster, seasonStaff]);

  useEffect(() => {
    updateSearchText(textToSearch ?? "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textToSearch]);

  useFetchPersons({
    searchText: searchText,
    offsetDc: offsetDc,
    offsetDb: offsetDb,
    limit: DefaultPageSize + DefaultPageSize
  });

  const onTableChangedHandler = useCallback((pagination) => {

    updateCurrentPage(pagination.current);

    const isLastPage = pagination.current === (pagination.total / pagination.pageSize);
    if (isLastPage && moreDataExists) {
      updateOffsets(
        (persons || [])?.filter(x => !isEmpty(x.dcPersonId)).length,
        (persons || []).filter(x => !isEmpty(x.personId)).length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persons, moreDataExists, updateOffsets]);

  if (type === "seasonRoster") {
    return <PlayerList type={type}
                       persons={persons}
                       isLoading={isLoading}
                       alreadyAddedPersons={alreadyAddedPersons}
                       pagination={pagination}
                       onTableChanged={onTableChangedHandler}/>
  } else if (type === "seasonStaff") {
    return <StaffList type={type}
                      persons={persons}
                      isLoading={isLoading}
                      alreadyAddedPersons={alreadyAddedPersons}
                      pagination={pagination}
                      onTableChanged={onTableChangedHandler}/>
  } else {
    return null;
  }
};