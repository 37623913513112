import { useApi } from "../../../shared/api"
import { useParams } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useTeamsStore } from "../model"
import { notification } from "antd"
import { useIntl } from "react-intl"

export const useTeamsQuery = () => {
  const { fetchData } = useApi()
  const { sport, organizationId } = useParams()

  return useQuery({
    queryKey: ["teams", organizationId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities`)
  })
}

export const useTeamQuery = () => {
  const { fetchData } = useApi()
  const { sport, organizationId, teamId } = useParams()
  const setActiveTeam = useTeamsStore(store => store.setActiveTeam)

  return useQuery({
    queryKey: ["team", teamId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}`),
    onSuccess: data => {
      setActiveTeam(data?.data)
    }
  })
}

export const useUpdateTeamMutation = () => {
  const { mutateData } = useApi()
  const { sport, organizationId, teamId } = useParams()
  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()

  return useMutation({
    mutationFn: (data) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}`,
        method: "PUT",
        data: data
      })
    },
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: formatMessage({
          id: "team.update.success",
          defaultMessage: "Team updated successfully"
        })
      });
      queryClient.invalidateQueries({ queryKey: ["team", teamId] })
      queryClient.invalidateQueries({ queryKey: ["teams", organizationId] })
    },
    onError: (error) => {
      notification.error({
        message: formatMessage({
          id: "team.update.failed",
          defaultMessage: "Update team has failed",
        }),
        description: error?.message,
        placement: "bottomRight"
      })
      console.error(error?.message, "onError")
    }
  })
}