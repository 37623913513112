import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useFixturesStore = create(devtools((set) => ({
  fixtures: [],
  fixturesByOrganization: [],
  fixture: null,
  setFixtures: data => {
    set({
      fixtures: data,
    });
  },
  setFixture: data => {
    set({
      fixture: data,
    });
  },
})), { name: "fixtures" });