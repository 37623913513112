import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useRosterPositionsStore = create(devtools(() => ({
  basketball: [
    "PG",
    "G",
    "SG",
    "PF",
    "SF",
    "F",
    "C",
    "FC",
    "GF",
  ],
  hockey: [
    "GK",
    "O",
    "D",
    "FB",
    "HB",
    "SW",
    "M",
    "CH",
    "DM",
    "AM",
    "F",
    "CF",
    "LF",
    "RF",
    "S",
  ],
})), { name: "rosterPositions" });