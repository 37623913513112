import { Link } from "react-router-dom";
import { Button, Result } from "antd";
import { useCustomersStore } from "../../../entities/customer/model";
import { shallow } from "zustand/shallow";

export const Page403 = () => {
  const redirectLinkForErrorPages = useCustomersStore(store => store?.redirectLinkForErrorPages, shallow);

  return <Result
    status="error"
    title="403"
    subTitle="The current user has no permissions to perform this operation"
    extra={<Link to={redirectLinkForErrorPages}><Button type="primary">Back Home</Button></Link>}
  />;
};