import { Select } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// TODO: remove useOrganizationsStore and use useQuery directly
import { useOrganizationsStore } from "../../../entities/organization/model";
import { useRole } from "~shared/hooks";
import { ROLES } from "~shared/types";

export const ChangeUserRole = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { customerId, organizationId, sport } = useParams();
  const { isAdmin, isTeamManager } = useRole();
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const organizations = useOrganizationsStore((store: any) => store?.organizations);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (organizations?.filter((org: any) => org?.organizationId === organizationId)?.[0]?.availableUserRoles?.length <= 1) return null;

  if (!sport) return null;
  if (pathname.endsWith("/role")) return null;

  return (
    <>
      <i className="fa-solid fa-users-gear" style={{ marginRight: 5 }} />
      <Select
        popupMatchSelectWidth={false}
        value={isAdmin ? ROLES.ADMIN : ROLES.TEAM_MANAGER}
        onChange={(value) => {
          if (value === ROLES.ADMIN) {
            navigate(`/${customerId}/${organizationId}/${sport}/admin`);
          } else if (value === ROLES.TEAM_MANAGER) {
            navigate(`/${customerId}/${organizationId}/${sport}/teams`);
          }
        }}
        options={[
          {
            label: "Admin",
            value: ROLES.ADMIN,
            disabled: isAdmin,
          },
          {
            label: "Team Manager",
            value: ROLES.TEAM_MANAGER,
            disabled: isTeamManager
          },
        ]}/>
    </>
  );
};
