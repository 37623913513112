import { Button, Col, ColorPicker, Divider, Form, Input, Row, Select, Space, Spin, Typography } from "antd";
import { cloneDeep } from "lodash";
import { useTeamsStore } from "../model";
import { useIntl } from "react-intl";
import { useEditTeamFieldsList } from "../model";
import { useUpdateTeamMutation } from "../api";
import { ClubLogoBadge } from "../../../shared";
import { useClubsStore } from "../../club";
import { useVenuesStore } from "../../venues";
import { useRole } from '~shared/hooks';

export const EditForm = () => {
  const activeTeam = useTeamsStore(state => state?.activeTeam);
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const FIELDS_LIST = useEditTeamFieldsList();
  const { isLoading, mutate } = useUpdateTeamMutation();
  const clubs = useClubsStore(state => state?.clubs);
  const venues = useVenuesStore(state => state?.venues);
  
  const { isTeamManager } = useRole();

  if (!activeTeam) return <Spin/>;

  const handleUpdate = async () => {
    try {
      const res = await form.validateFields();
      const copyData = cloneDeep(res);

      //convert colors to HEX
      for (let key in copyData.colors) {
        if (copyData.colors[key] && typeof copyData.colors[key] !== "string") {
          copyData.colors[key] = copyData.colors[key].toHex();
        }
      }

      mutate(copyData);
    } catch (e) {
      console.error(e);
    }
  };

  return <>
    <Col xxl={19} xl={17} lg={15}>
      <Form form={form} layout="vertical" initialValues={activeTeam}>
        <Typography.Text type="secondary" strong>{formatMessage({
          id: "team.details",
          defaultMessage: "Team Details",
        })}</Typography.Text>
        <Divider className="mt-5" />
        <Row gutter={20}>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "local.full.name",
              defaultMessage: "Local Full Name",
            })} name="nameFullLocal" rules={[{
              required: true,
              message: "Please input Local Full Name",
            },
            ]}>
              <Input disabled={isTeamManager} />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item
              rules={[
                {
                  max: 5,
                },
              ]}
              label={formatMessage({
                id: "local.code",
                defaultMessage: "Local Code",
              })} name="codeLocal">
              <Input disabled={isTeamManager} />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "latin.full.name",
              defaultMessage: "Latin Full Name",
            })} name="nameFullLatin">
              <Input disabled={isTeamManager} />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item
              rules={[
                {
                  max: 5,
                },
              ]}
              label={formatMessage({
                id: "latin.code",
                defaultMessage: "Latin Code",
              })} name="codeLatin">
              <Input disabled={isTeamManager} />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "gender",
              defaultMessage: "Gender",
            })} name={"gender"}>
              <Select
                showSearch
                options={FIELDS_LIST?.gender?.map(gender => {
                  return {
                    value: gender,
                    label: formatMessage({
                      id: "gender." + gender,
                      defaultMessage: gender,
                    }),
                  };
                })}
                disabled={isTeamManager}
              />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "standard",
              defaultMessage: "Standard",
            })} name={"standard"}>
              <Select
                showSearch
                options={FIELDS_LIST?.standard?.map(standard => {
                  return {
                    value: standard,
                    label: formatMessage({
                      id: "standard." + standard,
                      defaultMessage: standard,
                    }),
                  };
                })}
                disabled={isTeamManager}
              />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "status",
              defaultMessage: "Status",
            })} name={"status"} rules={[{
              required: true,
              message: "Please input Status",
            },
            ]}>
              <Select
                showSearch
                options={FIELDS_LIST?.status?.map(status => {
                  return {
                    value: status,
                    label: formatMessage({
                      id: "status." + status,
                      defaultMessage: status,
                    }),
                  };
                })}
                disabled={isTeamManager}
              />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "club",
              defaultMessage: "Club",
            })} name={"entityGroupId"}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                optionRender={({ data }) => {
                  return <Space>
                    <ClubLogoBadge img={data?.img}/>
                    {data?.label}
                  </Space>;
                }}
                options={clubs?.map(club => {
                  return {
                    value: club?.entityGroupId,
                    label: club?.nameFullLocal,
                    img: club?.images?.filter(img => img?.imageType === "LOGO")?.[0]?.url,
                  };
                })}
                disabled={isTeamManager}
              />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "international.reference",
              defaultMessage: "International Reference",
            })} name={"internationalReference"}>
              <Input disabled={isTeamManager} />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "default.venue",
              defaultMessage: "Default Venue",
            })} name={"defaultVenueId"}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                options={venues?.map(venue => {
                  return {
                    value: venue?.venueId,
                    label: venue?.nameLocal,
                  };
                })}
                disabled={isTeamManager}
              />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item
              label={formatMessage({
                id: "age.group",
                defaultMessage: "Age Group"
              })}
              name="ageGroup"
            >
              <Select
                showSearch
                disabled={isTeamManager}
                options={FIELDS_LIST?.ageGroup?.map(ageGroup => ({
                  value: ageGroup,
                  label: formatMessage({
                    id: "age.group." + ageGroup,
                    defaultMessage: ageGroup
                  })
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Typography.Text type={"secondary"} strong>{formatMessage({
          id: "colours",
          defaultMessage: "Colours",
        })}</Typography.Text>
        <Divider className={"mt-5"}/>
        <Row>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "primary",
              defaultMessage: "Primary",
            })} name={["colors", "primary"]}>
              <ColorPicker/>
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "secondary",
              defaultMessage: "Secondary",
            })} name={["colors", "secondary"]}>
              <ColorPicker/>
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={formatMessage({
              id: "tertiary",
              defaultMessage: "Tertiary",
            })} name={["colors", "tertiary"]}>
              <ColorPicker/>
            </Form.Item>
          </Col>
        </Row>
        <Typography.Text type={"secondary"} strong>{formatMessage({
          id: "social.details",
          defaultMessage: "Social Details",
        })}</Typography.Text>
        <Divider className={"mt-5"}/>
        <Row gutter={20}>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={<span><i className="fa-brands fa-facebook"/> {formatMessage({
              id: "facebook",
              defaultMessage: "Facebook",
            })}</span>}
                       name={["social", "facebook"]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={<span><i className="fa-brands fa-instagram"/> {formatMessage({
              id: "instagram",
              defaultMessage: "Instagram",
            })}</span>}
                       name={["social", "instagram"]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={<span><i className="fa-brands fa-twitter"/> {formatMessage({
              id: "twitter",
              defaultMessage: "Twitter",
            })}</span>}
                       name={["social", "twitter"]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={<span><i className="fa-solid fa-globe"/> {formatMessage({
              id: "website",
              defaultMessage: "Website",
            })}</span>} name={["social", "website"]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Form.Item label={<span><i className="fa-brands fa-wikipedia-w"/> {formatMessage({
              id: "wikipedia",
              defaultMessage: "Wikipedia",
            })}</span>}
                       name={["social", "wikipedia"]}>
              <Input/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row justify={"center"}>
        <Button loading={isLoading}
                onClick={handleUpdate}
                size={"large"}
                className={"text-upper"}
                type={"primary"}>
          {formatMessage({
            id: "update",
            defaultMessage: "Update",
          })}
        </Button>
      </Row>
    </Col>
  </>;
};