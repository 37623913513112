import { useApi } from "../../../shared/api";
import { useQuery } from "@tanstack/react-query";
import { useOrganizationsStore } from "../model";

/**
 * TODO: this looks very bad, useQuery is already a state management solution, duplicating state is a bad idea.
 * The idea is simple - state should be as single source of truth, not duplicated.
 * Remove onSuccess => setOrganizations and use from useQuery directly.
 * */
export const useFetchOrganization = () => {
  const { fetchData } = useApi();
  const setOrganizations = useOrganizationsStore(state => state?.set);

  return useQuery({
    queryKey: [],
    queryFn: () => fetchData(`/common/organizations`),
    onSuccess: (data) => {
      setOrganizations(data?.data);
    },
  });
};