import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {useApi} from "~shared/api";

const useFetchUniformItems = (uniformId?: string) => {
  const {fetchData} = useApi();
  const {organizationId, sport, teamId} = useParams();

  const fn = async () => {

    if (uniformId) {
      const url = `/${sport}/o/${organizationId}/entities/${teamId}/uniforms/${uniformId}/items`;
      const response = await fetchData(url);
      return response.data || [];
    }

    return [];
  };

  return useQuery({
    queryKey: ["uniformItems", sport, organizationId, teamId, uniformId],
    queryFn: () => fn()
  });
}

export default useFetchUniformItems;
