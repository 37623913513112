import {Modal} from "antd";
import { useIntl } from "react-intl";
import { Uniforms } from "../../../features/Uniforms";

type Props = {
  open: boolean;
  closeModal: () => void;
};

export const UniformModal = ({ open, closeModal }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      className="UniformModal"
      open={open}
      footer={false}
      onCancel={closeModal}
      destroyOnClose
      title={formatMessage({ id: "uniforms.manage" })}
      width="70%"
    >
        <Uniforms />
    </Modal>
  );
};
