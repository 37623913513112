import { Pagination } from "antd"

export const TablePagination = ({ changePagination, total, tableParams }) => {
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return originalElement
    }
    if (type === "next") {
      return originalElement
    }
    return null
  }

  const handleChange = (page) => {
    changePagination()(prev => {
      return { ...prev, current: page }
    })
  }

  return <Pagination onChange={handleChange}
                     pageSize={tableParams?.pageSize}
                     total={total}
                     current={tableParams?.current}
                     itemRender={itemRender}
    // size={"small"}
  />
}