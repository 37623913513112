import {create} from "zustand"
import {devtools} from "zustand/middleware"
import {uniqWith} from "lodash";

export const usePersonsStore = create(devtools((set) => ({
  persons: [],
  moreDataExists: false,
  offsetDc: 0,
  offsetDb: 0,
  currentPage: 1,
  searchText: "",
  isLoading: false,
  updateSearchText: (searchText) => {
    set(() => ({
      persons: [],
      moreDataExists: false,
      searchText: searchText,
      offsetDc: 0,
      offsetDb: 0,
      currentPage: 1,
      isLoading: true
    }))
  },
  updateCurrentPage: (currentPage) => {
    set(() => ({
      currentPage: currentPage
    }))
  },
  updateOffsets: (offsetDc, offsetDb) => {
    set(() => ({
      offsetDc: offsetDc,
      offsetDb: offsetDb,
      isLoading: true
    }))
  },
  updatePersons: (persons, moreDataExists) => {
    set((state) => ({
      persons: uniqWith(
        state.persons.concat((persons || [])),
        (a, b) => a?.dcPersonId === b?.dcPersonId && a?.personId === b?.personId),
      moreDataExists: moreDataExists,
      isLoading: false
    }))
  },
})), {name: "persons"});