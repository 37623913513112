import {useIntl} from "react-intl"
import {Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Typography} from "antd"
import {useEffect} from "react"
import {useCreatePersonFieldsList} from "../../../features/AddPerson/model/useCreatePersonFieldsList";

export const CreatePersonForm = ({setFormRef}) => {
  const {formatMessage} = useIntl()
  const [form] = Form.useForm()
  const FIELD_LIST = useCreatePersonFieldsList()

  useEffect(() => {
    setFormRef(form)
  }, [form, setFormRef]);

  return <Form form={form} layout="vertical" initialValues={{gender: "MALE", status: "ACTIVE"}}>
    <Row>
      <Col span={19}>
        <Typography.Text type={"secondary"} strong>{formatMessage({id: "personal.details"})}</Typography.Text>
        <Divider className={"mt-5"}/>
        <Row gutter={40}>
          <Col span={8}>
            <Form.Item label={formatMessage({id: "local.full.name"})} name={"nameFullLocal"} rules={[{required: true}]}>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={formatMessage({id: "latin.full.name"})} name={"nameFullLatin"}>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={formatMessage({id: "name.abbreviated"})} name={"abbreviatedName"}>
              <Input/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={formatMessage({id: "date.of.birth"})} name={"dateOfBirth"}>
              <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={formatMessage({id: "nationality"})} name={"nationality"}>
              <Select
                showSearch
                options={FIELD_LIST?.nationality?.map(country => {
                  return {
                    value: country?.[0],
                    label: country?.[0] ? `${country?.[1]} - (${country?.[0]})` : "-"
                  }
                })}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item rules={[{required: true}]} label={formatMessage({id: "gender"})} name={"gender"}>
              <Select showSearch
                      options={FIELD_LIST?.gender?.map(gender => {
                        return {
                          value: gender,
                          label: formatMessage({
                            id: "gender." + gender,
                            defaultMessage: gender
                          })
                        }
                      })}/>
            </Form.Item>
          </Col>
        </Row>

        <Typography.Text type={"secondary"} strong>{formatMessage({id: "additional.details"})}</Typography.Text>
        <Divider className={"mt-5"}/>
        <Row gutter={40}>
          <Col span={8}>
            <Form.Item label={formatMessage({id: "height.in.cm"})} name={"height"}>
              <InputNumber style={{width: "100%"}}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={formatMessage({id: "weight.in.kg"})} name={"weight"}>
              <InputNumber style={{width: "100%"}}/>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </Form>
}