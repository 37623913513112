import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useSeasonsStore = create(devtools((set) => ({
  activeSeason: null,
  seasons: [],
  activeSeasons: [],
  upcomingSeasons: [],
  seasonsByOrganization: [],
  seasonInFixtureList: [],
  setSeasonInFixtureList: data => {
    set({
      seasonInFixtureList: data,
    });
  },
  setActiveSeasons: data => {
    set({
      activeSeasons: data,
    });
  },
  setUpcomingSeasons: data => {
    set({
      upcomingSeasons: data,
    });
  },
  setActiveSeason: data => {
    set({
      activeSeason: data,
    });
  },
  setSeasons: data => {
    set({
      seasons: data,
    });
  },
  setSeasonsByOrganization: data => {
    set({
      seasonsByOrganization: data,
    });
  },
  taskStatusListForSubmit: ["DRAFT", "REJECTED"],
})), { name: "seasons" });