import { Modal, Space, Typography } from "antd";
import { useDeleteFixtureTask, useDeleteSeasonTask } from "../../../entities/task";
import { useEffect } from "react";

export const ConfirmModal = ({ open, handleClose, type, entity }) => {
  const {
    mutate: deleteSeasonTask,
    isLoading: isLoadingSeasonTask,
    isSuccess: isSuccessSeasonTask,
  } = useDeleteSeasonTask();
  const {
    mutate: deleteFixtureTask,
    isLoading: isLoadingFixtureTask,
    isSuccess: isSuccessFixtureTask,
  } = useDeleteFixtureTask();

  useEffect(() => {
    if (isSuccessSeasonTask || isSuccessFixtureTask) {
      handleClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSeasonTask, isSuccessFixtureTask]);

  const handleDelete = () => {
    if (type === "season") {
      deleteSeasonTask({ teamId: entity?.entityId, seasonId: entity?.seasonId });
    }

    if (type === "fixture") {
      deleteFixtureTask({ teamId: entity?.taskEntityId, seasonId: entity?.seasonId, fixtureId: entity?.fixtureId });
    }
  };

  return <Modal title="Delete task"
                open={open}
                closable
                onCancel={handleClose}
                okText="Delete"
                onOk={handleDelete}
                okButtonProps={{
                  danger: true,
                  loading: isLoadingSeasonTask || isLoadingFixtureTask,
                }}
  >
    <Space direction={"vertical"}>
      {type === "season" ? <Typography.Text>Are you sure you want to delete task for season?</Typography.Text> :
        <Typography.Text>Are you sure you want to delete task for fixture?</Typography.Text>}
      <Typography.Text>Note: all not published roster/staff will be deleted as well.</Typography.Text>
    </Space>
  </Modal>;
};