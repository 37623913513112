import { BackToButton } from "../../../shared/ui"
import { Divider, Row, Typography } from "antd"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { AdminSeasonList } from "../../../entities/season/ui"
import { AdminFixtureList } from "../../../entities/fixtures"

export const AdminDashboard = () => {
  const { customerId } = useParams()
  const { formatMessage } = useIntl()

  return <>
    <Row justify={"space-between"} className={"mt-10"} align={"middle"}>
      <Typography.Title className={"text-upper"} level={3}>{formatMessage({
        id: "team.manager.admin.view",
        defaultMessage: "Team manager - Admin view"
      })}</Typography.Title>
      <BackToButton to={`/${customerId}`} btnText={"Back to organizations"}/>
    </Row>
    <Divider/>
    <AdminSeasonList/>
    <AdminFixtureList/>
  </>
}