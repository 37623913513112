import { create } from "zustand"
import { devtools } from "zustand/middleware"

export const useSeasonRosterStore = create(devtools((set) => ({
  seasonRoster: [],
  seasonRosterPublished: [],
  setSeasonsRoster: data => {
    set({
      seasonRoster: data
    })
  },
  setSeasonsRosterPublished: data => {
    set({
      seasonRosterPublished: data
    })
  }
})), { name: "seasonRoster" });