import { useFetchSeasonTask, useTaskStore } from "../../entities/task";
import { Spin, Tag, Tooltip } from "antd";

export const TaskStatusTagForSeason = () => {
  const { isLoading } = useFetchSeasonTask();
  const task = useTaskStore(store => store?.task);

  return <Tooltip title={task?.historyRecords?.[task?.historyRecords?.length - 1]?.text}>
    <Tag color={"red"} style={{ fontSize: "16px" }} className={"mt-5"}>
      REJECTED {isLoading ? <Spin size={"small"}/> : <i className="fa-regular fa-circle-question ml-5"/>}
    </Tag>
  </Tooltip>;
};