import { Button, Row, Select, Space, Table, theme, Typography } from "antd";
import { useFetchFixturesByOrganization } from "../api";
import { useIntl } from "react-intl";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { useCallback, useState } from "react";
import { CompetitionLogoBadge, SeasonLogoBadge, TablePagination, TaskStatusTag, TeamLogoBadge } from "../../../shared";
import { DeleteTask } from "../../../features/DeleteTask";
import { useFetchSeasonsInFixtureList, useSeasonsStore } from "../../season";
import {isEmpty} from "lodash";

const { useToken } = theme;

const FilterOptions = [{
  label: "Default view", value: "draft,submitted,review,rejected,approved,publishing",
}, {
  label: "Draft", value: "draft",
}, {
  label: "Submitted", value: "submitted",
}, {
  label: "Review", value: "review",
}, {
  label: "Rejected", value: "rejected",
}, {
  label: "Approved", value: "approved",
}, {
  label: "Publishing", value: "publishing",
}, {
  label: "Published", value: "published",
}];

export const AdminFixtureList = () => {
  const { token } = useToken();
  const { formatMessage } = useIntl();
  const [filterValue, setFilterValue] = useState("draft,submitted,review,rejected,approved,publishing");
  const [selectSeason, setSelectSeason] = useState("ALL");
  const [tableParams, setTableParams] = useState({
    current: 1, pageSize: 10,
  });
  const { isLoading: isLoadingSeasons } = useFetchSeasonsInFixtureList({
    taskStatus: filterValue,
  });

  const { isLoading, data } = useFetchFixturesByOrganization({
    taskStatus: filterValue,
    page: tableParams?.current,
    pageSize: tableParams?.pageSize,
    seasonId: selectSeason === "ALL" ? null : selectSeason,
  });

  const seasonInFixtureList = useSeasonsStore(store => store?.seasonInFixtureList);

  const handleChangePagination = useCallback(() => {
    return setTableParams;
  }, []);

  const handleChangeFilter = (value) => {
    setFilterValue(value);
    setSelectSeason("ALL");
    setTableParams({
      current: 1, pageSize: 10,
    });
  };

  const handleChangeSeason = (value) => {
    setSelectSeason(value);
  };

  return <>
    <Row justify={"space-between"} className={"mb-15"} align={"middle"}>
      <Typography.Text>Fixtures Roster & Staff</Typography.Text>
      <Space>
        <Space>
          <Typography.Text>Seasons</Typography.Text>
          <Select placeholder={"Select season"} popupMatchSelectWidth={false}
                  onChange={handleChangeSeason}
                  showSearch
                  loading={isLoadingSeasons}
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                  options={[{
                    label: "All seasons", value: "ALL",
                  }, ...seasonInFixtureList?.map(item => {
                    return {
                      label: item?.nameLocal, value: item?.seasonId,
                    };
                  }) ?? []]}/>
        </Space>
        <Space>
          <Typography.Text>Task status</Typography.Text>
          <Select defaultValue={filterValue} onChange={handleChangeFilter} options={FilterOptions}
                  popupMatchSelectWidth={false}/>
        </Space>
      </Space>
    </Row>
    <Table dataSource={data?.data}
           size={"small"}
           pagination={false}
           rowKey={(record) => record?.taskEntityId + record?.fixtureId}
           loading={isLoading}
           columns={[{
             title: formatMessage({
               id: "competition.name", defaultMessage: "Competition Name",
             }), dataIndex: "competitionNameLocal", render: (competitionNameLocal, record) => <Space>
               <CompetitionLogoBadge img={record?.competitionImageUrl}/>
               {competitionNameLocal}
             </Space>,
           }, {
             title: formatMessage({
               id: "season.name", defaultMessage: "Season Name",
             }), dataIndex: "seasonNameLocal", render: (seasonNameLocal, record) => <Space>
               <SeasonLogoBadge img={record?.seasonImageUrl}/>
               {seasonNameLocal}
             </Space>,
           }, {
             title: formatMessage({
               id: "team.name", defaultMessage: "Team name",
             }), dataIndex: "taskEntityId", render: (taskEntityId, record) => {
               return record?.homeEntity?.entityId === taskEntityId ? <Space>
                 {!isEmpty(record?.homeEntity?.nameLocal) && <TeamLogoBadge img={record?.homeEntity?.imageLogoUrl}/>}
                 {record?.homeEntity?.nameLocal}
               </Space> : <Space>
                 {!isEmpty(record?.awayEntity?.nameLocal) && <TeamLogoBadge img={record?.awayEntity?.imageLogoUrl}/>}
                 {record?.awayEntity?.nameLocal}
               </Space>;
             },
           }, {
             title: formatMessage({
               id: "opposition", defaultMessage: "Opposition",
             }), dataIndex: "taskEntityId", render: (taskEntityId, record) => {
               return record?.homeEntity?.entityId !== taskEntityId ? <Space>
                 {!isEmpty(record?.homeEntity?.nameLocal) && <TeamLogoBadge img={record?.homeEntity?.imageLogoUrl}/>}
                 {record?.homeEntity?.nameLocal}
               </Space> : <Space>
                 {!isEmpty(record?.awayEntity?.nameLocal) && <TeamLogoBadge img={record?.awayEntity?.imageLogoUrl}/>}
                 {record?.awayEntity?.nameLocal}
               </Space>;
             },
           }, {
             dataIndex: "Round",
           }, {
             title: "Venue", dataIndex: "venue", render: venue => venue?.nameLocal,
           }, {
             title: formatMessage({
               id: "date", defaultMessage: "Date",
             }),
             dataIndex: "startDateTimeLocal",
             render: startDate => moment(startDate).format("DD/MM/YYYY"),
             align: "center",
           }, {
             title: formatMessage({
               id: "start.time", defaultMessage: "Start Time",
             }),
             dataIndex: "startDateTimeLocal",
             align: "center",
             render: (startDateTimeLocal) => moment(startDateTimeLocal).format("HH:mm"),
           }, {
             title: "Stage", dataIndex: "stage", align: "center", render: (stage) => stage?.nameLocal,
           }, {
             title: "Pool", dataIndex: "pool", align: "center", render: (pool) => pool?.nameLocal,
           }, {
             title: "Series", dataIndex: "series", align: "center", render: (series) => series?.nameLocal,
           }, {
             title: formatMessage({
               id: "registration.open", defaultMessage: "Registration Open?",
             }),
             dataIndex: "isRegistrationOpened",
             render: (isRegistrationOpened) => isRegistrationOpened ? <i style={{ color: token.colorSuccess }}
                                                                         className={"fa-solid fa-check"}></i> : <i
               style={{ color: token.colorError }} className="fa-solid fa-xmark"></i>,
             align: "center",
           }, {
             title: formatMessage({
               id: "status", defaultMessage: "Status",
             }), dataIndex: "taskStatus", align: "center", width: 1, render: (taskStatus, record) => <TaskStatusTag
               rejectReason={record?.taskHistoryRecords[record?.taskHistoryRecords?.length - 1]?.text}
               status={taskStatus}/>,
           }, {
             render: (value, record) => <Space>
               <Link
                 to={`teams/${record?.taskEntityId}/seasons/${record?.seasonId}/fixtures/${record?.fixtureId}/view`}
               >
                 <Button className={"text-upper"} type={"primary"}>view roster &
                   staff</Button>
               </Link>
               <DeleteTask entity={record} type={"fixture"}/>
             </Space>, width: 1,
           }]}
           footer={() => <Row justify={"end"}>
             <TablePagination tableParams={tableParams} total={data?.meta?.count}
                              changePagination={handleChangePagination}/>
           </Row>}
    />
  </>;
};