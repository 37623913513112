import { useApi } from "../../../shared/api"
import { useParams } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { notification } from "antd"

export const useSubmitSeasonTask = () => {
  const { mutateData } = useApi()
  const { organizationId, sport, teamId, seasonId } = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/task/submit`,
        method: "PATCH",
        data: {}
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["seasons", teamId, seasonId] })
      queryClient.invalidateQueries({ queryKey: ["seasons", teamId] })
      notification.success({
        placement: "bottomRight",
        message: "Submit roster/staff successfully"
      })
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Submit roster/staff has failed",
        description: error?.message
      })
    }
  })
}

export const useRejectSeasonTask = () => {
  const { mutateData } = useApi()
  const { organizationId, sport, teamId, seasonId } = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/task/reject`,
        method: "PATCH",
        data: data
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["task", teamId, seasonId] })
      queryClient.invalidateQueries({ queryKey: ["seasons", teamId, seasonId] })
      queryClient.invalidateQueries({ queryKey: ["seasons", teamId] })
      notification.success({
        placement: "bottomRight",
        message: "Reject roster/staff successfully"
      })
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Reject roster/staff has failed",
        description: error?.message
      })
    }
  })
}

export const useReviewSeasonTask = () => {
  const { mutateData } = useApi()
  const { organizationId, sport, teamId, seasonId } = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/task/review`,
        method: "PATCH",
        data: {}
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["task", teamId, seasonId] })
      queryClient.invalidateQueries({ queryKey: ["seasons", teamId, seasonId] })
      queryClient.invalidateQueries({ queryKey: ["seasons", teamId] })
      notification.success({
        placement: "bottomRight",
        message: "Review roster/staff successfully"
      })
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Review roster/staff has failed",
        description: error?.message
      })
    }
  })
}