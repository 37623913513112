import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCustomersStore } from "../../entities/customer/model";
import { shallow } from "zustand/shallow";
import { useRole } from "~shared/hooks";

export const useGenerateRedirectLink = () => {
  const { customerId, organizationId, sport } = useParams();
  const { isAdmin, isTeamManager } = useRole();
  const setRedirectLinkForErrorPages = useCustomersStore(store => store?.setRedirectLinkForErrorPages, shallow);
  const [redirectLink, setRedirectLink] = useState("/");

  useEffect(() => {
    setRedirectLinkForErrorPages(redirectLink);
  }, [redirectLink, setRedirectLinkForErrorPages]);

  const generateLink = useCallback(() => {
    if (isAdmin) {
      if (customerId) {
        setRedirectLink(`/${customerId}`);

        if (organizationId) {
          setRedirectLink(`/${customerId}/${organizationId}/${sport}/admin`);
        }
      } else {
        setRedirectLink(`/`);
      }
    }
    
    if (isTeamManager) {
      if (customerId) {
        setRedirectLink(`/${customerId}`);

        if (organizationId) {
          setRedirectLink(`/${customerId}/${organizationId}/${sport}/teams`);
        }
      } else {
        setRedirectLink(`/`);
      }
    }
  }, [isAdmin, isTeamManager, customerId, organizationId, sport]);

  useEffect(() => {
    generateLink();
  }, [generateLink]);

  return redirectLink;
};