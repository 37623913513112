import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {useApi} from "~shared/api";

export const useFetchUniforms = () => {
  const {fetchData} = useApi();
  const {organizationId, sport, teamId} = useParams();

  const fn = async () => {
    const url = `/${sport}/o/${organizationId}/entities/${teamId}/uniforms?sortBy=nameLocal`;
    const response = await fetchData(url);
    return response.data || [];
  };

  return useQuery({
    queryKey: ["uniforms", sport, organizationId, teamId],
    queryFn: () => fn()
  });
}
