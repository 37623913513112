import {useApi} from "../../../shared/api"
import {useParams} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {notification} from "antd"
import {useSeasonStaffStore} from "../model"
import {useIntl} from "react-intl"
import { sortStaff } from "~shared/common"

export const useFetchSeasonStaff = (props) => {
  let enabled = props?.enabled

  if (typeof enabled !== "boolean") {
    enabled = true
  }

  const {fetchData} = useApi()
  const {organizationId, sport, teamId, seasonId} = useParams()
  const setSeasonStaff = useSeasonStaffStore(state => state?.setSeasonStaff)

  return useQuery({
    queryKey: ["seasonStaff", sport, organizationId, teamId, seasonId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/staff`),
    enabled: enabled,
    onSuccess: data => {
      setSeasonStaff(sortStaff(data?.data))
    },
    onError: err => {
      console.error("onError", err)
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch season staff",
        description: err?.message
      })
    }
  })
}

export const useFetchPublishedSeasonStaff = (seasonId) => {
  const {fetchData} = useApi()
  const {organizationId, sport, teamId} = useParams()
  const setSeasonStaffPublished = useSeasonStaffStore(store => store?.setSeasonStaffPublished)

  return useQuery({
    queryKey: ["seasonStaff", "published", sport, organizationId, teamId, seasonId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/staff/published`),
    enabled: false,
    onSuccess: (data) => {
      setSeasonStaffPublished(data?.data || [])
    },
    onError: err => {
      console.error("onError", err)
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch season staff",
        description: err?.message
      })
    }
  })
}

export const useDeleteSeasonStaff = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId} = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/staff`,
        method: "DELETE",
        data: data
      })
    },
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Person deleted successfully"
      })
      queryClient.invalidateQueries({queryKey: ["seasonStaff", sport, organizationId, teamId, seasonId]})
    },
    onError: error => {
      notification.error({
        placement: "bottomRight",
        message: "Delete player has failed",
        description: error?.message
      })
    }
  })
}

export const useAddPersonsToSeasonStaff = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId} = useParams()
  const {formatMessage} = useIntl()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/staff`,
        method: "POST",
        data
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ["seasonStaff", sport, organizationId, teamId, seasonId]})
      notification.success({
        placement: "bottomRight",
        message: formatMessage({
          id: "person.add.success",
          defaultMessage: "Person added successfully"
        })
      })
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't add persons",
        description: error?.message || ""
      })
    }
  })
}

export const useUpdateSeasonStaffPersonInternal = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId} = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (person) => mutateData({
      url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/staff/${person?.personId}`,
      method: "PUT",
      data: person
    }),
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Person updated successfully"
      })
      queryClient.invalidateQueries({queryKey: ["seasonStaff", sport, organizationId, teamId, seasonId]})
    },
    onError: (err) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't update person",
        description: err?.message
      })
    }
  })
}

export const useUpdateSeasonStaffPersonDC = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId} = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({dcPersonId, ...person}) => mutateData({
      url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/staff/dc/${dcPersonId}`,
      method: "PUT",
      data: person
    }),
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Person updated successfully"
      })
      queryClient.invalidateQueries({queryKey: ["seasonStaff", sport, organizationId, teamId, seasonId]})
    },
    onError: (err) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't update person",
        description: err?.message
      })
    }
  })
}