import { Form, Input, Modal } from "antd"
import { useEffect, useState } from "react"
import { useRejectSeasonTask } from "../../../entities/season"
import { useRejectFixtureTask } from "../../../entities/fixtures"

const { TextArea } = Input

export const RejectModal = ({ open, closeModal, type }) => {
  const {
    mutate: rejectSeasonTask,
    isLoading: isLoadingSeasonTask,
    isSuccess: isSuccessSeasonTask
  } = useRejectSeasonTask()
  const {
    mutate: rejectFixtureTask,
    isLoading: isLoadingFixtureTask,
    isSuccess: isSuccessFixtureTask
  } = useRejectFixtureTask()
  const [modalTitle, setModalTitle] = useState("Reject season Roster & Staff?")
  const [form] = Form.useForm()

  useEffect(() => {
    if (type === "fixtureRoster" || type === "fixtureStaff") {
      setModalTitle("Reject fixture Roster & Staff?")
    }
  }, [type])

  useEffect(() => {
    if (isSuccessSeasonTask || isSuccessFixtureTask) {
      closeModal()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSeasonTask, isSuccessFixtureTask])

  const handleReject = async () => {
    try {
      const data = await form.validateFields()
      switch (type) {
        case "fixtureStaff":
        case "fixtureRoster":
          return rejectFixtureTask(data)
        case "seasonRoster":
        case "seasonStaff":
          return rejectSeasonTask(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return <Modal
    destroyOnClose={true}
    open={open}
    onCancel={closeModal}
    title={modalTitle}
    okButtonProps={{
      danger: true,
      loading: isLoadingSeasonTask || isLoadingFixtureTask
    }}
    okText={"Reject"}
    onOk={handleReject}
  >
    <Form colon={false} form={form}>
      <Form.Item label={"Reject Reason"}
                 name={"comment"}
                 rules={[
                   {
                     required: true,
                     message: "Please input Reject Reason"
                   }
                 ]}>
        <TextArea autoSize={true}></TextArea>
      </Form.Item>
    </Form>
  </Modal>
}