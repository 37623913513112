/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback, useEffect, useState} from "react";
import {Button, ColorPicker, DatePicker, Flex, Form, Input, Space, Spin, Switch, Typography} from "antd";
import {ColorFactory} from "antd/es/color-picker/color";
import {useIntl} from "react-intl";
import {isEmpty} from "lodash";
import dayjs from "dayjs";
import styled from "styled-components";
import {useSportConfiguration} from "~shared/hooks/useSportConfiguration";
import {UniformItemTypes, UniformItemColors} from "~shared/types";
import {
    UniformItemLongSleeveSvg,
    UniformItemShortSleeveSvg,
    UniformItemShortsSvg,
    UniformItemSkirtsSvg,
    UniformItemSleevelessSvg,
    UniformItemSocksSvg
} from "./svgs";
import useFetchUniformItems from "./hooks/useFetchUniformItems";
import {IUniformModel} from "./models/IUniformModel";
import {IUniformItemModel} from "./models/IUniformItemModel";
import {useUpsertUniform} from "./hooks/useUpsertUniform";
import {useDeleteUniform} from "./hooks/useDeleteUniform";
import {useDeleteUniformItem} from "./hooks/useDeleteUniformItem";

const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .UniformContainer[data-loading="yes"] {
        align-items: center;
        justify-content: center;
        display: flex;
        flex: 1;
    }

    .UniformContainer {
        background: #FFFFFF;
        opacity: 1;
        padding: 10px;
        min-width: 700px;
        min-height: 400px;

        gap: 15px;
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: center;

        > svg {
            display: flex;
            flex: 1;
        }
    }
`;
StyledContainer.displayName = 'StyledContainer';

export interface IUniformProperties {
    uniform?: IUniformModel;
    onAdded?: (uniformId: string) => void;
    onDeleted?: () => void;
}

export const Uniform = (properties: IUniformProperties) => {
    const [form] = Form.useForm();
    const {formatMessage} = useIntl();
    const isEdit = !isEmpty(properties.uniform);
    const {uniformItemTypes} = useSportConfiguration();
    const {data: uniformItems, isLoading: isUniformItemsLoading, refetch: refetchUniformItems} = useFetchUniformItems(properties.uniform?.uniformId);
    const {mutateAsync: upsertUniform, isLoading: isUpsertUniformInProgress} = useUpsertUniform();
    const {mutateAsync: deleteUniform, isLoading: isDeletingUniformInProgress} = useDeleteUniform();
    const {mutateAsync: deleteUniformItem, isLoading: isDeletingUniformItemInProgress} = useDeleteUniformItem();
    const [isSkirtUniform, setIsSkirtUniform] = useState<boolean>(false);
    const [isSleevelessUniform, setIsSleevelessUniform] = useState<boolean>(false);
    const [colorValues, setColorValues] = useState<{
        [itemType: string]: {
            primary: string | undefined,
            secondary: string | undefined,
            tertiary: string | undefined,
            lettering: string | undefined
        }
    }>({});

    const initialFormValues = {
        nameLocal: properties.uniform?.nameLocal,
        nameLatin: properties.uniform?.nameLatin,
        dateFrom: isEmpty(properties.uniform?.dateFrom?.split("T")?.[0])
            ? null : dayjs(properties.uniform?.dateFrom?.split("T")?.[0], 'YYYY-MM-DD'),
        dateTo: isEmpty(properties.uniform?.dateTo?.split("T")?.[0])
            ? null : dayjs(properties.uniform?.dateTo?.split("T")?.[0], 'YYYY-MM-DD'),
    };

    const { uniform, onAdded, onDeleted } = properties;
    const uniformId = uniform?.uniformId;

    const onSubmitButtonHandler = useCallback(async () => {
        const values = await form.validateFields();

        if (values.dateFrom) {
            values.dateFrom = values.dateFrom.format('YYYY-MM-DD')
        }
        if (values.dateTo) {
            values.dateTo = values.dateTo.format('YYYY-MM-DD')
        }

        const items: any[] = [];
        if (values.colors) {
            Object.keys(values.colors).forEach(itemType => {
                if (values.colors[itemType]) {

                    const foundUniformItem = uniformItems?.filter((x: IUniformItemModel) => x.itemType === itemType)?.[0];
                    const upsertUniformItem: any = {
                        itemType,
                        uniformItemId: foundUniformItem?.uniformItemId,
                        nameLocal: foundUniformItem?.nameLocal ?? itemType,
                        nameLatin: foundUniformItem?.nameLatin ?? itemType,
                        colors: {}
                    };

                    Object.keys(values.colors[itemType]).forEach(colorType => {
                        const formItemValue = values.colors[itemType][colorType];
                        if (formItemValue) {
                            upsertUniformItem.colors[colorType] = formItemValue.toHex();
                        }
                    });

                    if (Object.keys(upsertUniformItem.colors).length > 0) {
                        items.push(upsertUniformItem);
                    }
                }
            });
        }

        const uniformResponse = await upsertUniform({
            uniformId,
            upsertData: {
                dateFrom: values.dateFrom,
                dateTo: values.dateTo,
                nameLocal: values.nameLocal,
                nameLatin: values.nameLatin,
            },
            items
        });

        const targetUniformId = uniformResponse?.data?.[0]?.uniformId;
        if (targetUniformId && onAdded && !isEdit) {
            onAdded(targetUniformId);
        }

        refetchUniformItems();
    }, [form, isEdit, uniformId, uniformItems, onAdded, upsertUniform, refetchUniformItems]);

    const onValuesChangeHandler = (_: any, allValues: any) => {

        const colors: any = {};

        if (allValues?.colors) {
            Object.keys(allValues.colors).forEach(itemType => {
                colors[itemType] = {};
                if (allValues.colors[itemType]) {
                    Object.keys(allValues.colors[itemType]).forEach(colorType => {
                        const formItemValue = allValues.colors[itemType][colorType];
                        if (formItemValue) {
                            colors[itemType][colorType] = formItemValue.toHex();
                        }
                    });
                }
            });
        }

        setColorValues(colors);
    };

    const onDeleteButtonHandler = useCallback(async () => {
        if (uniform) {
            const deleteItemPromises = (uniformItems ?? [])
                .map((item: IUniformItemModel) => deleteUniformItem(item));
            await Promise.all(deleteItemPromises);
            await deleteUniform(uniform);
        }
        onDeleted?.()
    }, [deleteUniform, deleteUniformItem, uniformItems, uniform, onDeleted]);

    useEffect(() => {
        const colors: any = {};
        const formColors: any = {};
        Object.values(UniformItemTypes).forEach(itemType => {
            const uniformItem = (uniformItems || []).filter((x: IUniformItemModel) => x.itemType === itemType)?.[0];
            if (uniformItem) {
                colors[itemType] = {};
                formColors[itemType] = {};
                Object.values(UniformItemColors).forEach(colorType => {
                    const color = uniformItem.colors?.[colorType];
                    if (color) {
                        colors[itemType][colorType] = color;
                        formColors[itemType][colorType] = new ColorFactory(color);
                    }
                });
            }
        });
        setColorValues(colors);
        form.setFieldsValue({colors: formColors});
    }, [form, uniformItems]);

    const isLoading = isUniformItemsLoading;

    const isMutationDisabled = isUniformItemsLoading || isUpsertUniformInProgress || isDeletingUniformInProgress || isDeletingUniformItemInProgress;

    return (
        <StyledContainer>
            <div className="UniformContainer" data-loading={isLoading ? "yes" : "no"}>
            {isLoading ? (
                <Spin />
            ) : (
            <>
                <Form form={form} key={properties.uniform?.uniformId} labelCol={{span: 12}} wrapperCol={{span: 12}}
                        initialValues={initialFormValues}
                        onValuesChange={onValuesChangeHandler}>
                    <Form.Item wrapperCol={{span: 24}} style={{textAlign: 'center'}}>
                        <Space>
                            <Switch
                                checkedChildren={formatMessage({id: "uniform.skirts"})}
                                unCheckedChildren={formatMessage({id: "uniform.shorts"})}
                                checked={isSkirtUniform}
                                onChange={(value) => setIsSkirtUniform(value)}/>
                            <Switch
                                checkedChildren={formatMessage({id: "uniform.sleeveless"})}
                                unCheckedChildren={formatMessage({id: "uniform.shortSleeve"})}
                                checked={isSleevelessUniform}
                                onChange={(value) => setIsSleevelessUniform(value)}/>
                        </Space>
                    </Form.Item>
                    <Form.Item
                        name={'nameLocal'}
                        label={formatMessage({id: "uniform.nameLocal"})}
                        tooltip={formatMessage({id: 'form.help.local.name.item'}, {item: formatMessage({id: 'uniform'})})}
                        style={{marginBottom: '5px'}}
                        rules={[{max: 150, required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={'nameLatin'}
                        label={formatMessage({id: "uniform.nameLatin"})}
                        tooltip={formatMessage({id: 'form.help.latin.name.item'}, {item: formatMessage({id: 'uniform'})})}
                        style={{marginBottom: '5px'}}
                        rules={[{max: 150}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={"dateFrom"}
                        label={formatMessage({id: "uniform.date.start"})}
                        tooltip={formatMessage({id: "form.help.start.date.item"}, {item: formatMessage({id: 'uniform'})})}
                        style={{marginBottom: '5px'}}>
                        <DatePicker size={'middle'} format={'DD/MM/YYYY'}/>
                    </Form.Item>
                    <Form.Item
                        name={"dateTo"}
                        label={formatMessage({id: "uniform.date.end"})}
                        tooltip={formatMessage({id: "form.help.end.date.item"}, {item: formatMessage({id: 'uniform'})})}
                        style={{marginBottom: '5px'}}>
                        <DatePicker size={'middle'} format={'DD/MM/YYYY'}/>
                    </Form.Item>
                    {Object.keys(uniformItemTypes).map((itemType: string) => {
                        return (
                            <Form.Item key={itemType} style={{marginBottom: '5px'}}
                                        label={formatMessage({id: `uniform.${itemType}`})}>
                                <Space.Compact>
                                    {uniformItemTypes[itemType as UniformItemTypes]?.map((itemColor: UniformItemColors) => {
                                        return (
                                            <Form.Item key={itemColor}
                                                        name={["colors", itemType as UniformItemTypes, itemColor]}
                                                        noStyle>
                                                <ColorPicker defaultFormat="hex" disabledAlpha/>
                                            </Form.Item>
                                        );
                                    })}
                                </Space.Compact>
                            </Form.Item>
                        );
                    })}
                    <Form.Item wrapperCol={{offset: 8, span: 16}} style={{textAlign: 'right'}}>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isUpsertUniformInProgress}
                                onClick={onSubmitButtonHandler}
                                disabled={isMutationDisabled}
                            >
                                {formatMessage({id: isEdit ? "update" : "add"})}
                            </Button>
                            {properties.uniform && (
                                <Button
                                    type="default"    
                                    loading={isDeletingUniformInProgress || isDeletingUniformItemInProgress}
                                    onClick={onDeleteButtonHandler}
                                    disabled={isMutationDisabled}
                                >
                                    {formatMessage({id: "delete"})}
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
                <Flex vertical align={"center"} gap={"small"} style={{minWidth: "210px"}}>
                    <Typography.Title level={5}>{formatMessage({id: "uniform.player.column.name"})}</Typography.Title>
                    {!isSleevelessUniform && (
                        <UniformItemShortSleeveSvg
                            colorPrimary={`#${colorValues?.[UniformItemTypes.Top]?.primary ?? "000000"}`}
                            colorSecondary={`#${colorValues?.[UniformItemTypes.Top]?.secondary ?? "000000"}`}/>
                    )}
                    {isSleevelessUniform && (
                        <UniformItemSleevelessSvg
                            colorPrimary={`#${colorValues?.[UniformItemTypes.Top]?.primary ?? "000000"}`}/>
                    )}
                    {!isSkirtUniform && (
                        <UniformItemShortsSvg
                            colorPrimary={`#${colorValues?.[UniformItemTypes.Bottom]?.primary ?? "000000"}`}/>
                    )}
                    {isSkirtUniform && (
                        <UniformItemSkirtsSvg
                            colorPrimary={`#${colorValues?.[UniformItemTypes.Bottom]?.primary ?? "000000"}`}/>
                    )}
                    <UniformItemSocksSvg colorPrimary={`#${colorValues?.[UniformItemTypes.Socks]?.primary ?? "000000"}`}/>
                </Flex>
                <Flex vertical align={"center"} gap={"small"} style={{minWidth: "210px"}}>
                    <Typography.Title level={5}>{formatMessage({id: "uniform.goalkeeper.column.name"})}</Typography.Title>
                    <UniformItemLongSleeveSvg
                        colorPrimary={`#${colorValues?.[UniformItemTypes.GoalkeeperTop]?.primary ?? "000000"}`}
                        colorSecondary={`#${colorValues?.[UniformItemTypes.GoalkeeperTop]?.secondary ?? "000000"}`}/>
                    {!isSkirtUniform && (
                        <UniformItemShortsSvg
                            colorPrimary={`#${colorValues?.[UniformItemTypes.GoalkeeperBottom]?.primary ?? "000000"}`}/>
                    )}
                    {isSkirtUniform && (
                        <UniformItemSkirtsSvg
                            colorPrimary={`#${colorValues?.[UniformItemTypes.GoalkeeperBottom]?.primary ?? "000000"}`}/>
                    )}
                    <UniformItemSocksSvg colorPrimary={`#${colorValues?.[UniformItemTypes.Socks]?.primary ?? "000000"}`}/>
                </Flex>
            </>
            )}
            </div>
        </StyledContainer>
    )
}
