import {useApi} from "~shared/api";
import {useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {notification} from "antd";
import {useFixtureRosterStore} from "../model";
import {useIntl} from "react-intl";

import { sortRoster } from "~shared/common";

export const useFetchFixtureRoster = (props) => {
  let enabled = props?.enabled;

  if (typeof enabled !== "boolean") {
    enabled = true;
  }

  const {fetchData} = useApi();
  const {seasonId, organizationId, sport, teamId, fixtureId} = useParams();
  const setFixtureRoster = useFixtureRosterStore(store => store?.setFixtureRoster);

  return useQuery({
    queryKey: ["fixtureRoster", sport, organizationId, teamId, seasonId, fixtureId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/roster`),
    enabled: enabled,
    onSuccess: data => {
      setFixtureRoster(sortRoster(data?.data));
    },
    onError: err => {
      console.error("onError", err);
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch fixture staff",
        description: err?.message,
      });
    },
  });
};

export const useFetchPublishedFixtureRoster = (fixtureId) => {
  const {fetchData} = useApi();
  const {organizationId, sport, teamId, seasonId} = useParams();

  return useQuery({
    queryKey: ["fixtureRoster", "published", sport, organizationId, teamId, seasonId, fixtureId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/roster/published`),
    enabled: false,
    onError: err => {
      console.error("onError", err);
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch fixture roster",
        description: err?.message,
      });
    },
  });
};

export const useDeleteFixtureRoster = () => {
  const {mutateData} = useApi();
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams();
  const {formatMessage} = useIntl();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/roster`,
        method: "DELETE",
        data: data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ["fixtureRoster", sport, organizationId, teamId, seasonId, fixtureId]});
      notification.success({
        placement: "bottomRight",
        message: formatMessage({
          id: "person.delete.success",
          defaultMessage: "Person(s) deleted successfully",
        }),
      });
    },
    onError: error => {
      notification.error({
        placement: "bottomRight",
        message: "Delete player has failed",
        description: error?.message,
      });
    },
  });
};

export const useAddPersonsToFixtureRoster = () => {
  const {mutateData} = useApi();
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams();
  const {formatMessage} = useIntl();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/roster`,
        method: "POST",
        data: data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ["fixtureRoster", sport, organizationId, teamId, seasonId, fixtureId]});
      notification.success({
        placement: "bottomRight",
        message: formatMessage({
          id: "person.add.success",
          defaultMessage: "Person added successfully",
        }),
      });
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't add persons",
        description: error?.message || "",
      });
    },
  });
};

export const useUpdateFixtureRosterPlayerInternal = () => {
  const {mutateData} = useApi();
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (person) => mutateData({
      url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/roster/${person?.personId}`,
      method: "PUT",
      data: person,
    }),
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Player updated successfully",
      });
      queryClient.invalidateQueries({queryKey: ["fixtureRoster", sport, organizationId, teamId, seasonId, fixtureId]});
    },
    onError: (err) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't update player",
        description: err?.message,
      });
    },
  });
};

export const useUpdateFixtureRosterPlayerDC = () => {
  const {mutateData} = useApi();
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({dcPersonId, ...person}) => mutateData({
      url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/roster/dc/${dcPersonId}`,
      method: "PUT",
      data: person,
    }),
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Player updated successfully",
      });
      queryClient.invalidateQueries({queryKey: ["fixtureRoster", sport, organizationId, teamId, seasonId, fixtureId]});
    },
    onError: (err) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't update player",
        description: err?.message,
      });
    },
  });
};