import messagesEN from "./en.json";

export const setupLanguage = () => {
  const messages = {
    en: messagesEN,
  }

  const defaultLocale = "en-GB";
  const defaultLanguage = "en";

  return {
    messages: messages[defaultLanguage],
    language: defaultLanguage,
    locale: defaultLocale
  };
}