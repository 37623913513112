import { Row, Space, Typography } from "antd";
import { BackToButton, TaskStatusTag } from "../../../shared";
import { useParams } from "react-router-dom";
import { useFetchSeason, useSeasonsStore } from "../../../entities/season";
import { SeasonTabs } from "./SeasonTabs";

export const AdminSeasonView = () => {
  const { customerId, organizationId, sport } = useParams();
  useFetchSeason();
  const activeSeason = useSeasonsStore(store => store?.activeSeason);

  return <>
    <Row justify={"space-between"} className={"mt-10"} align={"middle"}>
      <Space>
        <Typography.Title style={{ margin: 0 }} className={"text-upper"}
                          level={3}>{activeSeason?.nameLocal} - {activeSeason?.entityNameLocal}</Typography.Title>
        {activeSeason?.taskStatus && <TaskStatusTag status={activeSeason?.taskStatus}/>}
      </Space>
      <BackToButton to={`/${customerId}/${organizationId}/${sport}/admin`} btnText={"Back to dashboard"}/>
    </Row>
    <SeasonTabs/>
  </>;
};