import { useEffect, useState } from "react";
import { useFixturesStore } from "../../../entities/fixtures";
import { useFixtureRosterStore } from "../../../entities/roster/model";
import { shallow } from "zustand/shallow";

export const useIsFixtureRosterReachedMaxSize = ({ type }) => {
  const [isReachedMaxSize, setIsReachedMaxSize] = useState(false);
  const fixture = useFixturesStore(store => store?.fixture, shallow);
  const fixtureRoster = useFixtureRosterStore(store => store?.fixtureRoster, shallow);

  useEffect(() => {
    if (type === "fixtureRoster") {
      if (fixture?.maximumRosterSize) {
        setIsReachedMaxSize(fixtureRoster?.length > fixture?.maximumRosterSize);
      } else {
        setIsReachedMaxSize(false);
      }
    }
  }, [type, fixture, fixtureRoster]);

  return isReachedMaxSize;
};