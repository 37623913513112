import { Link } from "react-router-dom";
import { Button, Result } from "antd";
import { useCustomersStore } from "../../../entities/customer/model";
import { shallow } from "zustand/shallow";

export const Page404 = () => {
  const redirectLinkForErrorPages = useCustomersStore(store => store?.redirectLinkForErrorPages, shallow);

  return <Result
    status="error"
    title="404"
    subTitle="Sorry, the resource you are looking for does not exist"
    extra={<Link to={redirectLinkForErrorPages}><Button type="primary">Back Home</Button></Link>}
  />;
};