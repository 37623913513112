import { useParams } from "react-router-dom";
import { StaffRosterFixtureTitle, useFetchFixture, useFixturesStore } from "../../../entities/fixtures";
import { Row, Space } from "antd";
import { BackToButton, TaskStatusTag } from "../../../shared";
import { FixtureTabs } from "./FixtureTabs";

export const AdminFixtureView = () => {
  const { customerId, organizationId, sport } = useParams();
  useFetchFixture();
  const fixture = useFixturesStore(store => store?.fixture);

  return <>
    <Row justify={"space-between"} className={"mt-10"} align={"middle"}>
      <Space>
        <StaffRosterFixtureTitle/>
        {fixture?.taskStatus && <TaskStatusTag status={fixture?.taskStatus}/>}
      </Space>
      <BackToButton to={`/${customerId}/${organizationId}/${sport}/admin`} btnText={"Back to dashboard"}/>
    </Row>
    <FixtureTabs/>
  </>;
};