import {useParams} from "react-router-dom";
import {useMemo} from "react";
import {isEmpty} from "lodash";
import {UniformItemTypes} from "~shared/types/UniformItemTypes";
import {UniformItemColors} from "~shared/types/UniformItemColors";

interface SportConfiguration {
   uniformItemTypes: { [itemType in UniformItemTypes]?: Array<UniformItemColors> }
}

const defaultUniformItemTypes = {
    [UniformItemTypes.Top]: [UniformItemColors.Primary, UniformItemColors.Secondary],
    [UniformItemTypes.Bottom]: [UniformItemColors.Primary],
    [UniformItemTypes.GoalkeeperTop]: [UniformItemColors.Primary, UniformItemColors.Secondary],
    [UniformItemTypes.GoalkeeperBottom]: [UniformItemColors.Primary],
    [UniformItemTypes.Socks]: [UniformItemColors.Primary],
};

const config: { [sport: string]: SportConfiguration } = {
    "basketball": {
        uniformItemTypes: {}
    },
    "handball": {
        uniformItemTypes: defaultUniformItemTypes
    },
    "hockey": {
        uniformItemTypes: defaultUniformItemTypes
    },
    "ice3": {
        uniformItemTypes: {}
    },
    "volleyball": {
        uniformItemTypes: {}
    },
    "tennis": {
        uniformItemTypes: {}
    },
    "baseball": {
        uniformItemTypes: {}
    },
    "defaults": {
        uniformItemTypes: {}
    },
};

export const useSportConfiguration = () => {
    const {sport: sportFromParams} = useParams();
    const sport = sportFromParams?.toLowerCase() ?? "";

    return useMemo(() => {
        if (!isEmpty(config[sport])) {
            return config[sport];
        }
        return config["defaults"];
    }, [sport]);
};
