import { useApi } from "../../../shared/api"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"

export const useFetchSeasonsByOrganization = ({ taskStatus, page, pageSize }) => {
  const { fetchData } = useApi()
  const { organizationId, sport } = useParams()

  return useQuery({
    queryKey: ["seasons", organizationId, taskStatus, page],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/seasons?taskStatus=${taskStatus}&limit=${pageSize}&offset=${pageSize * page - pageSize}`),
    keepPreviousData: true
  })
}