import { useState } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthChecking } from "./AuthChecking";

export const AuthProvider = ({ children }) => {
  const [appState, setAppState] = useState(null);
  const onRedirectCallback = (appState) => {
    setAppState(appState);
    window.location.href = appState?.returnTo || window.location.origin;
  };

  return (
    <Auth0Provider
      domain={import.meta.env.AUTH0_DOMAIN}
      clientId={import.meta.env.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <AuthChecking appState={appState}>
        {children}
      </AuthChecking>
    </Auth0Provider>
  );
};
