import { BaseEntityCard, SearchInput } from "../../../shared";
import { Alert, Row, Space, Spin } from "antd";
import { useState } from "react";
import { useOrganizationsStore } from "../../../entities/organization/model";
import { useFetchOrganization } from "../../../entities/organization/api";

const generateOrgButtonLink = ({ org }) => {
  // //both
  if (org?.availableUserRoles?.includes("competition-admin") && org?.availableUserRoles?.includes("team-manager")) {
    return `${org?.organizationId}/${org?.sport}/role`;
  }

  //admin only
  if (org?.availableUserRoles?.includes("competition-admin")) {
    return `${org?.organizationId}/${org?.sport}/admin`;
  }

  // //team manager only
  if (org?.availableUserRoles?.includes("team-manager")) {
    return `${org?.organizationId}/${org?.sport}/teams`;
  }
};

export const OrganizationsTable = () => {
    const { isLoading } = useFetchOrganization();
    const organizations = useOrganizationsStore(state => state?.organizations);
    const [searchText, setSearchText] = useState("");

    if (!isLoading && !organizations?.length) {
      return <Alert type={"error"} description={"You don't have access to any organization."}/>;
    }

    if (!isLoading && organizations?.every(org => org?.availableUserRoles?.length === 0)) {
      return <Alert type={"error"} description={"The current user has no role. Please contact the administrator."}/>;
    }

    return <>
      <div style={{
        boxShadow: "rgba(0, 0, 0, 0.12) 0px 12px 40px",
        padding: "10px",
        marginTop: "10px",
      }}>
        <SearchInput className={"mb-10"} doSearch={setSearchText}/>
        {isLoading ? <Row justify={"center"}><Spin/></Row> :
          <Space direction={"vertical"} style={{ width: "100%" }}>
            {organizations
              ?.filter((org) => org?.nameLocal?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1 || org?.organizationId?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1)
              ?.map(org => <BaseEntityCard key={org?.organizationId}
                                           to={generateOrgButtonLink({ org })}
                                           name={org?.nameLocal}
                                           desc={`Org ID: ${org?.organizationId} | ${org?.sport}`}/>)}
          </Space>}
      </div>
    </>;
  }
;