import { create } from "zustand"
import { devtools } from "zustand/middleware"

export const useTeamsStore = create(devtools((set) => ({
  teams: [],
  activeTeam: null,
  setActiveTeam: (data) => {
    set({
      activeTeam: data?.[0]
    })
  },
  set: (data) => {
    set({
      teams: data
    })
  }
})), { name: "teams" });