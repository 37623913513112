import { Button, Col, Row } from "antd"
import { useState } from "react"
import { SeasonsListForCopy } from "../../entities/season/ui"
import { PublishedRosterList } from "../../entities/roster"
import { PublishedStaffList } from "../../entities/staff"
import { CopyButton } from "./CopyButton"

export const CopyForSeasonWrapper = ({ type, closeModal }) => {
  const [viewSeasonId, setViewSeasonId] = useState(null)

  return <>
    <Row>
      <Col span={24}>
        {viewSeasonId ? (
          <>
            <Button
              onClick={() => { setViewSeasonId(null) }}
              icon={<i className="fa-solid fa-angle-left" />}
              className="text-upper"
            >
              Back
            </Button>
            {type === "seasonStaff"
              ? <PublishedStaffList seasonId={viewSeasonId} />
              : <PublishedRosterList seasonId={viewSeasonId} />
            }
            <Row justify="end" className="mt-15">
              <CopyButton
                fromSeasonId={viewSeasonId}
                closeModal={closeModal}
                type={type}
              />
            </Row>
          </>
        ) : <SeasonsListForCopy type={type} setSeasonId={setViewSeasonId} />}
      </Col>
    </Row>
  </>
}