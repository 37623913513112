export const useEditTeamFieldsList = () => {
  const gender = ["FEMALE", "MALE", "MIXED", "UNKNOWN"];
  const standard = ["ELITE", "FRIENDLY", "GRASS_ROOT", "INTERNATIONAL", "NONCONTINENTAL_CHAMPIONSHIP", "OLYMPIC", "REGION", "TIER2", "TIER3", "WORLD_CHAMPIONSHIP", "ZONE_CHAMPIONSHIP"];
  const status = ["ACTIVE", "INACTIVE", "PENDING"];
  const ageGroup = ["JUNIOR", "YOUTH", "UNDER_10", "UNDER_11", "UNDER_12", "UNDER_13", "UNDER_14", "UNDER_15", "UNDER_16", "UNDER_17", "UNDER_18", "UNDER_19", "UNDER_20", "UNDER_21", "UNDER_22", "UNDER_23", "SENIOR", "MASTERS"];

  return {
    gender,
    standard,
    status,
    ageGroup
  }
}