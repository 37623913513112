import { useFetchCustomers } from "../../../entities/customer/api";
import { useCustomersStore } from "../../../entities/customer/model";
import { useState } from "react";
import { Col, Row, Space, Spin, Typography } from "antd";
import { useIntl } from "react-intl";
import { BaseEntityCard, SearchInput } from "../../../shared";

export const CustomerSelection = () => {
  const { formatMessage } = useIntl();
  useFetchCustomers();
  const customers = useCustomersStore(state => state?.customers);
  const [searchText, setSearchText] = useState("");

  return <>
    <Row justify={"space-between"} className={"mt-10"}>
      <Space direction={"vertical"} size={0}>
        <Typography.Title className={"text-center mt-0"} level={2}>{formatMessage({
          id: "select.customer",
          defaultMessage: "Select Customer",
        })}</Typography.Title>
        <Typography.Text>{formatMessage({
          id: "available.customers",
          defaultMessage: "Available customers",
        })}</Typography.Text>
      </Space>
    </Row>
    <Row justify={"center"}>
      <Col xs={24} sm={10} md={12} lg={10} xl={8} xxl={5}>
        <div style={{
          boxShadow: "rgba(0, 0, 0, 0.12) 0px 12px 40px",
          backgroundColor: "#f3f3f3",
        }} className={"p-10 mt-10"}>
          <SearchInput className={"mb-10"} doSearch={setSearchText}/>
          {!customers && <Row justify={"center"}><Spin/></Row>}
          <Space direction={"vertical"} style={{ width: "100%" }}>
            {customers
              ?.filter((customer) => customer?.displayName?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1)
              ?.map(customer => <BaseEntityCard key={customer?.customerId} to={`/${customer?.customerId}`}
                                                name={customer?.displayName} desc={`Code: ${customer?.code}`}/>)}
          </Space>
        </div>
      </Col>
    </Row>
  </>;
};