import { Col, Row, Skeleton, Typography } from "antd";
import { BackToButton } from "../../../shared/ui";
import { useParams } from "react-router-dom";
import { EditTeamForm } from "../../../entities/teams/ui";
import { useIntl } from "react-intl";
import { useTeamQuery } from "../../../entities/teams/api";
import { useClubsQuery } from "../../../entities/club";
import { useVenuesQuery } from "../../../entities/venues";
import { useTeamsStore } from "../../../entities/teams/model";
import { UpdateImageWrap } from "./UpdateImageWrap";

export const EditTeam = () => {
  const { customerId, organizationId, sport, teamId } = useParams();
  const { formatMessage } = useIntl();
  useTeamQuery(teamId);
  useClubsQuery();
  useVenuesQuery();
  const activeTeam = useTeamsStore(state => state?.activeTeam);

  return <>
    <Row className={"mt-15"}>
      <BackToButton to={`/${customerId}/${organizationId}/${sport}/teams/${teamId}`} btnText={formatMessage({
        id: "back.to.team",
        defaultMessage: "Back to team",
      })}/>
    </Row>
    <Row>
      <Col span={24}>
        {!activeTeam ? <Skeleton.Input/> : <Typography.Title level={2}>{formatMessage({
          id: "manage",
          defaultMessage: "Manage",
        })} - {activeTeam?.nameFullLocal}</Typography.Title>}
      </Col>
    </Row>
    <Row gutter={15}>
      {!activeTeam ? <Skeleton/> : <>
        <EditTeamForm/>
        <Col xxl={5} xl={7} lg={9}>
          <UpdateImageWrap/>
        </Col>
      </>}
    </Row>
  </>;
};