import { create } from "zustand"
import { devtools } from "zustand/middleware"

export const useFixtureRosterStore = create(devtools((set) => ({
  fixtureRoster: [],
  setFixtureRoster: data => {
    set({
      fixtureRoster: data
    })
  }
})), { name: "fixtureRoster" })