import {useApi} from "../../../shared/api"
import {useParams} from "react-router-dom"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {notification} from "antd"
import {useFixtureStaffStore} from "../model"
import {useIntl} from "react-intl"
import { sortStaff } from "~shared/common";

export const useFetchFixtureStaff = (props) => {
  let enabled = props?.enabled

  if (typeof enabled !== "boolean") {
    enabled = true
  }

  const {fetchData} = useApi()
  const {seasonId, organizationId, sport, teamId, fixtureId} = useParams()
  const setFixtureStaff = useFixtureStaffStore(state => state?.setFixtureStaff)

  return useQuery({
    queryKey: ["fixtureStaff", sport, organizationId, teamId, seasonId, fixtureId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/staff`),
    enabled: enabled,
    onSuccess: data => {
      setFixtureStaff(sortStaff(data?.data))
    },
    onError: err => {
      console.error("onError", err)
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch fixture staff",
        description: err?.message
      })
    }
  })
}

export const useFetchPublishedFixtureStaff = (fixtureId) => {
  const {fetchData} = useApi()
  const {organizationId, sport, teamId, seasonId} = useParams()

  return useQuery({
    queryKey: ["fixtureStaff", "published", sport, organizationId, teamId, seasonId, fixtureId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/staff/published`),
    enabled: false,
    onError: err => {
      console.error("onError", err)
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch fixture staff",
        description: err?.message
      })
    }
  })
}

export const useDeleteFixtureStaff = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/staff`,
        method: "DELETE",
        data: data
      })
    },
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Person deleted successfully"
      })
      queryClient.invalidateQueries({queryKey: ["fixtureStaff", sport, organizationId, teamId, seasonId, fixtureId]})
    },
    onError: error => {
      notification.error({
        placement: "bottomRight",
        message: "Delete Person has failed",
        description: error?.message
      })
    }
  })
}

export const useAddPersonsToFixtureStaff = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams()
  const {formatMessage} = useIntl()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/staff`,
        method: "POST",
        data
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ["fixtureStaff", sport, organizationId, teamId, seasonId, fixtureId]})
      notification.success({
        placement: "bottomRight",
        message: formatMessage({
          id: "person.add.success",
          defaultMessage: "Person added successfully"
        })
      })
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't add persons",
        description: error?.message || ""
      })
    }
  })
}

export const useUpdateFixtureStaffPersonInternal = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (person) => mutateData({
      url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/staff/${person?.personId}`,
      method: "PUT",
      data: person
    }),
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Person updated successfully"
      })
      queryClient.invalidateQueries({queryKey: ["fixtureStaff", sport, organizationId, teamId, seasonId, fixtureId]})
    },
    onError: (err) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't update player",
        description: err?.message
      })
    }
  })
}

export const useUpdateFixtureStaffPersonDC = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({dcPersonId, ...person}) => mutateData({
      url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/staff/dc/${dcPersonId}`,
      method: "PUT",
      data: person
    }),
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Person updated successfully"
      })
      queryClient.invalidateQueries({queryKey: ["fixtureStaff", sport, organizationId, teamId, seasonId, fixtureId]})
    },
    onError: (err) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't update player",
        description: err?.message
      })
    }
  })
}