import { Button,Modal, Typography } from "antd"
import { useIntl } from "react-intl"
import { useState } from "react"
import { useFetchSeason } from "../../entities/season"
import { useFetchFixture  } from "../../entities/fixtures"
import { CopyForFixtureWrapper } from "./CopyForFixtureWrapper"
import { CopyForSeasonWrapper } from "./CopyForSeasonWrapper"

type Props = {
  type: "seasonRoster" | "seasonStaff" | "fixtureRoster" | "fixtureStaff"
}

export const CopyPersons = ({ type }: Props) => {
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState(false)

  const { data: activeSeasonData } = useFetchSeason()
  const { data: fixtureData } = useFetchFixture()
  const activeSeason = activeSeasonData?.data?.[0]
  const fixture = fixtureData?.data?.[0]

  const isSeason = type === "seasonRoster" || type === "seasonStaff"
  const isFixture = type === "fixtureRoster" || type === "fixtureStaff"
  const isStaff = type === "fixtureStaff" || type === "seasonStaff"

  const isEditable = (isSeason && activeSeason?.areRosterAndStaffEditable) || (isFixture && fixture?.areRosterAndStaffEditable)

  const closeModal = () => setOpen(false)

  return (
    <>
      <Button
        type="primary"    
        onClick={() => setOpen(true)}
        disabled={!isEditable}
        className="text-upper"
        icon={<i className="fa-solid fa-file-arrow-down" />}
      >
        {formatMessage({
          id: isStaff ? "copy.staff" : "copy.roster",
          defaultMessage: isStaff ? "Copy Staff" : "Copy Roster"
        })}
      </Button>
      {open && (
        <Modal
          open={open}
          footer={null}
          destroyOnClose
          width="70%"
          onCancel={closeModal}
        >
          <div className="pt-25">
            {isSeason && <CopyForSeasonWrapper closeModal={closeModal} type={type} />}
            {isFixture && (
              <CopyForFixtureWrapper
                closeModal={closeModal}
                type={type as "fixtureStaff" | "fixtureRoster"}
              />
            )}
            {!isSeason && !isFixture && (
              <Typography.Title type="danger">
                Unexpected error. Reload the page.
              </Typography.Title>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}