import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Divider, Row, Skeleton, Space, Switch, Typography } from "antd";
import { BackToButton, SearchInput, TaskStatusTag } from "../../../shared";
import { StaffRosterFixtureTitle, useFetchFixture, useFixturesStore } from "../../../entities/fixtures";
import { StaffList } from "../../../entities/staff";
import { AddPerson } from "../../../features/AddPerson";
import { SubmitSeasonStaff } from "../../../features/SubmitSeasonStaff";
import { CopyPersons } from "../../../features/CopyPersons";
import { useUpdateStaffByPerson } from "../../../entities/staff/api";

export const FixtureStaff = () => {
  const { customerId, organizationId, sport, teamId, seasonId } = useParams();
  const { formatMessage } = useIntl();
  const { isLoading } = useFetchFixture();
  const [searchText, setSearchText] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const fixture = useFixturesStore(store => store?.fixture);

  const {
    updated: updatedPerson,
    isLoading: isUpdating,
    mutate: updateStaff
  } = useUpdateStaffByPerson({ type: "fixtureStaff" })
  
  const canEdit = useCallback((column, record) => {
    const isTarget = record === updatedPerson;
    return !(isTarget && isUpdating);
  }, [isUpdating, updatedPerson]);
  
  const onColumnChanged = useCallback(async ({ column, value, record }) => {
    const dataCopy = {
      [column]: value,
    }
    await updateStaff(dataCopy, record)
  }, [updateStaff]);

  const onIsEditModeChange = useCallback((checked) => {
    setIsEditMode(checked);
  }, []);

  return <>
    <Row className={"mt-15"} justify={"space-between"}>
      {isLoading
        ? <Skeleton.Input/>
        : <Space align={"center"}>
          <StaffRosterFixtureTitle type={"staff"}/>
          {fixture?.taskStatus && <TaskStatusTag status={fixture?.taskStatus}/>}
        </Space>}
      <BackToButton to={`/${customerId}/${organizationId}/${sport}/teams/${teamId}/seasons/${seasonId}/fixtures`}
                    btnText={formatMessage({
                      id: "back.to.fixtures",
                      defaultMessage: "Back to fixtures",
                    })}/>
    </Row>
    <Divider/>
    <Row className={"mb-10"} justify={"space-between"} align={"middle"}>
      <Typography.Text strong>Team Staff List</Typography.Text>
      <Space>
        <span>{formatMessage({ id: "edit.mode", defaultMessage: "Edit mode" })}</span>
        <Switch checked={isEditMode} disabled={!fixture?.areRosterAndStaffEditable || false} onChange={onIsEditModeChange} />
        <SearchInput doSearch={setSearchText}/>
        <CopyPersons type={"fixtureStaff"}/>
        <AddPerson type={"fixtureStaff"}/>
        <SubmitSeasonStaff type={"fixtureStaff"}/>
      </Space>
    </Row>
    <StaffList searchText={searchText} type={"fixtureStaff"} canEdit={canEdit} isEditMode={isEditMode} onColumnChanged={onColumnChanged} />
  </>;
};