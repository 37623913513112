import { useApi } from "../../../shared/api";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { notification } from "antd";
import { useTaskStore } from "../model";

export const useFetchSeasonTask = () => {
  const { fetchData } = useApi();
  const { organizationId, sport, teamId, seasonId } = useParams();
  const setTask = useTaskStore(store => store?.setTask);

  return useQuery({
    queryKey: ["task", teamId, seasonId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/task`),
    onSuccess: (data) => {
      setTask(data?.data?.[0] || null);
    },
    onError: err => {
      notification.error({
        placement: "bottomRight",
        message: "Can't fetch task",
        description: err?.message,
      });
    },
  });
};

export const useApproveSeasonTask = () => {
  const { mutateData } = useApi();
  const { organizationId, sport, teamId, seasonId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/task/approve`,
        method: "PATCH",
        data: {},
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["task", teamId, seasonId] });
      queryClient.invalidateQueries({ queryKey: ["seasons", organizationId] });
      queryClient.invalidateQueries({ queryKey: ["seasons", teamId, seasonId] });
      notification.success({
        placement: "bottomRight",
        message: "Approve roster/staff successfully",
      });
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Approve roster/staff has failed",
        description: error?.message,
      });
    },
  });
};

export const usePublishSeasonTask = () => {
  const { mutateData } = useApi();
  const { organizationId, sport, teamId, seasonId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/task/publish`,
        method: "PATCH",
        data: {},
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["task", teamId, seasonId] });
      queryClient.invalidateQueries({ queryKey: ["seasons", organizationId] });
      queryClient.invalidateQueries({ queryKey: ["seasons", teamId, seasonId] });
      notification.success({
        placement: "bottomRight",
        message: "Publish roster/staff successfully",
      });
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Publish roster/staff has failed",
        description: error?.message,
      });
    },
  });
};

export const useDeleteSeasonTask = () => {
  const { mutateData } = useApi();
  const { organizationId, sport } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId, seasonId }) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/task`,
        method: "DELETE",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["seasons", organizationId] });
      notification.success({
        placement: "bottomRight",
        message: "Delete season task successfully",
      });
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Delete season task has failed",
        description: error?.message,
      });
    },
  });
};