import { useAuth0 } from "@auth0/auth0-react";
import "./style.css";
import { Spin } from "antd";
import { useCallback, useState } from "react";
import { Tracing } from "~shared/Tracing";

export const AuthChecking = ({ children, appState }) => {
  const {
    user,
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
  } = useAuth0();
  const [isNewTokenGenerated, setIsNewTokenGenerated] = useState(false);
  const [timerId, setTimerId] = useState(null);

  const stopTimer = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
  }, [timerId]);

  if (isLoading) return <div className={"loading-full-screen"}>
    <Spin/>
  </div>;

  if (isAuthenticated) {
    Tracing.setUser(user);
  } else {
    loginWithRedirect({
      appState: {
        returnTo: window.location.href,
      },
    });
    return;
  }

  const invalidateToken = async () => {
    try {
      await getAccessTokenSilently({
        cacheMode: "off",
      });

      const idToken = await getIdTokenClaims();
      if (!idToken) {
        throw new Error("Can't get token");
      }

      const timeMs = (idToken?.exp - idToken?.iat - 3600) * 1000;
      if (timeMs <= 0) {
        throw new Error("Invalid token lifetime");
      }

      setTimerId(setTimeout(() => {
        invalidateToken();
      }, timeMs));
      setIsNewTokenGenerated(true);
    } catch (e) {
      console.error(e);
      stopTimer();

      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/logout?returnTo=${window.location.href}`,
        },
      });
    }
  };

  if (!isNewTokenGenerated) {
    stopTimer();
    if (!appState) invalidateToken();
    return <div className={"loading-full-screen"}>
      <Spin/>
    </div>;
  } else {
    return children;
  }
};