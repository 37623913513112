import { useApi } from "../../../shared/api";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useSeasonsStore } from "../model";
import { notification } from "antd";

export const useFetchActiveSeasons = () => {
  const { fetchData } = useApi();
  const { organizationId, sport, teamId } = useParams();
  const setActiveSeasons = useSeasonsStore(state => state.setActiveSeasons);

  return useQuery({
    queryKey: ["seasons", teamId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons?status=active&include=competitions`),
    onSuccess: data => {
      setActiveSeasons(data?.data);
    },
  });
};

export const useFetchHistoricSeasons = ({ offset, limit }) => {
  const { fetchData } = useApi();
  const { organizationId, sport, teamId } = useParams();

  return useQuery({
    queryKey: ["seasons", teamId, "historic", `offset=${offset}&limit=${limit}`],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons?status=inactive&offset=${offset}&limit=${limit}&include=competitions`),
  });
};

export const useFetchUpcomingSeasons = () => {
  const { fetchData } = useApi();
  const { organizationId, sport, teamId } = useParams();
  const setUpcomingSeasons = useSeasonsStore(state => state.setUpcomingSeasons);

  return useQuery({
    queryKey: ["seasons", teamId, "upcoming"],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons?status=pending&limit=3&include=competitions`),
    onSuccess: data => {
      setUpcomingSeasons(data?.data);
    },
  });
};

export const useFetchSeasons = () => {
  const { fetchData } = useApi();
  const { organizationId, sport, teamId } = useParams();
  const setSeasons = useSeasonsStore(state => state.setSeasons);

  return useQuery({
    queryKey: ["seasons", teamId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons`),
    onSuccess: data => {
      setSeasons(data?.data || null);
    },
    onError: err => {
      console.error("onError", err);
      notification.error({
        placement: "bottomRight", message: "Can't fetch seasons", description: err?.message,
      });
    },
  });
};

export const useFetchSeason = () => {
  const { fetchData } = useApi();
  // TODO: DO NOT(!!) use useParams in query hooks, what if I want to use different teamId, for instance, on the same page? This hook tied tightly to the route gives less flexibility
  const { organizationId, sport, teamId, seasonId } = useParams();
  const setActiveSeason = useSeasonsStore(state => state?.setActiveSeason);

  return useQuery({
    queryKey: ["seasons", teamId, seasonId],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}`),
    onSuccess: data => {
      setActiveSeason(data?.data?.[0] || null);
    },
    onError: err => {
      console.error("onError", err);
      notification.error({
        placement: "bottomRight", message: "Can't fetch season", description: err?.message,
      });
    },
  });
};

export const useFetchSeasonsInFixtureList = ({ taskStatus }) => {
  const { fetchData } = useApi();
  const { organizationId, sport } = useParams();
  const setSeasonInFixtureList = useSeasonsStore(state => state?.setSeasonInFixtureList);

  return useQuery({
    queryKey: ["seasons", "fixtures", organizationId, taskStatus],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/fixtures/seasons?taskStatus=${taskStatus}`),
    onSuccess: data => {
      setSeasonInFixtureList(data?.data || null);
    },
    onError: err => {
      console.error("onError", err);
      notification.error({
        placement: "bottomRight", message: "Can't fetch season", description: err?.message,
      });
    },
  });
};
