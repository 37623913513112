import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRole } from "~shared/hooks";

export const HeaderLogo = () => {
  const { teamId, customerId, organizationId, sport } = useParams();
  const [redirectLink, setRedirectLink] = useState("/");
  const { isAdmin, isTeamManager } = useRole();

  useEffect(() => {
    if (isAdmin && organizationId) {
      setRedirectLink(`/${customerId}/${organizationId}/${sport}/admin`);
      return;
    }

    if (isTeamManager && teamId) {
      setRedirectLink(`/${customerId}/${organizationId}/${sport}/teams/${teamId}`);
      return;
    }

    setRedirectLink("/");
  }, [teamId, isAdmin, isTeamManager, organizationId, customerId, sport]);

  return <Link to={redirectLink} className="navbar-brand">
    <img style={{ marginLeft: "-8px" }} src={"/img/SR_logo_pos.png"} alt="Logo"/>
    Team Management
  </Link>;
};