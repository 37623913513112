import { Button } from "antd"
import { RejectModal } from "./RejectModal"
import { useState } from "react"

export const ButtonReject = ({ type }) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return <>
    <Button
      icon={<i className="fa-solid fa-xmark"></i>}
      onClick={openModal}
      type={"primary"}
      className={"text-upper"}
      danger={true}>Reject</Button>
    <RejectModal open={open} closeModal={closeModal} type={type}/>
  </>
}