import { useParams } from "react-router-dom";
import { Role, ROLES } from '~shared/types';

/** Gets role from path using react-router */
export const useRole = () => {
  const { role } = useParams<{ role: Role}>();

  return {
    isAdmin: role === ROLES.ADMIN,
    // at the moment by default it always team manager unless `role` is `admin`
    isTeamManager: role !== ROLES.ADMIN
  };
};