import { useCallback, useMemo } from "react"
import {
  useUpdateFixtureRosterPlayerDC,
  useUpdateFixtureRosterPlayerInternal,
  useUpdateSeasonRosterPlayerDC,
  useUpdateSeasonRosterPlayerInternal
} from "../../../entities/roster/api"
import { cloneDeep } from "lodash"
import {
  useUpdateFixtureStaffPersonDC,
  useUpdateFixtureStaffPersonInternal, useUpdateSeasonStaffPersonDC,
  useUpdateSeasonStaffPersonInternal
} from "../../../entities/staff/api"


export const ALLOWED_TYPES = [
  "seasonRoster",
  "fixtureRoster",
  "fixtureStaff",
  "seasonStaff",
];

export const useUpdate = ({ type, person }) => {
  if (!ALLOWED_TYPES.includes(type)) {
    console.error("Target type", type, "is not in the accepted list", ALLOWED_TYPES);
    throw new Error("Target type is not accepted");
  }

  //roster season
  const {
    isLoading: isLoadingInternalSeasonRoster,
    isSuccess: isSuccessInternalSeasonRoster,
    mutate: updateInternalSeasonRoster
  } = useUpdateSeasonRosterPlayerInternal()
  const {
    isLoading: isLoadingDCSeasonRoster, isSuccess: isSuccessDCSeasonRoster, mutate: updateDCSeasonRoster
  } = useUpdateSeasonRosterPlayerDC()
  // roster fixture
  const {
    isLoading: isLoadingInternalFixtureRoster,
    isSuccess: isSuccessInternalFixtureRoster,
    mutate: updateInternalFixtureRoster
  } = useUpdateFixtureRosterPlayerInternal()
  const {
    isLoading: isLoadingDCFixtureRoster, isSuccess: isSuccessDCFixtureRoster, mutate: updateDCFixtureRoster
  } = useUpdateFixtureRosterPlayerDC()
  // staff fixture
  const {
    isLoading: isLoadingInternalFixtureStaff,
    isSuccess: isSuccessInternalFixtureStaff,
    mutate: updateInternalFixtureStaff
  } = useUpdateFixtureStaffPersonInternal()
  const {
    isLoading: isLoadingDCFixtureStaff, isSuccess: isSuccessDCFixtureStaff, mutate: updateDCFixtureStaff
  } = useUpdateFixtureStaffPersonDC()
  // staff season
  const {
    isLoading: isLoadingInternalSeasonStaff,
    isSuccess: isSuccessInternalSeasonStaff,
    mutate: updateInternalSeasonStaff
  } = useUpdateSeasonStaffPersonInternal()
  const {
    isLoading: isLoadingDCSeasonStaff,
    isSuccess: isSuccessDCSeasonStaff,
    mutate: updateDCSeasonStaff
  } = useUpdateSeasonStaffPersonDC()

  const isLoading = useMemo(() => {
    if (person?.personId) {
      if (type === "seasonRoster") return isLoadingInternalSeasonRoster
      if (type === "fixtureRoster") return isLoadingInternalFixtureRoster
      if (type === "fixtureStaff") return isLoadingInternalFixtureStaff
      if (type === "seasonStaff") return isLoadingInternalSeasonStaff
    }

    if (person?.dcPersonId) {
      if (type === "seasonRoster") return isLoadingDCSeasonRoster
      if (type === "fixtureRoster") return isLoadingDCFixtureRoster
      if (type === "fixtureStaff") return isLoadingDCFixtureStaff
      if (type === "seasonStaff") return isLoadingDCSeasonStaff
    }
  }, [isLoadingInternalSeasonStaff, isLoadingDCSeasonStaff, isLoadingInternalSeasonRoster, isLoadingDCSeasonRoster, isLoadingInternalFixtureRoster, isLoadingDCFixtureRoster, isLoadingInternalFixtureStaff, isLoadingDCFixtureStaff, type, person])

  const isSuccess = useMemo(() => {
    if (person?.personId) {
      if (type === "seasonRoster") return isSuccessInternalSeasonRoster
      if (type === "fixtureRoster") return isSuccessInternalFixtureRoster
      if (type === "fixtureStaff") return isSuccessInternalFixtureStaff
      if (type === "seasonStaff") return isSuccessInternalSeasonStaff
    }

    if (person?.dcPersonId) {
      if (type === "seasonRoster") return isSuccessDCSeasonRoster
      if (type === "fixtureRoster") return isSuccessDCFixtureRoster
      if (type === "fixtureStaff") return isSuccessDCFixtureStaff
      if (type === "seasonStaff") return isSuccessDCSeasonStaff
    }
  }, [isSuccessDCSeasonStaff, isSuccessInternalSeasonStaff, isSuccessInternalFixtureStaff, isSuccessDCFixtureStaff, isSuccessInternalSeasonRoster, isSuccessDCSeasonRoster, isSuccessInternalFixtureRoster, isSuccessDCFixtureRoster, type, person])

  const update = useCallback(async ({ form }) => {
    try {
      const data = await form.validateFields()
      const dataCopy = cloneDeep(data)

      if (dataCopy?.number) {
        dataCopy.number = dataCopy.number.toString()
      }

      if (person?.personId) {
        if (type === "seasonRoster") {
          updateInternalSeasonRoster({
            ...dataCopy, personId: person?.personId
          })
        }
        if (type === "fixtureRoster") {
          updateInternalFixtureRoster({
            ...dataCopy, personId: person?.personId
          })
        }
        if (type === "fixtureStaff") {
          updateInternalFixtureStaff({
            ...dataCopy, personId: person?.personId
          })
        }
        if (type === "seasonStaff") {
          updateInternalSeasonStaff({
            ...dataCopy, personId: person?.personId
          })
        }
      }

      if (person?.dcPersonId) {
        if (type === "seasonRoster") {
          updateDCSeasonRoster({
            ...dataCopy, dcPersonId: person?.dcPersonId
          })
        }
        if (type === "fixtureRoster") {
          updateDCFixtureRoster({
            ...dataCopy, dcPersonId: person?.dcPersonId
          })
        }
        if (type === "fixtureStaff") {
          updateDCFixtureStaff({
            ...dataCopy, dcPersonId: person?.dcPersonId
          })
        }
        if (type === "seasonStaff") {
          updateDCSeasonStaff({
            ...dataCopy, dcPersonId: person?.dcPersonId
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, person])

  return {
    update, isLoading, isSuccess
  }
}
