import {useApi} from "../../../shared/api"
import {useParams} from "react-router-dom"
import {useMutation, useQueryClient} from "@tanstack/react-query"
import {notification} from "antd"

export const useCopyRosterFromSeasonToSeason = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId} = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({fromSeasonId}) => mutateData({
      url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/roster/copy/from/season/${fromSeasonId}`,
      method: "POST"
    }),
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Roster copy successfully"
      })
      queryClient.invalidateQueries({queryKey: ["seasonRoster", sport, organizationId, teamId, seasonId]})
      queryClient.invalidateQueries({queryKey: ["seasons", teamId]})
      queryClient.invalidateQueries({queryKey: ["seasons", teamId, seasonId]})
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't copy roster",
        description: error?.message || ""
      })
    }
  })
}

export const useCopyRosterFromSeasonToFixture = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({fromSeasonId}) => mutateData({
      url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/roster/copy/from/season`,
      method: "POST"
    }),
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Roster copy successfully"
      })
      queryClient.invalidateQueries({queryKey: ["fixtureRoster", sport, organizationId, teamId, seasonId, fixtureId]})
      queryClient.invalidateQueries({queryKey: ["fixtures", teamId, seasonId]})
      queryClient.invalidateQueries({queryKey: ["fixtures", teamId, seasonId, fixtureId]})
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't copy roster",
        description: error?.message || ""
      })
    }
  })
}

export const useCopyRosterFromFixtureToFixture = () => {
  const {mutateData} = useApi()
  const {organizationId, sport, teamId, seasonId, fixtureId} = useParams()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({fromFixtureId}) => mutateData({
      url: `/${sport}/o/${organizationId}/entities/${teamId}/seasons/${seasonId}/fixtures/${fixtureId}/roster/copy/from/fixture/${fromFixtureId}`,
      method: "POST"
    }),
    onSuccess: () => {
      notification.success({
        placement: "bottomRight",
        message: "Roster copy successfully"
      })
      queryClient.invalidateQueries({queryKey: ["fixtureRoster", sport, organizationId, teamId, seasonId, fixtureId]})
      queryClient.invalidateQueries({queryKey: ["fixtures", teamId, seasonId]})
      queryClient.invalidateQueries({queryKey: ["fixtures", teamId, seasonId, fixtureId]})
    },
    onError: (error) => {
      notification.error({
        placement: "bottomRight",
        message: "Can't copy roster",
        description: error?.message || ""
      })
    }
  })
}