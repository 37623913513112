import { Col, Row, Space, Spin, Typography } from "antd"
import { useTeamsQuery } from "../../../entities/teams/api"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { BackToButton, BaseEntityCard, SearchInput } from "../../../shared/ui"
import { useState } from "react"

export const TeamsList = () => {
  const { isLoading, data } = useTeamsQuery()
  const { formatMessage } = useIntl()
  const { customerId } = useParams()
  const [searchText, setSearchText] = useState("")

  return <>
    <Row justify={"space-between"} className={"mt-10"}>
      <Space direction={"vertical"} size={0}>
        <Typography.Title className={"mt-0"} level={2}>
          {formatMessage({
            id: "select.team",
            defaultMessage: "Select Team"
          })}</Typography.Title>
        <Typography.Text>{formatMessage({
          id: "available.teams",
          defaultMessage: "Available Teams"
        })}</Typography.Text>
      </Space>
      <BackToButton to={`/${customerId}`} btnText={"Back to organizations"}/>
    </Row>
    <Row justify={"center"}>
      <Col xs={24} sm={10} md={12} lg={10} xl={8} xxl={5}>
        <div style={{
          boxShadow: "rgba(0, 0, 0, 0.12) 0px 12px 40px",
          padding: "10px",
          marginTop: "10px"
        }}>
          <SearchInput className={"mb-10"} doSearch={setSearchText}/>
          {isLoading && <Row justify={"center"}><Spin/></Row>}
          <Space direction={"vertical"} style={{
            width: "100%", maxHeight: "400px",
            overflow: "auto"
          }}>
            {data?.data
              ?.filter((team) => team?.nameFullLocal?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1)
              ?.map(team => <BaseEntityCard showImg={true} img={team?.imageLogoUrl} key={team?.entityId}
                                            to={team?.entityId} name={team?.nameFullLocal}/>)}
          </Space>
        </div>
      </Col>
    </Row>
  </>
}