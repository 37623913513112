import {useParams} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {notification} from "antd";
import {useIntl} from "react-intl";
import {useApi} from "~shared/api";
import {IUniformModel} from "../models/IUniformModel";

export const useDeleteUniform = () => {
  const {sport, organizationId, teamId} = useParams();
  const {mutateData} = useApi();
  const queryClient = useQueryClient();
  const {formatMessage} = useIntl();

  return useMutation({
    mutationFn: (uniform: IUniformModel) => {
      return mutateData({
        url: `/${sport}/o/${organizationId}/entities/${teamId}/uniforms/${uniform.uniformId}?forceDelete=true`,
        method: "DELETE",
        data: undefined
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: async (response: any, uniform: IUniformModel) => {

      if (response?.data && response.data.length > 0) {

        if (response?.data && response.data.length > 0 && response.data[0]?.success === true) {

          await queryClient.invalidateQueries({
            queryKey: ["uniformItems", sport, organizationId, uniform.uniformId]
          });

          await queryClient.invalidateQueries({
            queryKey: ["uniforms", sport, organizationId, teamId]
          });

          notification.success({
            message: formatMessage({id: 'uniform.delete.success'}),
            placement: 'bottomRight',
          });
        }
      }
    }
  });
}
