import { create } from "zustand"
import { devtools } from "zustand/middleware"

export const useSeasonStaffStore = create(devtools((set) => ({
  seasonStaff: [],
  seasonStaffPublished: [],
  setSeasonStaff: data => {
    set({
      seasonStaff: data
    })
  },
  setSeasonStaffPublished: data => {
    set({
      seasonStaffPublished: data
    })
  }
})), { name: "seasonStaff" })