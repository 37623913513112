import { Button } from "antd"
import { useReviewSeasonTask } from "../../../entities/season"
import { useReviewFixtureTask } from "../../../entities/fixtures"

export const ButtonReview = ({ type }) => {
  const { mutate: reviewSeasonTask, isLoading: isLoadingSeasonTask } = useReviewSeasonTask()
  const { mutate: reviewFixtureTask, isLoading: isLoadingFixtureTask } = useReviewFixtureTask()

  const onClick = () => {
    switch (type) {
      case "fixtureStaff":
      case "fixtureRoster":
        return reviewFixtureTask()
      case "seasonRoster":
      case "seasonStaff":
        return reviewSeasonTask()
    }
  }

  return <Button
    loading={isLoadingSeasonTask || isLoadingFixtureTask}
    icon={<i className="fa-solid fa-magnifying-glass"></i>}
    className={"text-upper"}
    type={"primary"}
    onClick={onClick}>Start Reviewing</Button>
}