import { Link } from "react-router-dom"
import { Button, theme, Typography } from "antd"

const { useToken } = theme

export const BackToButton = ({
  to,
  btnText
}) => {
  const { token } = useToken()

  return <Link to={to}>
    <Button
      // style={{ paddingLeft: 0, paddingRight: 0 }}
      type={"text"} icon={<i style={{ color: token.colorTextSecondary }} className="fa-solid fa-angle-left"></i>}>
      <Typography.Text type={"secondary"} className={"text-upper"} strong>{btnText}</Typography.Text>
    </Button>
  </Link>

}