import { useCallback, useRef } from "react";

import { useUpdateSeasonRosterPlayerInternal, useUpdateSeasonRosterPlayerDC } from "./useSeasonRosterAPI";
import { useUpdateFixtureRosterPlayerInternal, useUpdateFixtureRosterPlayerDC } from "./useFixtureRosterAPI";

export {
  useFetchSeasonRoster,
  useFetchPublishedSeasonRoster,
  useDeletePlayerFromSeasonRoster,
  useAddPersonsToSeasonRoster,
  useUpdateSeasonRosterPlayerInternal,
  useUpdateSeasonRosterPlayerDC
} from "./useSeasonRosterAPI"

export {
  useAddPersonsToFixtureRoster,
  useFetchFixtureRoster,
  useFetchPublishedFixtureRoster,
  useDeleteFixtureRoster,
  useUpdateFixtureRosterPlayerInternal,
  useUpdateFixtureRosterPlayerDC
} from "./useFixtureRosterAPI"

export const useUpdateRosterByPerson = ({ type }) => {
  const lastUpdateRef = useRef();
  //roster season
  const {
    isLoading: isLoadingInternalSeasonRoster,
    isSuccess: isSuccessInternalSeasonRoster,
    mutate: updateInternalSeasonRoster
  } = useUpdateSeasonRosterPlayerInternal()
  const {
    isLoading: isLoadingDCSeasonRoster,
    isSuccess: isSuccessDCSeasonRoster,
    mutate: updateDCSeasonRoster
  } = useUpdateSeasonRosterPlayerDC()
  // roster fixture
  const {
    isLoading: isLoadingInternalFixtureRoster,
    isSuccess: isSuccessInternalFixtureRoster,
    mutate: updateInternalFixtureRoster
  } = useUpdateFixtureRosterPlayerInternal()
  const {
    isLoading: isLoadingDCFixtureRoster,
    isSuccess: isSuccessDCFixtureRoster,
    mutate: updateDCFixtureRoster
  } = useUpdateFixtureRosterPlayerDC()

  const mutate = useCallback(async (dataCopy, person) => {
    lastUpdateRef.current = person;
    if (person?.personId) {
      if (type === "seasonRoster") {
        updateInternalSeasonRoster({
          ...dataCopy, personId: person?.personId
        })
      }
      if (type === "fixtureRoster") {
        updateInternalFixtureRoster({
          ...dataCopy, personId: person?.personId
        })
      }
    }
    if (person?.dcPersonId) {
      if (type === "seasonRoster") {
        updateDCSeasonRoster({
          ...dataCopy, dcPersonId: person?.dcPersonId
        })
      }
      if (type === "fixtureRoster") {
        updateDCFixtureRoster({
          ...dataCopy, dcPersonId: person?.dcPersonId
        })
      }
    }
  }, [type, updateInternalSeasonRoster, updateInternalFixtureRoster, updateDCSeasonRoster, updateDCFixtureRoster]);

  const isLoading = isLoadingInternalSeasonRoster || isLoadingDCSeasonRoster || isLoadingInternalFixtureRoster || isLoadingDCFixtureRoster;

  return {
    updated: isLoading ? lastUpdateRef.current : undefined,
    mutate,
    isLoading: isLoading,
    isSuccess: isSuccessInternalSeasonRoster && isSuccessInternalFixtureRoster && isSuccessDCSeasonRoster && isSuccessDCFixtureRoster
  };
};

export {
  useCopyRosterFromSeasonToFixture, useCopyRosterFromFixtureToFixture, useCopyRosterFromSeasonToSeason
} from "./useRosterCopyAPI"