import { Button } from "antd";
import { useEffect, useState } from "react";
import { useSeasonsStore } from "../../../entities/season";
import { useIntl } from "react-intl";
import { shallow } from "zustand/shallow";
import { useFixturesStore } from "../../../entities/fixtures";
import { RolesAreNotSetModalWarning } from "./RolesAreNotSetModalWarning";
import { useFixtureRosterStore } from "../../../entities/roster/model";

//type list
// seasonStaff
// seasonRoster
// fixtureStaff
// fixtureRoster

export const SendButton = ({ type }) => {
  const [disableButton, setDisableButton] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const { formatMessage } = useIntl();
  const activeSeason = useSeasonsStore(state => state?.activeSeason, shallow);
  const activeFixture = useFixturesStore(store => store?.fixture, shallow);
  const fixtureRoster = useFixtureRosterStore(store => store?.fixtureRoster, shallow);

  const textButton = () => {
    switch (type) {
      case "seasonStaff":
      case "fixtureStaff":
        return formatMessage({
          id: "staff.submit",
          defaultMessage: "Submit Staff",
        });
      case "seasonRoster":
      case "fixtureRoster":
        return formatMessage({
          id: "roster.submit",
          defaultMessage: "Submit Roster",
        });
      default:
        return "Submit";
    }
  };

  useEffect(() => {
    if (activeSeason || activeFixture) {
      switch (type) {
        case "fixtureStaff":
        case "fixtureRoster": {
          if (activeFixture?.areRosterAndStaffEditable) {
            setDisableButton(false);
          } else {
            setDisableButton(true);
          }
          const maxRoster = activeFixture?.maximumRosterSize;
          const maxStarter = activeFixture?.maximumRosterStarterSize;
          if (maxRoster) {
            if (fixtureRoster?.length > maxRoster) {
              setDisableButton(true);
            }
          }
          if (maxStarter) {
            if (fixtureRoster?.filter(x => x?.isStarter)?.length > maxStarter) {
              setDisableButton(true);
            }
          }
          break;
        }
        case "seasonStaff":
        case "seasonRoster":
          if (activeSeason?.areRosterAndStaffEditable) {
            setDisableButton(false);
          } else {
            setDisableButton(true);
          }
          break;
      }
    }
  }, [activeSeason, activeFixture, type, fixtureRoster]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return <>
    <Button disabled={disableButton}
            type={"primary"}
            onClick={handleOpenModal}
            icon={<i className="fa-solid fa-paper-plane"></i>} className={"text-upper"}>{textButton()}</Button>
    <RolesAreNotSetModalWarning type={type} open={openModal} handleCloseModal={handleCloseModal}/>
  </>;
};