import { Table } from "antd";
import { useFetchPublishedFixtureRoster, useFetchPublishedSeasonRoster } from "../api";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import {
  getNumberColumn,
  getDobColumn,
  getGetPositionColumn,
  getNameFullLocalColumns,
  getNationalityColumn,
  getPersonStatusColumn,
  getRosterStatusColumn,
  getIsCaptainColumn,
  getIsStarterColumn
} from "~shared/common";

import { sortRoster } from "~shared/common/sorters";

export const PublishedRosterList = ({seasonId = null, fixtureId = null}) => {
  const {formatMessage} = useIntl();
  const {refetch: fetchSeasonRoster, isLoading: isLoadingSeasonRoster} = useFetchPublishedSeasonRoster(seasonId);
  const {refetch: fetchFixtureRoster, isLoading: isLoadingFixtureRoster} = useFetchPublishedFixtureRoster(fixtureId);
  const [data, setData] = useState([]);

  const fetchSeasonRosterFn = async () => {
    try {
      const res = await fetchSeasonRoster();
      setData(sortRoster(res?.data?.data));
    } catch (e) {
      console.error(e);
    }
  };

  const isLoading = Boolean(seasonId && isLoadingSeasonRoster) || Boolean(fixtureId && isLoadingFixtureRoster);

  const fetchFixtureRosterFn = async () => {
    try {
      const res = await fetchFixtureRoster();
      setData(sortRoster(res?.data?.data));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (seasonId) {
      fetchSeasonRosterFn();
    }
    if (fixtureId) {
      fetchFixtureRosterFn();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonId, fixtureId]);

  return (
    <Table
      loading={isLoading}
      size="small"
      dataSource={data}
      rowKey={(record) => record?.dcPersonId ? record?.dcPersonId : record?.personId}
      columns={[
        getNumberColumn(formatMessage),
        getNameFullLocalColumns(formatMessage, true),
        getDobColumn(formatMessage),
        getNationalityColumn(formatMessage),
        getPersonStatusColumn(formatMessage, true),
        getGetPositionColumn(formatMessage),
        getRosterStatusColumn(formatMessage),
        getIsCaptainColumn(formatMessage),
        ...fixtureId ? [getIsStarterColumn(formatMessage)] : []
      ]
      }
    />
  );
};