import { Link } from "react-router-dom";
import { Button, Space, theme, Typography } from "antd";
import moment from "moment";
import {groupBy, uniqBy, isEmpty} from "lodash";
import { useIntl } from "react-intl";
import { TaskStatusTag, TeamLogoBadge } from "../../../shared";

const { useToken } = theme;

const renderResult = ({ awayEntity, homeEntity }) => {
  if (!awayEntity || !homeEntity) return "";
  if (!homeEntity?.score && !homeEntity?.secondaryScore) {
    return "";
  }
  if (!awayEntity?.score && !awayEntity?.secondaryScore) {
    return "";
  }

  let isHomeTeamWin = false;
  let isAwayTeamWin = false;

  const homeResult = parseInt(homeEntity?.score);
  const awayResult = parseInt(awayEntity?.score);

  const homeSecondaryResult = parseInt(homeEntity?.secondaryScore);
  const awaySecondaryResult = parseInt(awayEntity?.secondaryScore);

  if (homeResult > awayResult) {
    isHomeTeamWin = true;
    isAwayTeamWin = false;
  }

  if (homeResult < awayResult) {
    isHomeTeamWin = false;
    isAwayTeamWin = true;
  }

  if (homeSecondaryResult && awaySecondaryResult) {
    if (homeResult + homeSecondaryResult > awayResult + awaySecondaryResult) {
      isHomeTeamWin = true;
      isAwayTeamWin = false;
    }

    if (homeResult + homeSecondaryResult < awayResult + awaySecondaryResult) {
      isHomeTeamWin = false;
      isAwayTeamWin = true;
    }

    return <Space size={3}>
      {isHomeTeamWin && <i className="fa-solid fa-caret-left mr-5"></i>}
      <Typography.Text>{homeResult}</Typography.Text>
      <Typography.Text>-</Typography.Text>
      <Typography.Text>{awayResult}</Typography.Text>
      <Typography.Text>(Pen<Typography.Text
        strong={isHomeTeamWin}> {homeSecondaryResult}</Typography.Text> - <Typography.Text
        strong={isAwayTeamWin}>{awaySecondaryResult}</Typography.Text>)</Typography.Text>
      {isAwayTeamWin && <i className="fa-solid fa-caret-right ml-5"></i>}
    </Space>;
  }

  return <Space size={3}>
    {isHomeTeamWin && <i className="fa-solid fa-caret-left mr-5"></i>}
    <Typography.Text strong={isHomeTeamWin}>{homeResult}</Typography.Text>
    <Typography.Text>-</Typography.Text>
    <Typography.Text strong={isAwayTeamWin}>{awayResult}</Typography.Text>
    {isAwayTeamWin && <i className="fa-solid fa-caret-right ml-5"></i>}
  </Space>;
};

export const useColumnFixtureTable = ({ fixtures }) => {
  const { token } = useToken();
  const { formatMessage } = useIntl();

  return [{
    title: "Round",
    dataIndex: "round",
    align: "center",
    filters: Object.keys(groupBy(fixtures?.data, "round"))
      ?.filter(round => round !== "null")
      ?.map(round => {
        return {
          text: round, value: round,
        };
      }),
    onFilter: (record, value) => value?.round === record,
  }, {
    title: "Home team",
    dataIndex: "homeEntity",
    render: (homeEntity) => {
      return <Space size={5}>
        {!isEmpty(homeEntity?.nameLocal) && <TeamLogoBadge img={homeEntity?.imageLogoUrl}/>}
        {homeEntity?.nameLocal}
      </Space>;
    },
  }, {
    title: "Result",
    align: "center",
    render: ({ homeEntity, awayEntity }) => renderResult({ awayEntity, homeEntity }),
  }, {
    title: "Away team",
    dataIndex: "awayEntity",
    render: (awayEntity) => {
      return <Space>
        {!isEmpty(awayEntity?.nameLocal) && <TeamLogoBadge img={awayEntity?.imageLogoUrl}/>}
        {awayEntity?.nameLocal}
      </Space>;
    },
  }, {
    title: "Venue",
    dataIndex: "venue",
    align: "center",
    render: (venue) => venue?.nameLocal,
    filters: Object.keys(groupBy(fixtures?.data, (value) => value?.venue?.id || null))
      ?.filter(venueId => venueId !== "null")
      ?.map(venueId => {
        const foundFixtures = fixtures?.data?.filter(fixture => fixture?.venue?.id === venueId)?.[0];
        const venue = foundFixtures?.venue;

        return {
          text: venue?.nameLocal,
          value: venueId,
        };
      }),
    onFilter: (record, value) => value?.venue?.id === record,
    filterSearch: true,
  }, {
    title: "Date",
    dataIndex: "startDateTimeLocal",
    align: "center",
    render: (startDateTimeLocal) => {
      return startDateTimeLocal ? moment(startDateTimeLocal).format("DD/MM/YYYY") : "";
    },
    filters: uniqBy(fixtures?.data?.map((x) => ({
      text: x.startDateTimeLocal && moment(x.startDateTimeLocal).format("DD/MM/YYYY"),
      value: x.startDateTimeLocal && moment(x.startDateTimeLocal).format("DD/MM/YYYY"),
    })) || [], "value").sort((a, b) => {
      const aMixed = new Date(a);
      const bMixed = new Date(b);
      return aMixed < bMixed ? -1 : 1;
    }),
    onFilter: (value, record) => value === moment(record.startDateTimeLocal).format("DD/MM/YYYY"),
  }, {
    title: "Start time",
    dataIndex: "startDateTimeLocal",
    align: "center",
    render: (startDateTimeLocal) => {
      return startDateTimeLocal ? moment(startDateTimeLocal).format("HH:mm") : "";
    },
  }, {
    title: "Status", dataIndex: "status", align: "center",
  }, {
    title: "Stage",
    dataIndex: "stage",
    align: "center",
    render: (stage) => stage?.nameLocal,
    filters: Object.keys(groupBy(fixtures?.data, (value) => value?.stage?.id || null))
      ?.filter(stageId => stageId !== "null")
      ?.map(stageId => {
        const foundFixtures = fixtures?.data?.filter(fixture => fixture?.stage?.id === stageId)?.[0];
        const stage = foundFixtures?.stage;

        return {
          text: stage?.nameLocal,
          value: stageId,
        };
      }),
    onFilter: (record, value) => value?.stage?.id === record,
    filterSearch: true,
  }, {
    title: "Pool",
    dataIndex: "pool",
    align: "center",
    render: (pool) => pool?.nameLocal,
    filters: Object.keys(groupBy(fixtures?.data, (value) => value?.pool?.id || null))
      ?.filter(poolId => poolId !== "null")
      ?.map(poolId => {
        const { pool } = fixtures?.data?.filter(fixture => fixture?.pool?.id === poolId)?.[0] || {};
        return {
          text: pool?.nameLocal,
          value: poolId,
        };
      }),
    onFilter: (record, value) => value?.pool?.id === record,
    filterSearch: true,
  }, {
    title: "Series",
    dataIndex: "series",
    align: "center",
    render: (series) => series?.nameLocal,
    filters: Object.keys(groupBy(fixtures?.data, (value) => value?.series?.id || null))
      ?.filter(seriesId => seriesId !== "null")
      ?.map(seriesId => {
        const { series } = fixtures?.data?.filter(fixture => fixture?.series?.id === seriesId)?.[0] || {};
        return {
          text: series?.nameLocal,
          value: seriesId,
        };
      }),
    onFilter: (record, value) => value?.series?.id === record,
    filterSearch: true,
  },
    {
      title: formatMessage({
        id: "status",
        defaultMessage: "Status",
      }),
      dataIndex: "taskStatus",
      align: "center",
      width: 1,
      render: (taskStatus, record) => <TaskStatusTag
        rejectReason={record?.taskHistoryRecords[record?.taskHistoryRecords?.length - 1]?.text}
        status={taskStatus}/>,
    },
    {
      render: (value, record) => <Space size={0}>
        <Link to={`${record?.fixtureId}/roster`}>
          <Button title={record?.taskStatus} type={"text"}
                  icon={record?.areRosterAndStaffEditable ? <i className={"fa-solid fa-lock-open"}></i> : <i
                    className="fa-solid fa-lock"></i>}><Typography.Text strong style={{
            color: token.colorPrimary,
          }} className={"text-upper"}>{formatMessage({
            id: "view.roster",
            defaultMessage: "View Roster",
          })}</Typography.Text></Button>
        </Link>
        <Link to={`${record?.fixtureId}/staff`}>
          <Button title={record?.taskStatus} type={"text"}
                  icon={record?.areRosterAndStaffEditable ? <i className={"fa-solid fa-lock-open"}></i> : <i
                    className="fa-solid fa-lock"></i>}><Typography.Text strong style={{
            color: token.colorPrimary,
          }} className={"text-upper"}>{formatMessage({
            id: "view.staff",
            defaultMessage: "View Staff",
          })}</Typography.Text></Button>
        </Link>
      </Space>, width: 1,
    }];
};