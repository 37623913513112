import { AuthProvider } from "../features/auth"
import Router from "./Router"
import { ConfigProvider } from "antd"
import { ReactQueryProvider } from "./api/ReactQueryProvider"
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { IntlProvider } from "react-intl"
import { setupLanguage } from "../shared/lib"

function App () {
  const { locale, messages } = setupLanguage()

  return (
    <AuthProvider>
      <ReactQueryProvider>
        <ConfigProvider theme={{
          token: {
            colorPrimary: "#00003c",
            colorPrimaryBg: "#d9d9d9",
            fontFamily: "Bebas Neue Pro",
            colorBgSpotlight: "#d9d9d9",
            fontSize: 15
          },
          components: {
            Layout: {
              headerBg: "#f2f2f2",
              siderBg: "#f2f2f2",
              footerBg: "#26303d",
              footerPadding: "15px"
            },
            List: {
              footerBg: "#ffffff",
              headerBg: "#ffffff"
            },
            Tooltip: {
              colorBgDefault: "rgba(0,0,0,.8)",
            }
          },
        }}>
          <IntlProvider messages={messages} locale={locale}>
            <Router/>
          </IntlProvider>
        </ConfigProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryProvider>
    </AuthProvider>
  )
}

export default App
