import { Col, Row, Tabs, Typography } from "antd"
import { RosterList } from "../../../entities/roster"
import { StaffList } from "../../../entities/staff"
import { AdminSeasonTaskHistory } from "../../../entities/task"
import { TaskActions } from "../../../widgets/taskActionButtons"
import { useSeasonsStore } from "../../../entities/season"

const items = [
  {
    key: "roster",
    label: <Typography.Text className={"text-upper"}>Roster</Typography.Text>,
    children: <RosterList type={"seasonRoster"}/>
  },
  {
    key: "staff",
    label: <Typography.Text className={"text-upper"}>Staff</Typography.Text>,
    children: <StaffList type={"seasonStaff"}/>
  }
]

export const SeasonTabs = () => {
  const activeSeason = useSeasonsStore(store => store?.activeSeason)

  return <Row gutter={5}>
    <Col xs={20} sm={20} md={16} lg={16} xl={17} xxl={20}>
      <Tabs className={"mt-15"} centered={true} defaultActiveKey={"roster"} items={items}>
      </Tabs>
    </Col>
    <Col xs={4} sm={40} md={8} lg={8} xl={7} xxl={4}
    >
      <div className={"mb-15 mt-30"} style={{
        display: "flex",
        justifyContent: "end",
        minHeight: "33px"
      }}>
        <TaskActions type={"seasonRoster"} status={activeSeason?.taskStatus}/>
      </div>
      <AdminSeasonTaskHistory/>
    </Col>
  </Row>
}