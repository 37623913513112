import { Button, Col, Row, Select, Space, Table, theme, Typography } from "antd";
import { useIntl } from "react-intl";
import { useFetchSeasons } from "../api";
import { useSeasonsStore } from "../model";
import moment from "moment";
import { CompetitionLogoBadge, SearchInput, SeasonLogoBadge, TaskStatusTag } from "../../../shared";
import { useState } from "react";
import { Link } from "react-router-dom";

const { useToken } = theme;

export const SeasonsList = () => {
  const { token } = useToken();
  const { formatMessage } = useIntl();
  const [activeFilter, setActiveFilter] = useState("DEFAULT");
  const [searchText, setSearchText] = useState("");
  useFetchSeasons();
  const seasons = useSeasonsStore(state => state?.seasons);

  const changeActiveFilter = (value) => {
    setActiveFilter(value);
  };

  return <Row className={"mb-15"}>
    <Col span={24}>
      <Row justify={"space-between"} align={"middle"} className={"mb-10"}>
        <Typography.Text strong>{formatMessage({
          id: "seasons",
          defaultMessage: "Seasons",
        })}</Typography.Text>
        <Space align={"center"}>
          <Select popupMatchSelectWidth={false}
                  bordered={false}
                  defaultValue={"DEFAULT"}
                  onChange={changeActiveFilter}
                  options={[
                    {
                      label: formatMessage({
                        id: "default.view",
                        defaultMessage: "Default View",
                      }),
                      value: "DEFAULT",
                    },
                    {
                      label: formatMessage({
                        id: "active.seasons",
                        defaultMessage: "Active Seasons",
                      }),
                      value: "ACTIVE",
                    },
                    {
                      label: formatMessage({
                        id: "pending.seasons",
                        defaultMessage: "Pending Seasons",
                      }),
                      value: "PENDING",
                    },
                    {
                      label: formatMessage({
                        id: "inactive.seasons",
                        defaultMessage: "Inactive Seasons",
                      }),
                      value: "INACTIVE",
                    },
                  ]}/>
          <SearchInput doSearch={setSearchText}/>
        </Space>
      </Row>
    </Col>
    <Col span={24}>
      <Table size={"small"}
             dataSource={seasons
               ?.filter(season => activeFilter === "DEFAULT" ? season?.status === "ACTIVE" || season?.status === "PENDING" : season?.status === activeFilter)
               ?.filter((season) => season?.nameLocal?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1)
             }
             rowKey={"seasonId"}
             columns={[
               {
                 title: formatMessage({
                   id: "year",
                   defaultMessage: "year",
                 }),
                 dataIndex: "year",
                 width: 1,
                 align: "center",
               },
               {
                 title: formatMessage({
                   id: "competition.name",
                   defaultMessage: "Competition Name",
                 }),
                 dataIndex: "competitionNameLocal",
                 render: (competitionNameLocal, record) => <Space>
                   <CompetitionLogoBadge img={record?.competitionImageUrl}/>
                   {competitionNameLocal}
                 </Space>,
               },
               {
                 title: formatMessage({
                   id: "season.name",
                   defaultMessage: "Season Name",
                 }),
                 dataIndex: "nameLocal",
                 render: (nameLocal, record) => <Space>
                   <SeasonLogoBadge img={record?.imageUrl}/>
                   {nameLocal}
                 </Space>,
               },
               {
                 title: formatMessage({
                   id: "gender",
                   defaultMessage: "Gender",
                 }),
                 dataIndex: "gender",
                 render: (gender) => formatMessage({
                   id: "gender." + gender,
                   defaultMessage: gender,
                 }),
                 align: "center",
               },
               {
                 title: formatMessage({
                   id: "age.group",
                   defaultMessage: "Age Group",
                 }),
                 dataIndex: "ageGroup",
                 render: (ageGroup) => formatMessage({
                   id: "age.group." + ageGroup,
                   defaultMessage: ageGroup,
                 }),
                 align: "center",
               },
               {
                 title: formatMessage({
                   id: "start.date",
                   defaultMessage: "Start Date",
                 }),
                 dataIndex: "startDate",
                 render: startDate => moment(startDate).format("DD/MM/YYYY"),
                 align: "center",
               },
               {
                 title: formatMessage({
                   id: "end.date",
                   defaultMessage: "End Date",
                 }),
                 dataIndex: "endDate",
                 render: endDate => moment(endDate).format("DD/MM/YYYY"),
                 align: "center",
               },
               {
                 title: formatMessage({
                   id: "status",
                   defaultMessage: "Status",
                 }),
                 dataIndex: "status",
                 render: (status) => formatMessage({
                   id: "status." + status,
                   defaultMessage: status,
                 }),
                 align: "center",
               },
               {
                 dataIndex: "taskStatus",
                 title: formatMessage({
                   id: "task.status",
                   defaultMessage: "Task status",
                 }),
                 width: 1,
                 align: "center",
                 render: (taskStatus, record) => <TaskStatusTag
                   rejectReason={record?.taskHistoryRecords[record?.taskHistoryRecords?.length - 1]?.text}
                   status={taskStatus}/>,
               },
               {
                 width: 1,
                 align: "center",
                 render: (season) => {
                   return <Space size={0}>
                     <Link to={`seasons/${season?.seasonId}/fixtures`}>
                       <Button type={"text"}
                               icon={season?.containsFixtureTasksInRejectedStatus
                                 ? <i style={{ color: "red" }} className="fa-solid fa-circle-exclamation"/>
                                 : null}>
                         <Typography.Text strong style={{
                           color: token.colorPrimary,
                         }} className={"text-upper"}
                         >{formatMessage({
                           id: "view.fixtures",
                           defaultMessage: "View Fixtures",
                         })}</Typography.Text>
                       </Button>
                     </Link>
                     <Link to={`seasons/${season?.seasonId}/roster`}>
                       <Button title={season?.taskStatus} type={"text"}
                               icon={season?.areRosterAndStaffEditable ? <i className={"fa-solid fa-lock-open"}></i> :
                                 <i
                                   className="fa-solid fa-lock"></i>}>
                         <Typography.Text strong style={{
                           color: token.colorPrimary,
                         }} className={"text-upper"}
                         >{formatMessage({
                           id: "view.roster",
                           defaultMessage: "View Roster",
                         })}</Typography.Text>
                       </Button>
                     </Link>
                     <Link to={`seasons/${season?.seasonId}/staff`}>
                       <Button title={season?.taskStatus} type={"text"}
                               icon={season?.areRosterAndStaffEditable ? <i className={"fa-solid fa-lock-open"}></i> :
                                 <i
                                   className="fa-solid fa-lock"></i>}>
                         <Typography.Text strong style={{
                           color: token.colorPrimary,
                         }} className={"text-upper"}
                         >{formatMessage({
                           id: "view.staff",
                           defaultMessage: "View Staff",
                         })}</Typography.Text>
                       </Button>
                     </Link>
                   </Space>;
                 },
               },
             ]}
      />
    </Col>
  </Row>;
};