import { sortBy } from "lodash";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortRoster(data: any[]) {
    const source = (data || []);
    const sortedByName = sortBy(source, [(it) => it.nameFullLocal || it.nameFullLatin]);
    return sortBy(sortedByName, [(it) =>  parseInt(it.number || "0")]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortStaff(data: any[]) {
    const source = (data || []);
    const sortedByName = sortBy(source, [(it) => it.nameFullLocal || it.nameFullLatin]);
    return sortedByName;
}
