import { Button, Space, Typography } from "antd";
import moment from "moment";
import { TeamLogoBadge } from "../../../shared";
import {isEmpty} from "lodash";

const renderResult = ({ awayEntity, homeEntity }) => {
  if (!awayEntity || !homeEntity) return "";

  let isHomeTeamWin = false;
  let isAwayTeamWin = false;

  const homeResult = parseInt(homeEntity?.score);
  const awayResult = parseInt(awayEntity?.score);

  if (!awayResult || !homeResult) return "";

  const homeSecondaryResult = parseInt(homeEntity?.secondaryScore);
  const awaySecondaryResult = parseInt(awayEntity?.secondaryScore);

  if (homeResult > awayResult) {
    isHomeTeamWin = true;
    isAwayTeamWin = false;
  }

  if (homeResult < awayResult) {
    isHomeTeamWin = false;
    isAwayTeamWin = true;
  }

  if (homeSecondaryResult && awaySecondaryResult) {
    if (homeResult + homeSecondaryResult > awayResult + awaySecondaryResult) {
      isHomeTeamWin = true;
      isAwayTeamWin = false;
    }

    if (homeResult + homeSecondaryResult < awayResult + awaySecondaryResult) {
      isHomeTeamWin = false;
      isAwayTeamWin = true;
    }

    return <Space size={3}>
      {isHomeTeamWin && <i className="fa-solid fa-caret-left mr-5" />}
      <Typography.Text>{homeResult}</Typography.Text>
      <Typography.Text>-</Typography.Text>
      <Typography.Text>{awayResult}</Typography.Text>
      <Typography.Text>(Pen<Typography.Text
        strong={isHomeTeamWin}> {homeSecondaryResult}</Typography.Text> - <Typography.Text
        strong={isAwayTeamWin}>{awaySecondaryResult}</Typography.Text>)</Typography.Text>
      {isAwayTeamWin && <i className="fa-solid fa-caret-right ml-5" />}
    </Space>;
  }

  return <Space size={3}>
    {isHomeTeamWin && <i className="fa-solid fa-caret-left mr-5" />}
    <Typography.Text strong={isHomeTeamWin}>{homeResult}</Typography.Text>
    <Typography.Text>-</Typography.Text>
    <Typography.Text strong={isAwayTeamWin}>{awayResult}</Typography.Text>
    {isAwayTeamWin && <i className="fa-solid fa-caret-right ml-5" />}
  </Space>;
};

export const useColumnFixtureTableForCopy = ({ setFixtureId }) => {
    return [{
      title: "Round",
      dataIndex: "round",
      align: "center",
    }, {
      title: "Home team",
      dataIndex: "homeEntity",
      render: (homeEntity) => {
        return <Space size={5}>
          {!isEmpty(homeEntity?.nameLocal) && <TeamLogoBadge img={homeEntity?.imageLogoUrl}/>}
          {homeEntity?.nameLocal}
        </Space>;
      },
    },
      {
        title: "Result",
        align: "center",
        render: ({ homeEntity, awayEntity }) => renderResult({ awayEntity, homeEntity }),
      }, {
        title: "Away team",
        dataIndex: "awayEntity",
        render: (awayEntity) => {
          return <Space>
            {!isEmpty(awayEntity?.nameLocal) && <TeamLogoBadge img={awayEntity?.imageLogoUrl}/>}
            {awayEntity?.nameLocal}
          </Space>;
        },
      },
      {
        title: "Date",
        dataIndex: "startDateTimeLocal",
        align: "center",
        render: (startDateTimeLocal) => startDateTimeLocal && moment(startDateTimeLocal).format("DD/MM/YYYY"),
      },
      {
        dataIndex: "fixtureId",
        render: (fixtureId) => <Button onClick={() => setFixtureId(fixtureId)} type="text"
                                       icon={<i className="fa-solid fa-eye" />}/>,
      },
    ];
  }
;