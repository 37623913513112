import {Col, Row, theme, Typography} from "antd";
import {Link, useLocation, useParams} from "react-router-dom";
import {TeamSelect} from "./TeamSelect";
import {SeasonSelect} from "./SeasonSelect";
import {useOrganizationsStore} from "../../../entities/organization/model";

const {useToken} = theme;

const ArrowRight = (color) => {
  return (<i className="fa-solid fa-chevron-right ml-15 mr-15" style={{color: color}}/>)
};

export const BreadCrumbs = () => {
  const {token} = useToken();
  const {customerId, organizationId, teamId, seasonId, sport} = useParams();
  const location = useLocation();
  const organizations = useOrganizationsStore(store => store?.organizations);

  const breadcrumb = [];

  breadcrumb.push(<Typography.Text key="bc-brand-name"
                                   style={{color: token.colorPrimary, fontWeight: 700}}>Sportradar</Typography.Text>);
  breadcrumb.push(<ArrowRight key="bc-arrow-1" color={token.colorError}/>);
  breadcrumb.push(<Link key="bc-link-to-home" to={"/"}><Typography.Text style={{color: token.colorPrimary}} strong>Team
    Manager</Typography.Text></Link>);

  if (organizationId) {
    breadcrumb.push(<ArrowRight key="bc-arrow-2" color={token.colorError}/>);
    if (organizations?.length) {
      breadcrumb.push(
        <Link key="bc-link-to-organization" to={`/${customerId}`}>
          <Typography.Text style={{color: token.colorPrimary}} strong>
            {organizations?.filter(x => x?.organizationId === organizationId)?.[0]?.nameLocal}
          </Typography.Text>
        </Link>);
    } else {
      breadcrumb.push(
        <Link key="bc-link-to-organizations" to={"/"}>
          <Typography.Text style={{color: token.colorPrimary}} strong>Organizations</Typography.Text>
        </Link>);
    }
  }

  if (location?.pathname.includes("admin")) {
    breadcrumb.push(<ArrowRight key="bc-arrow-3" color={token.colorError}/>);
    breadcrumb.push(<Link key="bc-link-to-admin-dashboard" to={`/${customerId}/${organizationId}/${sport}/admin`}>
      <Typography.Text style={{color: token.colorPrimary}} strong>Admin Dashboard</Typography.Text>
    </Link>);
  } else {
    if (teamId) {
      breadcrumb.push(<ArrowRight key="bc-arrow-4" color={token.colorError}/>);
      breadcrumb.push(<TeamSelect key="bc-team-select"/>);
    }

    if (seasonId) {
      breadcrumb.push(<ArrowRight key="bc-arrow-5" color={token.colorError}/>);
      breadcrumb.push(<SeasonSelect key="bc-search-select"/>);
    }
  }

  return <Row>
    <Col span={24}>
      {breadcrumb?.map(item => item)}
    </Col>
  </Row>;
};