import { useParams } from "react-router-dom"
import { Select, Space } from "antd";
import { useNavigate } from "react-router-dom"
import { useFetchActiveSeasons, useSeasonsStore } from "../../../entities/season"
import { SeasonLogoBadge } from "../../../shared";

export const SeasonSelect = () => {
  const { customerId, organizationId, sport, seasonId, teamId } = useParams()
  const {isLoading} =  useFetchActiveSeasons()
  const activeSeasons = useSeasonsStore(state => state?.activeSeasons)
  const navigate = useNavigate()


  if (!activeSeasons?.length) return ""

  const handleChangeSeason = (value) => {
    navigate(`${customerId}/${organizationId}/${sport}/teams/${teamId}/seasons/${value}/fixtures`)
  }

  return <Space size={0}>
    <SeasonLogoBadge size={25} img={activeSeasons?.filter(season => seasonId === season?.seasonId)?.[0]?.imageUrl}/>
    <Select
      popupMatchSelectWidth={false}
      bordered={false}
      showSearch
      onChange={handleChangeSeason}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      optionRender={({ data }) => {
        return <Space>
          <SeasonLogoBadge img={data?.img}/>
          {data?.label}
        </Space>;
      }}
      defaultValue={seasonId}
      loading={isLoading}
      options={activeSeasons?.map(x => {
        return {
          label: x?.nameLocal,
          value: x?.seasonId,
          img: x?.imageUrl,
        }
      })}>
    </Select>
  </Space>
}