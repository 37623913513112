import { create } from "zustand"
import { devtools } from "zustand/middleware"

export const useFixtureStaffStore = create(devtools((set) => ({
  fixtureStaff: [],
  setFixtureStaff: data => {
    set({
      fixtureStaff: data
    })
  }
})), { name: "fixtureStaff" })