import { Space, Typography } from "antd"

export const DetailsFieldWrap = ({
  desc,
  title
}) => {
  return <Space direction={"vertical"} size={0}>
    <Typography.Text type={"secondary"}>{desc}</Typography.Text>
    <Typography.Text>{title || "-"}</Typography.Text>
  </Space>
}