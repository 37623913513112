import { Typography } from "antd"
import { useGetUserInfo } from "../api/useGetUserInfo"

export const UserHeaderProfile = () => {
  const user = useGetUserInfo()

  return <div style={{ display: "flex", alignItems: "center" }}>
    <img width={32} height={32} src={user?.picture} alt="avatar" style={{ borderRadius: "15px" }}/>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography.Text strong>{user?.name}</Typography.Text>
      <Typography.Text>{user?.email}</Typography.Text>
    </div>
  </div>
}
