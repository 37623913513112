import { Divider, Layout, Row, Space, Typography } from "antd"

export const Footer = () => {
  let date = new Date()
  let year = date.getFullYear()

  return <Layout.Footer style={{
    position: "fixed",
    bottom: "0px",
    width: "calc(100% - 65px)"
  }}>
    <Row justify={"space-between"}>
      <Typography.Text style={{ color: "#fff" }}>
        &copy; {year} - Sportradar
      </Typography.Text>
      <Space size={10}>
        <a target="_blank" rel="noopener" href="https://synergyautomatedcamerasystem.zendesk.com/hc/en-us/sections/4414390198029-Synergy-Competition-Admin-User-Guide">
          <Typography.Text style={{ color: "#fff" }} strong>Support</Typography.Text>
        </a>
        <Divider type={"vertical"} style={{
          background: "red",
          width: "2px",
          height: "20px"
        }}/>
        <a target="_blank" rel="noopener" href="https://sportradar.com/single-sign-on-sso-privacy-notice/">
          <Typography.Text style={{ color: "#fff" }} strong>Privacy</Typography.Text>
        </a>
        <Divider type={"vertical"} style={{
          background: "red",
          width: "2px",
          height: "20px"
        }}/>
        <a target="_blank" rel="noopener" href="https://synergysports.com/terms-of-use/">
          <Typography.Text style={{ color: "#fff" }} strong>Terms & Conditions</Typography.Text>
        </a>
      </Space>
    </Row>
  </Layout.Footer>
}