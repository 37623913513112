import { useApi } from "../../../shared/api";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

export const useFetchFixturesByOrganization = ({ taskStatus, page, pageSize, seasonId }) => {
  const { fetchData } = useApi();
  const { organizationId, sport } = useParams();

  const paramsObj = {
    taskStatus, limit: pageSize, offset: pageSize * page - pageSize,
  };

  if (seasonId) {
    paramsObj.seasonId = seasonId;
  }

  const params = new URLSearchParams(paramsObj);

  return useQuery({
    queryKey: ["fixtures", organizationId, params.toString()],
    queryFn: () => fetchData(`/${sport}/o/${organizationId}/fixtures?${params.toString()}`),
    keepPreviousData: true,
  });
};