import { Space } from "antd";
import { useIntl } from "react-intl";
import { EditPerson } from "../../../features/EditPerson";
import { DeleteStaff } from "../ui/DeleteStaff";
import { useRole } from "~shared/hooks";
import { getPersonStatusColumn, getNameFullLocalColumns, getRoleColumn } from "~shared/common";

export const useStaffListColumns = ({ type, canEdit = true, isEditMode = false, onColumnChanged = undefined, rolesList = [] }) => {
  const { formatMessage } = useIntl();
  const { isTeamManager } = useRole();

  const columns = [
    getNameFullLocalColumns(formatMessage),
    getPersonStatusColumn(formatMessage, true),
    getRoleColumn(formatMessage, { canEdit, isEditMode, onColumnChanged, rolesList })
  ];

  if (isTeamManager) {
    columns.push({
      render: (value, record) => <Space>
        <EditPerson type={type} person={record}/>
        <DeleteStaff buttonType={"small"} type={type} persons={[record]}/>
      </Space>,
      width: 1,
    });
  }

  return columns;
};