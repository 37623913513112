import { create } from "zustand"
import { devtools } from "zustand/middleware"

export const useClubsStore = create(devtools((set) => ({
  clubs: [],
  set: (data) => {
    set({
      clubs: data
    })
  }
})), { name: "clubs" });