import { useConfigStore } from "../model";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { useGenerateRedirectLink } from "./useGenerateRedirectLink";
import { shallow } from "zustand/shallow";
import { isString } from "lodash";

/**
 * TODO: implement `request` fn that does not care about application specific logic (e.g. useParams, useNavigate, useAuth0)
 * And then, implement `useApi` that will use `request` fn and will care about application specific logic.
 * In other words, separate concerns.
 */ 
export const useApi = () => {
  const customerId = useParams();
  const config = useConfigStore(state => state?.config, shallow);
  const BASE_API_URL = config?.environments?.api[config?.env];
  const { getIdTokenClaims, logout } = useAuth0();
  const navigate = useNavigate();
  useGenerateRedirectLink();

  const fetchData = async (url) => {
    if (!isString(url)) {
      throw new Error("URL type is not valid - expected string");
    }
    const serviceUrl = url.startsWith("/") ? url : `/${url}`;
    const ID_TOKEN = await getIdTokenClaims();
    try {
      const response = await fetch(BASE_API_URL + serviceUrl, {
        headers: {
          "Authorization": `Bearer ${ID_TOKEN?.__raw}`,
          "x-customer-id": customerId?.customerId,
        },
      });

      if (!response.ok) {
        if (response?.status === 401) {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
          return;
        }

        if (response?.status === 403) {
          navigate("/403");
        }

        if (response?.status === 404) {
          navigate("/404");
        }

        const error = await response.json();
        throw new Error(error?.errors?.message);
      }

      return await response.json();
    } catch (e) {
      console.error("Error during data fetching", e);
      throw e;
    }
  };

  const mutateData = async ({
    url,
    data,
    method = "POST",
    imageType = null,
  }) => {
    const ID_TOKEN = await getIdTokenClaims();

    try {
      const OPTIONS = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${ID_TOKEN?.__raw}`,
          "x-customer-id": customerId?.customerId,
        },
        body: JSON.stringify(data),
      };

      if (imageType) {
        OPTIONS.headers["Content-Type"] = data?.type || "application/octet-stream";
        OPTIONS.body = data;
      }

      const response = await fetch(BASE_API_URL + url, OPTIONS);

      if (!response.ok) {
        if (response?.status === 401) {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
          return;
        }

        if (response?.status === 403) {
          navigate("/403");
        }

        if (response?.status === 404) {
          navigate("/404");
        }

        const error = await response?.json();
        throw new Error(error?.errors?.message);
      }

      return response.json();
    } catch (e) {
      console.error(e, "mutateData ERROR");
      throw e;
    }
  };

  return {
    fetchData,
    mutateData,
  };
};